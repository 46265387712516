import { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import '../assets/css/onboardingCustomer.css';
import { Typography } from '@mui/material';
import {
  Radio, RadioGroup, Button,
} from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';

const CustomerDataInputRadio = ({
  variable, handleChange, userFilled, isRevolvingLineLoan, prepaidCardLastFour, inputHintAction, turnOnValidations,
}) => {
  const onChange = (value) => {
    let comboSelected;
    if (value !== null) {
      comboSelected = variable.userInputOptions.comboData.find((c) => c.id === value);
    }
    handleChange(variable, comboSelected);
  };

  useEffect(() => {}, [userFilled[variable.name]]);

  const buildLabel = (field) => {
    const description = field.descripcion || '';
    const subDescription = field.subDescription ? ` ${field.subDescription}` : '';
    let newIfApply = '';
    if (field.id === 'PREPAID_CARD' && !prepaidCardLastFour) {
      newIfApply = (
        <span className="customer-data-prepaid-card-new">
          {i18n.OnboardingCustomerData.inputRadio.new}
        </span>
      );
    }
    return (
      <Typography>
        {parse(description + subDescription)}
        {newIfApply}
      </Typography>
    );
  };

  return (
    <>
      {!isRevolvingLineLoan && (
        <Typography className="customer-data-revolving-title">
          {variable.userInputOptions.label}
        </Typography>
      )}
      {variable.userInputOptions.comboData && variable.userInputOptions.comboData.length > 0 && (
        <>
          <RadioGroup
            aria-labelledby="Regular-group"
            name={variable.name}
            value={userFilled[variable.name]?.id || ''}
            size="small"
            error={variable.userInputOptions.required && turnOnValidations
              && (userFilled[variable.name] === undefined
                || (userFilled[variable.name].toString()).trim() === '')}
            onChange={(event) => onChange(event.target.value)}
            required={variable.userInputOptions.required}
          >
            {variable.userInputOptions.comboData.map((field) => (
              <Radio
                value={field.id}
                label={buildLabel(field)}
              />
            ))}
          </RadioGroup>
          {(variable.userInputOptions.hint
            || (variable.userInputOptions.hintAction && userFilled[variable.name]?.showHint)) && (
            <>
              <Typography className="customer-data-custom-hint">
                <span style={{
                  color: (variable.userInputOptions.required && turnOnValidations
                  && (userFilled[variable.name] === undefined
                  || (userFilled[variable.name].toString()).trim() === '') ? '#E20000' : '#00000099'),
                }}
                >
                  {variable.userInputOptions.hint}
                </span>
                {variable.userInputOptions.hintAction && (
                <Button
                  variant="tertiary"
                  onClick={() => inputHintAction(variable)}
                  sx={{ textTransform: 'none' }}
                >
                  {variable.userInputOptions.hintAction}
                </Button>
                )}
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
};

CustomerDataInputRadio.propTypes = {
  variable: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  userFilled: PropTypes.shape({}).isRequired,
  isRevolvingLineLoan: PropTypes.bool.isRequired,
  prepaidCardLastFour: PropTypes.bool,
  inputHintAction: PropTypes.func.isRequired,
  turnOnValidations: PropTypes.bool.isRequired,
};

CustomerDataInputRadio.defaultProps = {
  prepaidCardLastFour: false,
};

export default CustomerDataInputRadio;
