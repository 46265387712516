import parse from 'html-react-parser';
import { useState, useEffect } from 'react';
import {
  Box, FormControlLabel, Grid, Tooltip, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  Radio, RadioGroup, SimpleSelect, Button,
} from '@doit/pcnt-react-ui-library';
import { HelpOutline } from '@mui/icons-material';
import i18n from '../common/i18n';
import { fillMessageWith, isMobileOperatingSystem } from '../utils/functionsUtil';
import NavigationActions from './NavigationActions';

const IdentityResolverStep = ({
  customers, onSubmit, goBack, loading,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [mustSelectGender, setMustSelectGender] = useState(false);
  const [gender, setGender] = useState('');
  const [pep, setPep] = useState(false);
  const [so, setSo] = useState(false);

  const genderList = [
    {
      id: 'F',
      description: i18n.IdentityResolverStep.genderFemale,
    },
    {
      id: 'M',
      description: i18n.IdentityResolverStep.genderMale,
    },
  ];

  const handleOnSubmit = () => {
    onSubmit({
      ...selectedCustomer,
      sexo: selectedCustomer.sexo || gender,
      pep,
      so,
    });
  };

  const verifyIfMustSetGender = () => {
    if (customers.length > 0) {
      customers.forEach((customer) => {
        if (!(customer.sexo)) {
          setMustSelectGender(true);
        }
      });
    }
  };

  const handleChange = (value) => {
    setGender(value);
  };

  const handleChangePep = (event, value) => {
    setPep(value === 'true');
  };

  const handleChangeSo = (event, value) => {
    setSo(value === 'true');
  };

  const handleSelectCustomer = (event, value) => {
    setSelectedCustomer(customers[value]);
  };

  useEffect(() => verifyIfMustSetGender(), []);

  return (
    <>
      <Grid container justify="left">
        {mustSelectGender && (
          <Grid item xs={12}>
            <Box m={3} />
            <SimpleSelect
              style={{ display: 'block', paddingRight: '16px' }}
              fullWidth
              label={i18n.IdentityResolverStep.genderLabel}
              onChange={handleChange}
              options={genderList.map((element) => ({
                label: element.description,
                value: element.id,
              }))}
              variant="outlined"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h1" align="left">
              {i18n.IdentityResolverStep.description}
            </Typography>
            {!isMobileOperatingSystem() && (
              <Button variant="tertiary" onClick={goBack} disabled={loading} sx={{ fontWeight: '400', paddingRight: '0px' }}>
                {i18n.IdentityResolverStep.noOne}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} className="identity-resolver-container">
          <Box className="identity-resolver-list-item">
            <RadioGroup onChange={handleSelectCustomer} sx={{ paddingLeft: '14px' }}>
              {customers.map((customer, index) => (
                <Box key={customer.cuit}>
                  <FormControlLabel
                    value={index}
                    control={<Radio />}
                    label={parse(fillMessageWith(
                      i18n.IdentityResolverStep.cuit,
                      customer.nombrecompleto,
                      customer.cuit,
                    ))}
                  />
                </Box>
              ))}
            </RadioGroup>
          </Box>
        </Grid>
        {isMobileOperatingSystem() && (
          <Grid item xs={12} mt={3} display="flex">
            <Button variant="tertiary" onClick={goBack} disabled={loading} sx={{ fontWeight: '400', paddingLeft: '0px' }}>
              {i18n.IdentityResolverStep.noOne}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt={{ md: 5, xs: 3 }} display="flex" alignItems="center">
            <Typography className="identity-resolver-legal-question">
              {i18n.IdentityResolverStep.pepQuestion}
            </Typography>
            <Tooltip title={i18n.IdentityResolverStep.pepHelp} enterTouchDelay>
              <HelpOutline sx={{ marginLeft: '10px' }} />
            </Tooltip>
          </Box>
          <RadioGroup
            row
            aria-labelledby="Regular-group"
            value={pep}
            name="pep"
            sx={{ margin: '10px' }}
            onChange={handleChangePep}
          >
            <Radio value="true" label="Si" size="small" />
            <Radio value="false" label="No" size="small" />
          </RadioGroup>
          <Box mt={2} display="flex" alignItems="center">
            <Typography className="identity-resolver-legal-question">
              {i18n.IdentityResolverStep.soQuestion}
            </Typography>
            <Tooltip title={i18n.IdentityResolverStep.soHelp} enterTouchDelay>
              <HelpOutline sx={{ marginLeft: '10px' }} />
            </Tooltip>
          </Box>
          <RadioGroup
            row
            aria-labelledby="Regular-group"
            value={so}
            name="so"
            sx={{ margin: '10px' }}
            onChange={handleChangeSo}
          >
            <Radio value="true" label="Si" size="small" />
            <Radio value="false" label="No" size="small" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} mt={3}>
          <NavigationActions
            onContinue={handleOnSubmit}
            onBack={goBack}
            loading={loading}
            disabled={!selectedCustomer}
          />
        </Grid>
      </Grid>
    </>
  );
};

IdentityResolverStep.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({
    nombrecompleto: PropTypes.string.isRequired,
    cuit: PropTypes.string.isRequired,
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default IdentityResolverStep;
