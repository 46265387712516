import { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const GlobalLoadingContext = createContext(null);

export const GlobalLoadingProvider = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(false);

  return (
    <GlobalLoadingContext.Provider value={{ globalLoading, setGlobalLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
};

GlobalLoadingProvider.propTypes = {
  data: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

GlobalLoadingProvider.defaultProps = {
  data: null,
};

export const useGlobalLoading = () => useContext(GlobalLoadingContext);
