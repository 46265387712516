import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { fillMessageWith } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';

const DualOfferTab = ({
  dualAmount, renewAmount, onDual, onRenew,
}) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const selectDual = () => {
    if (selectedTab !== 1) {
      setSelectedTab(1);
      onDual();
    }
  };

  const selectRenew = () => {
    if (selectedTab !== 2) {
      setSelectedTab(2);
      onRenew();
    }
  };

  const resolveClasses = (tab, selectedClass, normalClass) =>
    (selectedTab === tab ? selectedClass : normalClass);

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box
          className={
            resolveClasses(1, 'dual-offer-tab-selected dual-offer-tab-left', 'dual-offer-tab dual-offer-tab-left')
          }
          onClick={selectDual}
        >
          <Typography className={resolveClasses(1, 'dual-offer-tab-title-selected', 'dual-offer-tab-title')}>
            {parse(fillMessageWith(
              i18n.DualOfferTab.dual,
              moneyFormatterWithoutDecimals(dualAmount),
            ))}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          className={
            resolveClasses(2, 'dual-offer-tab-selected dual-offer-tab-right', 'dual-offer-tab dual-offer-tab-right')
          }
          onClick={selectRenew}
        >
          <Typography className={resolveClasses(2, 'dual-offer-tab-title-selected', 'dual-offer-tab-title')}>
            {parse(fillMessageWith(
              i18n.DualOfferTab.renew,
              moneyFormatterWithoutDecimals(renewAmount),
            ))}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

DualOfferTab.propTypes = {
  dualAmount: PropTypes.number.isRequired,
  renewAmount: PropTypes.number.isRequired,
  onDual: PropTypes.func.isRequired,
  onRenew: PropTypes.func.isRequired,
};

export default DualOfferTab;
