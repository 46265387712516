export const ENVIRONMENT = 'ENVIRONMENT';
export const CREDICUOTAS_SELFIE_BACKEND_URL = 'CREDICUOTAS_SELFIE_BACKEND_URL';
export const CREDICUOTAS_WEBSITE_URL = 'CREDICUOTAS_WEBSITE_URL';
export const SELF_MANAGEMENT_URL = 'SELF_MANAGEMENT_URL';
export const ORIGINATION_TERMS_URL = 'ORIGINATION_TERMS_URL';
export const ORIGINATION_POLICY_URL = 'ORIGINATION_POLICY_URL';
export const PREVIOUS_ORIGINATION_ONBOARDING_URL = 'PREVIOUS_ORIGINATION_ONBOARDING_URL';
export const SERVICES_COMPANIES_LOGOS_S3 = 'SERVICES_COMPANIES_LOGOS_S3';
export const GOOGLE_TAG_MANAGER_ID = 'GOOGLE_TAG_MANAGER_ID';
export const WA_CC_NUMBER = 'WA_CC_NUMBER';
export const WA_CC_TUCUMAN_BRANCH_CASH = 'WA_CC_TUCUMAN_BRANCH_CASH';
export const DOCUMENT_LOAD_ALLOW_CONTINUE_ON_DESKTOP = 'DOCUMENT_LOAD_ALLOW_CONTINUE_ON_DESKTOP';

export const getEnv = (key) => window.env[key];
