import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import '../assets/css/notificationSubscription.css';

const NotificationSubscription = ({
  onCancel, onSubscribe, message, positionBottom,
}) => (
  <Box className={positionBottom
    ? 'animate__animated animate__slideInUp notification-subscription-container-bottom'
    : 'animate__animated animate__slideInDown notification-subscription-container-top'}
  >
    <Typography align="left" sx={{ fontSize: '12px' }}>{message}</Typography>
    <Box mt={1}>
      <Box className="notification-subscription-actions">
        <Button
          variant="tertiary"
          size="small"
          onClick={onCancel}
          sx={{ textTransform: 'none', fontSize: '12px' }}
        >
          {i18n.NotificationSubscription.cancelCTA}
        </Button>
        <Box ml={1} />
        <Button
          variant="primary"
          size="small"
          onClick={onSubscribe}
          sx={{ textTransform: 'none', fontSize: '12px', borderRadius: '25px' }}
        >
          {i18n.NotificationSubscription.subscribeCTA}
        </Button>
      </Box>
    </Box>
  </Box>
);

NotificationSubscription.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  positionBottom: PropTypes.bool,
};

NotificationSubscription.defaultProps = {
  positionBottom: false,
};

export default NotificationSubscription;
