import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { Slider, TextInput } from '@doit/pcnt-react-ui-library';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import i18n from '../common/i18n';
import '../assets/css/onboardingSlider.css';

const OnboardingSlider = ({
  loanRequest, installments, selectedAmount, onChangeAmount, loading,
}) => {
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [prepaidAmount, setPrepaidAmount] = useState();
  const [amount, setAmount] = useState();
  const [inputManually, setInputManually] = useState();

  const inputStyle = {
    color: '#F2025D',
    fontSize: '28px',
    fontFamily: 'Roboto,sans-serif',
    padding: '2px',
  };

  const getMarks = () => {
    const marks = [
      {
        value: minAmount,
        label: moneyFormatterWithoutDecimals(minAmount - prepaidAmount),
      },
      {
        value: maxAmount,
        label: moneyFormatterWithoutDecimals(maxAmount - prepaidAmount),
      },
    ];
    return marks;
  };

  const handleChangeManually = (value) => {
    // added plus symbol to transform to number
    let amountAux = +value + prepaidAmount;
    amountAux = Math.ceil(amountAux / 100) * 100;
    if (amountAux < minAmount) {
      amountAux = minAmount;
    } else if (amountAux > maxAmount) {
      amountAux = maxAmount;
    }
    setInputManually(false);
    setAmount(amountAux);
    onChangeAmount(amountAux);
  };

  const handleChange = (event, value) => {
    setAmount(value);
    onChangeAmount(value);
  };

  const init = () => {
    if (loanRequest.response.prePaidLoanAmount) {
      setMinAmount(Math.ceil((installments[0].minimumAmount + loanRequest.response.prePaidLoanAmount) / 100) * 100);
      setPrepaidAmount(Math.ceil(loanRequest.response.prePaidLoanAmount / 100) * 100);
    } else {
      setMinAmount(Math.ceil(installments[0].minimumAmount / 100) * 100);
      setPrepaidAmount(0);
    }
    setMaxAmount(Math.floor(loanRequest.response.maxAmount / 100) * 100);
    setAmount(selectedAmount);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {amount && (
        <Box>
          <Box className="onboarding-slider-box">
            <Typography className="onboarding-slider-description">
              {i18n.OnboardingSlider.label}
              {!inputManually ? (
                <Typography className="onboarding-slider-amount" onClick={() => setInputManually(true)}>
                  {moneyFormatterWithoutDecimals(Math.ceil((amount - prepaidAmount) / 100) * 100)}
                </Typography>
              ) : (
                <Box className="onboarding-slider-input-box">
                  <TextInput
                    autoFocus
                    className="onboarding-slider-input"
                    variant="outlined"
                    type="number"
                    defaultValue={Math.ceil((amount - prepaidAmount) / 100) * 100}
                    inputProps={{ style: inputStyle }}
                    onBlur={(e) => handleChangeManually(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') handleChangeManually(e.target.value); }}
                  />
                </Box>
              )}
            </Typography>
          </Box>
          <Box mt={2} />
          <Grid container>
            <Grid item xs={12} md={6}>
              <Slider
                onChange={(event, value) => setAmount(value)}
                onChangeCommitted={handleChange}
                step={100}
                min={minAmount}
                max={maxAmount}
                value={amount}
                marks={getMarks()}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

OnboardingSlider.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedAmount: PropTypes.number.isRequired,
  onChangeAmount: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OnboardingSlider;
