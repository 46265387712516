import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import '../assets/css/onboardingFinal.css';
import i18n from '../common/i18n';
import circleCrossImg from '../assets/imgs/circle-cross.svg';
import WhatsappContact from '../components/WhatsappContact';

const OnboardingFinalAutomaticFailed = ({
  loanRequest, rechargeLoan, servicePayLoan, productAttributes,
}) => (
  <>
    {/* ********** AUTOMATICO (FAILED) ********** */}
    <Grid container>
      <Grid item>
        <Typography className="onboarding-final-title">
          {i18n.OnboardingFinal.automaticTitleFinish}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '40px', marginBottom: '40px' }}>
      <Grid item xs={3} sm={2}>
        <img src={circleCrossImg} alt="circle-cross" width="55px" />
      </Grid>
      <Grid item xs={9} sm={10}>
        <Typography className="onboarding-final-subtitle">
          {i18n.OnboardingFinal.automaticFailed.subTitle}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Grid item>
        <Typography className="onboarding-final-text">
          {i18n.OnboardingFinal.automaticFailed.textOne}

          {!rechargeLoan && !servicePayLoan && (
          <>
            {i18n.OnboardingFinal.transferToAccountMsg}
            <p>
              {' '}
              <br />
              {' '}
              {i18n.OnboardingFinal.bank}
              {' '}
              <b>
                {i18n.BANKS[loanRequest.bankAccountKey.substring(0, 3)]}
                .
              </b>
            </p>
          </>
          )}
          {rechargeLoan && (
          <>
            {i18n.OnboardingFinal.rechargeLoan}
            { productAttributes && productAttributes.IDC && (
            <>
              (
              <b>{productAttributes.IDC}</b>
              )
            </>
            )}
          </>
          )}
          {servicePayLoan && (
          <>
            {i18n.OnboardingFinal.servicePayLoan}
            { productAttributes && productAttributes.companyName && (
            <>
              (
              <b>{productAttributes.companyName}</b>
              )
            </>
            )}
          </>
          )}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginBottom: '60px' }}>
      <Grid item>
        <Typography className="onboarding-final-subtitle-pink">
          {i18n.OnboardingFinal.automaticFailed.textTwo}
        </Typography>
      </Grid>
    </Grid>

    <WhatsappContact />
  </>
);

OnboardingFinalAutomaticFailed.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  rechargeLoan: PropTypes.bool.isRequired,
  servicePayLoan: PropTypes.bool.isRequired,
  productAttributes: PropTypes.shape().isRequired,
};

export default OnboardingFinalAutomaticFailed;
