import { useState } from 'react';
import { Alert } from '@doit/pcnt-react-ui-library';
import { Snackbar } from '@mui/material';
import { onMessageListener } from '../utils/firebase';

const Notifications = () => {
  const [notification, setNotification] = useState();

  onMessageListener().then((payload) => {
    setNotification(payload.data);
  });

  const handleClick = () => {
    if (notification.redirect) {
      window.open(notification.redirect, '_blank');
    }
  };

  return (
    <>
      {notification && (
        <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            title={notification.title}
            severity="info"
            onClick={handleClick}
            onClose={() => setNotification(undefined)}
          >
            {notification.body}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Notifications;
