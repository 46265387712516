import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInputWrapper from './commons/TextInputWrapper';
import { dniSchemaValidation } from '../forms/schemaValidations';
import i18n from '../common/i18n';
import { sendDataLayerEventObject } from '../utils/functionsUtil';
import NavigationActions from './NavigationActions';

const DNIStep = ({ onSubmit, loading, onBack }) => {
  const methods = useForm({
    resolver: yupResolver(dniSchemaValidation),
    mode: 'onChange',
  });

  const handleSubmit = (data) => {
    sendDataLayerEventObject({ event: 'validar_dni' });
    onSubmit(data);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container justifySelf="left">
            <Grid item xs={12}>
              <Typography component="h2" variant="h1" align="left">
                {i18n.DNIStep.inputLabel}
              </Typography>
              <TextInputWrapper
                name="dni"
                label={i18n.DNIStep.inputPlaceHolder}
                helperText={i18n.DNIStep.inputHint}
                inputProps={{ maxLength: 9 }}
                mandatory
                fullWidth
              />
              <Box mt={4} />
            </Grid>
            <Grid item xs={12}>
              <NavigationActions
                onContinue={methods.handleSubmit(handleSubmit)}
                onBack={onBack}
                loading={loading}
                disabled={!methods.formState.isValid}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

DNIStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
};

DNIStep.defaultProps = {
  onBack: undefined,
};

export default DNIStep;
