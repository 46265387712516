import parse from 'html-react-parser';
import { Alert } from '@doit/pcnt-react-ui-library';
import { Grid, Typography } from '@mui/material';
import i18n from '../common/i18n';
import '../assets/css/onboardingFinal.css';
import locationIconImg from '../assets/imgs/location-icon.svg';
import locationLogoImg from '../assets/imgs/location-logo.svg';

const OnboardingFinalSucursal = () => (
  <>
    {/* ********** EFECTIVO SUCURSAL ********** */}
    <Grid container>
      <Grid item>
        <Typography className="onboarding-final-title">
          {i18n.OnboardingFinal.automaticTitleFinish}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '40px', marginBottom: '40px' }}>
      <Grid item xs={3} sm={2}>
        <img src={locationLogoImg} alt="location-logo" width="55px" />
      </Grid>
      <Grid item xs={9} sm={10}>
        <Typography className="onboarding-final-subtitle-long">
          {i18n.OnboardingFinal.sucursal.subTitle}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Grid item>
        <Typography className="onboarding-final-text">
          {parse(i18n.OnboardingFinal.sucursal.messageTimes)}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Grid item>
        <Typography className="onboarding-final-subtitle-pink">
          {i18n.OnboardingFinal.sucursal.subtileAddress}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Alert severity="info" sx={{ textAlign: 'left' }} icon={false}>
        <Grid container>
          <Grid item xs={2} sm={2}>
            <img src={locationIconImg} alt="location-img" width="20px" />
          </Grid>
          <Grid item xs={10} sm={10}>
            {i18n.OnboardingFinal.sucursal.textAddress}
          </Grid>
        </Grid>
      </Alert>
    </Grid>

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1780.1758048704723!2d-65.20955742891654!3d-26.828766774759348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225c13e3a68f31%3A0x6cd6b3d939ce248d!2sGral. José de San Martín 797%2C T4000 San Miguel de Tucumán%2C Tucumán%2C Argentina!5e0!3m2!1ses-419!2sbr!4v1688580736132!5m2!1ses-419!2sbr"
      title="efectivo"
      style={{
        border: 0, width: '100%', marginTop: '5px', marginBottom: '20px',
      }}
      allowfullscreen=""
      referrerPolicy="no-referrer-when-downgrade"
    />
  </>
);

export default OnboardingFinalSucursal;
