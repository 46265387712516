import { Box } from '@mui/material';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import i18n from '../common/i18n';
import Footer, { FooterType } from '../components/commons/Footer';
import RejectionMessage from '../components/RejectionMessage';
import imgWarning from '../assets/imgs/warning.svg';
import { ERROR_CODES } from '../utils/errorCodeConstant';

const OnboardingErrorCode = () => {
  const { errorCode } = useParams();
  const [image, setImage] = useState();
  const [paragraph1, setParagraph1] = useState();
  const [paragraph2, setParagraph2] = useState();

  useEffect(() => {
    const init = async () => {
      let info;
      switch (errorCode) {
        case (ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE):
          info = i18n.RejectionData.SERVICE_UNAVAILABLE_FOR_MAINTENANCE;
          setParagraph1(parse(info.paragraph1));
          setParagraph2(info.paragraph2);
          setImage({ src: imgWarning, alt: info.altImg, width: '330px' });
          break;
        default: break;
      }
    };
    init();
  }, []);

  return (
    <>
      <Box className="main-container-body">
        <Box mt={1} />
        <RejectionMessage image={image} paragraph1={paragraph1} paragraph2={paragraph2} />
      </Box>
      <Footer type={FooterType.DEFAULT} />
    </>
  );
};

export default OnboardingErrorCode;
