import { Snackbar, Alert } from '@mui/material';
import PropTypes from 'prop-types';

const Toast = ({
  messageOnSuccess, messageOnError, open, onClose,
}) => {
  const severity = messageOnSuccess ? 'success' : 'error';
  const message = messageOnSuccess || messageOnError;
  const handleOnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  return (
    <>
      <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleOnClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

Toast.propTypes = {
  messageOnSuccess: PropTypes.string,
  messageOnError: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
Toast.defaultProps = {
  messageOnSuccess: undefined,
  messageOnError: undefined,
};
export default Toast;
