import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { emailSchemaValidation } from '../forms/schemaValidations';
import i18n from '../common/i18n';
import TextInputWrapper from './commons/TextInputWrapper';
import { sendDataLayerEventObject } from '../utils/functionsUtil';
import NavigationActions from './NavigationActions';

const EmailStep = ({ onSubmit, loading, goBack }) => {
  const methods = useForm({
    resolver: yupResolver(emailSchemaValidation),
    mode: 'onChange',
  });

  const handleSubmit = (data) => {
    sendDataLayerEventObject({ event: 'cargar_email' });
    onSubmit(data);
  };

  return (
    <>
      <Typography variant="h1" align="left">{i18n.EmailStep.title}</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container justifySelf="left">
            <TextInputWrapper
              name="email"
              label={i18n.EmailStep.inputLabel}
              mandatory
              fullWidth
            />
          </Grid>
          <Box mt={4} />
          <NavigationActions
            onContinue={methods.handleSubmit(handleSubmit)}
            onBack={goBack}
            loading={loading}
            disabled={!methods.formState.isValid}
          />
        </form>
      </FormProvider>
    </>
  );
};

EmailStep.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default EmailStep;
