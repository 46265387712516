import { useEffect, useState } from 'react';
import { AppBar, Button, Img } from '@doit/pcnt-react-ui-library';
import TagManager from 'react-gtm-module';
import logoCredicuotas from '../../assets/imgs/logo-credicuotas.svg';
import logoUber from '../../assets/imgs/logo-uber.svg';
import i18n from '../../common/i18n';
import {
  getEnv, CREDICUOTAS_WEBSITE_URL, SELF_MANAGEMENT_URL, GOOGLE_TAG_MANAGER_ID,
} from '../../api/env';
import { getLoanRequest } from '../../api/onboardingService';

const AppBarWrapper = () => {
  const UBER = 'uber';
  const [logo, setLogo] = useState(logoCredicuotas);

  const goHome = () => {
    window.location = getEnv(CREDICUOTAS_WEBSITE_URL);
  };

  const goToSelfManagement = () => {
    window.open(getEnv(SELF_MANAGEMENT_URL), '_blank');
  };

  const resolveLogo = (partner) => {
    switch (partner.toLowerCase()) {
      case UBER: setLogo(logoUber); break;
      default: setLogo(logoCredicuotas); break;
    }
  };

  const resolvePartnerDataUsingHash = async () => {
    try {
      const hash = window.location.pathname.split('/')[2];
      if (hash && hash.startsWith('orig_')) {
        const { data } = await getLoanRequest(hash);
        if (data && data.partnerUserData && data.partnerUserData.partnerName) {
          resolveLogo(data.partnerUserData.partnerName);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = () => {
    if (window.location.pathname.includes(UBER)) {
      setLogo(logoUber);
    } else {
      resolvePartnerDataUsingHash();
    }
  };

  useEffect(() => {
    init();
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
  }, []);

  return (
    <>
      <AppBar
        logo={<Img className="app-bar-logo" onClick={goHome} alt="Credicuotas" src={logo} height={21} />}
        variant="dense"
        position="fixed"
      >
        <Button variant="primary" size="small" className="app-bar-btn" onClick={goToSelfManagement}>
          {i18n.AppBarWrapper.btnLabel}
        </Button>
      </AppBar>
    </>
  );
};

export default AppBarWrapper;
