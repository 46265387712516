import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import OnboardingQRCode from './OnboardingQRCode';
import DocumentLoanStep from './DocumentLoadStep';
import validationDocument from '../assets/imgs/validation-dni.svg';
import validationSelfie from '../assets/imgs/validation-selfie.svg';
import validateIdentity from '../assets/imgs/validate-identity.svg';
import { isAllowedContinueOnDesktop, isMobileOperatingSystem } from '../utils/functionsUtil';
import DocumentLoadCountDown from './DocumentLoadCountDown';

const PreDocumentLoad = ({
  loan, onSendMessage, showCountDown, onCountDown, onContinue,
}) => {
  const [QR, setQR] = useState();

  const init = () => {
    setQR(`${window.location.href}?qr=${loan.sappCreditId}`);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Typography className="document-load-title">{i18n.PreDocumentLoad.title}</Typography>
      <Box mt={3} />
      {isMobileOperatingSystem() ? (
        <>
          <Typography className="document-load-subtitle">{i18n.PreDocumentLoad.subtitle}</Typography>
          <Box mt={3} />
          <img src={validateIdentity} alt="IMG" width="90%" />
          <Box mt={5} />
          <Button
            variant="primary"
            className="btn-primary"
            type="button"
            fullWidth
            onClick={onContinue}
          >
            {i18n.PreDocumentLoad.continueCTA}
          </Button>
        </>
      ) : (
        <>
          {QR && !showCountDown && (
            <OnboardingQRCode value={QR} onClick={onSendMessage} />
          )}
          {showCountDown && (
            <DocumentLoadCountDown onFinish={onCountDown} />
          )}
          <Box mt={3} />
          <Typography className="document-load-description">{i18n.PreDocumentLoad.description}</Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box>
              <DocumentLoanStep
                step="1"
                img={validationDocument}
                items={[i18n.PreDocumentLoad.stepDNI[0], i18n.PreDocumentLoad.stepDNI[1]]}
              />
            </Box>
            <Box sx={{ marginLeft: '30px' }}>
              <DocumentLoanStep
                step="2"
                img={validationSelfie}
                items={[i18n.PreDocumentLoad.stepSelfie]}
              />
            </Box>
          </Box>
          {/* Se mostrará el boton de continuar en DESKTOP solo si esta encendido en el .env */}
          {isAllowedContinueOnDesktop() && (
            <Box mt={5}>
              <Button
                variant="secondary"
                className="btn-primary"
                type="button"
                onClick={onContinue}
              >
                {i18n.PreDocumentLoad.continueOnDesktopCTA}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

PreDocumentLoad.propTypes = {
  loan: PropTypes.shape().isRequired,
  onSendMessage: PropTypes.func.isRequired,
  showCountDown: PropTypes.bool.isRequired,
  onCountDown: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
};

PreDocumentLoad.defaultProps = {
  onContinue: undefined,
};

export default PreDocumentLoad;
