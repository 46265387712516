import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InstallmentCard from './InstallmentCard';
import LoadingOverlayCustom from './commons/LoadingOverlayCustom';

const InstallmentCardList = ({
  installments, installmentsOriginalLine, onSelect, loading,
}) => {
  const [installmentSelected, setInstallmentSelected] = useState();

  const isSelected = (installment) =>
    installmentSelected && installmentSelected.installments === installment.installments;

  const getOriginalInstallmentAmount = (installmentQty) => {
    let value;
    if (installmentsOriginalLine && installmentsOriginalLine.length > 0) {
      const instOrigLine = installmentsOriginalLine.find((inst) => inst.installments === installmentQty);
      if (instOrigLine) {
        value = instOrigLine.amount;
      }
    }
    return value;
  };

  const handleSelect = (installment) => {
    setInstallmentSelected(installment);
    onSelect(installment);
  };

  const init = () => {
    handleSelect(installments[0]);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <LoadingOverlayCustom loading={loading}>
        <Grid container rowSpacing={1}>
          {installments.map((installment) => (
            <Grid item xs={12} key={installment.installments}>
              <InstallmentCard
                installment={installment}
                onSelect={handleSelect}
                isSelected={isSelected(installment)}
                originalAmount={getOriginalInstallmentAmount(installment.installments)}
              />
            </Grid>
          ))}
        </Grid>
      </LoadingOverlayCustom>
    </>
  );
};

InstallmentCardList.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  installmentsOriginalLine: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

InstallmentCardList.defaultProps = {
  installmentsOriginalLine: undefined,
};

export default InstallmentCardList;
