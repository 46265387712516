import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Link, SimpleSelect } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import '../assets/css/payServices.css';

/* eslint arrow-body-style:off */
const PayServicesBusinessSelect = ({
  selectedServiceCompany,
  selectedCompanyList,
  paymentBillDetailList,
  setServiceSelected,
  cleanServiceCompanyInit,
}) => {
  const info = i18n.PRODUCT_LOAD.PAY_SERVICE;
  const selectService = async (element) => {
    const serviceSelectedTemp = element;
    serviceSelectedTemp.form = serviceSelectedTemp.form.sort((a, b) => { return a.position - b.position; });
    serviceSelectedTemp.companyCode = selectedServiceCompany.code;
    await setServiceSelected(serviceSelectedTemp);
  };

  return (
    <>
      <Typography className="services-service-selected">{selectedServiceCompany.name}</Typography>
      {/* eslint-disable-next-line */}
      <Link size='small' underline='always' className="font-family-OpenSans" onClick={cleanServiceCompanyInit}>{info.editServiceCompanyLink}</Link>

      { /* Muestra listado de servicios que puede pagar para la compañia,
                    por ejemplo si selecciono Municipalidad Mendoza, le mostrará Impuesto Automotor, ABL, etc. */ }
      {
        selectedCompanyList && selectedCompanyList.length > 0 && !paymentBillDetailList && (
          <Grid item>
            <Typography className="services-input-label">{info.searchMethodsHint}</Typography>
            <SimpleSelect
              fullWidth
              className="services-input"
              label={info.searchMethodsSelectLabel}
              onChange={selectService}
              options={selectedCompanyList.map((service) => {
                return {
                  label: service.description,
                  value: service,
                };
              })}
            />
          </Grid>
        )
      }
    </>
  );
};

PayServicesBusinessSelect.propTypes = {
  selectedServiceCompany: PropTypes.shape(),
  selectedCompanyList: PropTypes.arrayOf(PropTypes.shape()),
  paymentBillDetailList: PropTypes.arrayOf(PropTypes.shape()),
  setServiceSelected: PropTypes.func,
  cleanServiceCompanyInit: PropTypes.func,
};

PayServicesBusinessSelect.defaultProps = {
  selectedServiceCompany: undefined,
  selectedCompanyList: undefined,
  paymentBillDetailList: undefined,
  setServiceSelected: undefined,
  cleanServiceCompanyInit: undefined,
};

export default PayServicesBusinessSelect;
