import { isMobileOperatingSystem } from '../utils/functionsUtil';
import { fetch, fetchMultipart } from './axiosInstance';

export const getSiteBanner = () =>
  fetch({
    url: '/onboarding/resources/site/images',
  });

export const getServiceCompaniesLogos = () =>
  fetch({
    url: '/onboarding/services/companies/logos',
  });

export const resolveCustomers = (custId) =>
  fetch({
    url: `/onboarding/resolvecustomers/${custId}`,
    method: 'get',
  });

export const getPartnershipUserData = (code, partner) =>
  fetch({
    url: `/onboarding/partnerUserData/${code}/${partner}`,
    method: 'get',
  });

export const updateCustomerData = (customer, email, idSubProduct, partnerUserCode, partnerName) =>
  fetch({
    url: '/onboarding/updatecustomerdata',
    method: 'post',
    data: {
      customerTaxId: customer?.cuit,
      fullname: customer?.nombrecompleto,
      sex: customer?.sexo,
      birthdate: customer?.fechanacimiento,
      customerEmail: email,
      idSubProduct,
      userAgent: isMobileOperatingSystem() ? window.navigator.userAgent : undefined,
      partnerUserCode,
      partnerName,
      pep: customer?.pep,
      so: customer?.so,
    },
  });

export const verifyEmailDomain = (email) =>
  fetch({
    url: '/onboarding/verifyDomain',
    method: 'post',
    data: email,
  });

export const updateSpecificCustomerData = (customer) =>
  fetch({
    url: '/onboarding/updateSpecificCustomerData',
    method: 'post',
    data: {
      customerTaxId: customer?.cuit,
      fullname: customer?.nombrecompleto,
      sex: customer?.sexo,
      birthdate: customer?.fechanacimiento,
      pep: customer?.pep,
      so: customer?.so,
    },
  });

export const checkBlacklist = (phoneNumber) =>
  fetch({
    url: '/onboarding/checkblacklist',
    method: 'post',
    data: {
      userInputValue: phoneNumber,
    },
  });

export const sendVerificationCode = (inputValue, phoneNumber, email, resend) =>
  fetch({
    url: '/onboarding/sendcode',
    method: 'post',
    data: {
      userInputName: 'WT_PHONE',
      userInputValue: inputValue,
      userCellnumber: phoneNumber,
      userEmail: email,
      resend,
    },
  });

export const savePreSimulationLoan = (customerCellphone, customerEmail, customerData, type) =>
  fetch({
    url: '/onboarding/savepresimulationloan',
    method: 'post',
    data: {
      userCellnumber: customerCellphone,
      userEmail: customerEmail,
      userInputName: customerData?.nombrecompleto,
      userId: customerData?.dni,
      userAmount: 75000,
      userDestinationLoan: 'OTHER',
      userType: type,
    },
  });

export const verifyCode = (verificationData) =>
  fetch({
    url: '/onboarding/verifycode',
    method: 'post',
    data: verificationData,
  });

export const startWorkflow = () =>
  fetch({
    url: '/onboarding/start',
    method: 'get',
  });

export const getLoanRequest = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}`,
    method: 'get',
  });

export const getLoanStatus = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/status`,
    method: 'get',
  });

export const getDisbursementStatus = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/disbursement`,
    method: 'get',
  });

export const dismiss = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/dismiss`,
    method: 'post',
  });

export const getSurvey = () =>
  fetch({
    url: '/onboarding/nps',
    method: 'get',
  });

export const skipSurvey = (hash, nps) =>
  fetch({
    url: `/onboarding/nps/skip/${hash}`,
    method: 'post',
    data: nps,
  });

export const saveSurveyAnswer = (hash, nps) =>
  fetch({
    url: `/onboarding/nps/${hash}`,
    method: 'post',
    data: nps,
  });

export const getFooterData = (type) =>
  fetch({
    url: `/webParameters/footerData/${type}`,
    method: 'get',
  });

export const checkExistingLoanRequest = (customerTaxId) =>
  fetch({
    url: `/onboarding/checkExistingLoanRequest/${customerTaxId}`,
    method: 'get',
  });

export const updateVerifiedCodeAndEmail = (phoneNumber, customerMail, hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/userInputVerification/updateVerifiedCodeAndEmail`,
    method: 'post',
    data: {
      userInputValue: phoneNumber,
      userEmail: customerMail,
    },
  });

export const verifyEmail = (hash) =>
  fetch({
    url: `/onboarding/verifyemail/${hash}`,
    method: 'post',
  });

export const updateUrlSourceParameters = (hash, params) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/urlSourceParameters`,
    method: 'put',
    data: params,
  });

export const saveMobileUserAgent = (hash, userAgent) =>
  fetch({
    url: `/onboarding/savemobileuseragent/${hash}`,
    method: 'post',
    data: { userAgent },
  });

export const getOnboardingOffer = (hash) =>
  fetch({
    url: `/onboarding/offer/${hash}`,
    method: 'get',
  });

export const getOnboardingCustomOffer = (hash, amount, needInstallmentsDetail) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments/${amount}?needInstallmentsDetail=${needInstallmentsDetail}`,
    method: 'get',
  });

export const getOnboardingCustomOfferWithOriginalLine = (hash, amount, lineId) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments/${amount}/originalLine/${lineId}`,
    method: 'get',
  });

export const getServicesCompanies = (hash, searchString) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/companies/${searchString}`,
    method: 'get',
  });

export const getPaymentModes = (hash, companyCode) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/companies/${companyCode}/modes`,
    method: 'get',
  });

export const getPaymentBarcodeDetail = (hash, companyCode, barcode) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/companies/${companyCode}/barcode/${barcode}`,
    method: 'get',
  });

export const getPaymentBillDetail = (hash, companyCode, paymentMode, form) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/companies/${companyCode}/paymentMode/${paymentMode}`,
    method: 'post',
    data: form,
  });

export const addProductToPayBill = (hash, data) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/products/bill`,
    method: 'post',
    data,
  });

export const getOnboardingOfferDual = (hash) =>
  fetch({
    url: `/onboarding/offer/${hash}/dual`,
    method: 'get',
  });

export const getOnboardingCustomOfferDual = (hash, amount, needInstallmentsDetail) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments/${amount}/dual?needInstallmentsDetail=${needInstallmentsDetail}`,
    method: 'get',
  });

export const getOnboardingCustomOfferWithOriginalLineDual = (hash, amount, lineId) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments/${amount}/originalLine/${lineId}/dual`,
    method: 'get',
  });

export const setInstallment = (hash, installment) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments`,
    method: 'put',
    data: {
      installmentAmountSelected: installment.amount,
      installmentsNumberSelected: installment.installments,
      idFinanceLine: installment.idLinea,
      requestedAmount: installment.requestedAmount,
      dual: installment.isDual,
    },
  });

export const getRechargeCompaniesDetail = (hash) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/companies/recharge`,
    method: 'get',
  });

export const addProductToPayRecharge = (hash, data) =>
  fetch({
    url: `/productpayment/loanRequest/${hash}/products/recharge`,
    method: 'post',
    data,
  });

export const sendLinkToContinueDocumentsUploadFromMobile = (hash) =>
  fetch({
    url: `/onboarding/${hash}/userupload/sendmobilesms`,
    method: 'post',
  });

export const getDocumentsLoaded = (hash) =>
  fetch({
    url: `/onboarding/documentsLoaded/${hash}`,
    method: 'get',
  });

export const reminder = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/reminder`,
    method: 'post',
  });

export const getDocuments = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/documents`,
    method: 'get',
  });

export const getNextDocumentoToUpload = (hash, currentDocumentName) =>
  fetch({
    url: `/onboarding/${hash}/userupload/next`,
    method: 'get',
    params: {
      currentUserUploadName: currentDocumentName,
    },
  });

export const getUrlForUpload = (hash, currentDocumentName, file) =>
  fetchMultipart({
    url: `/onboarding/${hash}/userupload/${currentDocumentName}?uploadMethod=WEB`,
    method: 'post',
    data: { docFile: file },
  });

export const getBiometricOnboardingData = (hash) =>
  fetch({
    url: `/onboarding/4id/${hash}`,
    method: 'get',
  });

export const saveBiometricDataResult = (externalId, txid) =>
  fetch({
    url: `/onboarding/4id/externalid/${externalId}/txid/${txid}`,
    method: 'post',
  });

export const getStepBiometricData = (externalId) =>
  fetch({
    url: `/onboarding/4id/externalid/${externalId}`,
    method: 'get',
  });

export const getSummaryDataLoan = (hash) =>
  fetch({
    url: `/onboarding/summary/${hash}`,
    method: 'get',
  });

export const getHashLoanByMinifyIdWithUrlParams = (minHash) =>
  fetch({
    url: `/onboarding/redirectwithurlparams/${minHash}`,
    method: 'get',
  });

export const getHashLoanByMinifyId = (minHash) =>
  fetch({
    url: `/onboarding/redirect/${minHash}`,
    method: 'get',
  });

export const pushNotificationSubscribe = (data) =>
  fetch({
    url: '/onboarding/push-notifications/subscribe',
    method: 'post',
    data,
  });

export const getUserInputFields = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/userinputs`,
    method: 'get',
  });

export const getProvinces = () =>
  fetch({
    url: '/onboarding/states',
    method: 'get',
  });

export const resolveConditionalComboData = (hash, varName, conditioningValue) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/userinput/combodata/${varName}/${conditioningValue}`,
    method: 'get',
  });

export const setCustomerData = (hash, customerData) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/customerdata`,
    method: 'post',
    data: {
      hashkey: hash,
      customerDataMap: customerData,
    },
  });

export const resolveBankAccount = (hash, bankAccountKey) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/resolveBankAccount/${bankAccountKey}`,
    method: 'get',
  });

export const sendVerificationCodeForHash = (hash, userInputName, userInputValue, userEmail, resend) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/userInputVerification/sendcode`,
    method: 'post',
    data: {
      userInputName,
      userInputValue,
      userCellnumber: userInputValue,
      userEmail,
      resend,
    },
  });

export const verifyCodeForHash = (hash, userInputName, userInputValue, userInputVerificationCode) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/userInputVerification/verifycode`,
    method: 'post',
    data: {
      userInputName,
      userInputValue,
      userInputVerificationCode,
    },
  });

export const validateDebitCard = (hash, debitCardDTO) =>
  fetch({
    url: `/onboarding/checkDebitCard/${hash}`,
    method: 'post',
    data: debitCardDTO,
  });

export const getCardinalAssistanceInfo = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/getCardinalAssistanceInfo`,
    method: 'get',
  });

export const saveAdditionalProductRequest = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/saveAdditionalProductRequest`,
    method: 'post',
  });

export const getPrepaidCardLastFour = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/getPrepaidCardLastFour`,
    method: 'get',
  });

export const automaticSign = (hash) =>
  fetch({
    url: `/onboarding/${hash}/automaticSign`,
    method: 'post',
  });
