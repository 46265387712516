import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay-ts';
import i18n from '../../common/i18n';

const LoadingOverlayCustom = ({ children, loading }) => (
  <LoadingOverlay
    active={loading}
    spinner
    text={i18n.loadingMessage}
    styles={{
      overlay: (base) => ({
        ...base,
        background: 'rgba(200, 200, 200, 0.7)',
      }),
      spinner: (base) => ({
        ...base,
        '& svg circle': {
          stroke: '#F2025D',
        },
      }),
    }}
  >
    {children}
  </LoadingOverlay>
);

LoadingOverlayCustom.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoadingOverlayCustom;
