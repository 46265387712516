import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import DialogWrapper from './commons/DialogWrapper';
import i18n from '../common/i18n';
import { getLoanRequest } from '../api/onboardingService';
import { capitalize, dateFormatter, moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';

const ContinueLoanRequestModal = ({ hash, onClose, onContinue }) => {
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [ctaLabel, setCtaLabel] = useState();
  const [open, setOpen] = useState();

  const resolveModal = async () => {
    try {
      const res = await getLoanRequest(hash);

      const loanRequest = res.data;
      const customerName = capitalize(loanRequest.customerName);
      const prevAmount = loanRequest.requestedAmount !== null
        ? loanRequest.requestedAmount
        : loanRequest.response.maxAmount;
      const amount = moneyFormatterWithoutDecimals(prevAmount);
      const date = dateFormatter(loanRequest.date);

      if (loanRequest.welcomeInbox) {
        // solicitud en bandeja
        setTitle(i18n.ContinueLoanRequestModal.finalizedLoan.title);
        setCtaLabel(i18n.ContinueLoanRequestModal.finalizedLoan.cta);
        let description = fillMessageWith(
          i18n.ContinueLoanRequestModal.finalizedLoan.description[0], customerName, amount, date,
        );
        if (loanRequest.status === 'PRE') {
          description = `${description}. \n${i18n.ContinueLoanRequestModal.finalizedLoan.description[1]}`;
        } else if (loanRequest.status === 'LIQ' && loanRequest.disbursementMode === 'EFECTIVO') {
          description = `${description}. \n${i18n.ContinueLoanRequestModal.finalizedLoan.description[2]}`;
        } else if (loanRequest.status === 'LIQ' && loanRequest.disbursementMode === 'CBU') {
          description = `${description}. \n${i18n.ContinueLoanRequestModal.finalizedLoan.description[3]}`;
        }
        setMessage(description);
      } else {
        // solicitud pendiente
        setTitle(i18n.ContinueLoanRequestModal.pendingLoan.title);
        setCtaLabel(i18n.ContinueLoanRequestModal.pendingLoan.cta);
        setMessage(fillMessageWith(i18n.ContinueLoanRequestModal.pendingLoan.description, customerName, amount, date));
      }
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const init = () => {
    resolveModal();
  };

  useEffect(() => init(), []);

  return (
    <>
      <DialogWrapper
        open={open}
        title={title}
        message={message}
        onClose={onClose}
        primaryAction={onContinue}
        primaryActionLabel={ctaLabel}
      />
    </>
  );
};

ContinueLoanRequestModal.propTypes = {
  hash: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default ContinueLoanRequestModal;
