import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Alert } from '@doit/pcnt-react-ui-library';
import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import '../assets/css/onboardingFinal.css';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import phoneCallImg from '../assets/imgs/phone-call.svg';
import phoneIconImg from '../assets/imgs/phone-icon.svg';
import WhatsappContact from '../components/WhatsappContact';

const OnboardingFinalManual = ({ loanRequest }) => {
  const [isDayAndHourWorking, setIsDayAndHourWorking] = useState(false);
  const isRevolvingLine = () => loanRequest.productId === 46;

  const init = async () => {
    const today = new Date();
    const currentHour = today.getHours();
    const currentDayOfWeek = today.getDay();
    if ((currentDayOfWeek !== 0 && currentDayOfWeek !== 6 && currentHour >= 9 && currentHour < 21)
      || (currentDayOfWeek === 6 && currentHour >= 9 && currentHour < 21)) {
      setIsDayAndHourWorking(true);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {/* ********** MANUAL ********** */}
      <Grid container>
        <Grid item>
          <Typography className="onboarding-final-title">
            {i18n.OnboardingFinal.automaticTitleFinish}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '40px', marginBottom: '40px' }}>
        <Grid item xs={3} sm={2}>
          <img src={phoneCallImg} alt="phone-call" width="55px" />
        </Grid>
        <Grid item xs={9} sm={10}>
          <Typography className="onboarding-final-subtitle-long">
            {i18n.OnboardingFinal.manual.subTitle}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Grid item>
          <Typography className="onboarding-final-text">
            {parse(fillMessageWith(
              isDayAndHourWorking
                ? i18n.OnboardingFinal.manual.textWorkingHour : i18n.OnboardingFinal.manual.textNonWorkingHour,
              loanRequest.customerPhone,
              loanRequest.disbursementMode === 'PREPAID_CARD'
                ? fillMessageWith(i18n.OnboardingFinal.manual.textLoan,
                  isRevolvingLine() ? 'crédito' : 'préstamo')
                : i18n.WT_SUBPRODUCT_DESCRIPTION[loanRequest?.subProductId],
            ))}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Alert severity="info" sx={{ textAlign: 'left' }} icon={false}>
          <Grid container>
            <Grid item xs={2} sm={2}>
              <img src={phoneIconImg} alt="phone-icon" width="35px" />
            </Grid>
            <Grid item xs={10} sm={10}>
              {i18n.OnboardingFinal.manual.textTwo}
            </Grid>
          </Grid>
        </Alert>
      </Grid>

      <WhatsappContact
        message={i18n.OnboardingFinal.manual.textContact}
      />
    </>
  );
};

OnboardingFinalManual.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default OnboardingFinalManual;
