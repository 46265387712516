import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { getLoanRequest } from '../api/onboardingService';
import { useToaster } from '../contexts/ToasterContext';
import i18n from '../common/i18n';
import { getErrorCodeMessage } from '../utils/functionsUtil';

const useIncompatibleBrowser = () => {
  const { showToast } = useToaster();
  const { hash } = useParams();
  const [, copyToClipboard] = useCopyToClipboard();
  const [loading, setLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();

  const doCopy = () => {
    copyToClipboard(`${window.location.hostname}/m/${hash}`);
    showToast(i18n.OnboardingIncompatibleBrowser.ctaToast, 'success');
  };

  const init = async () => {
    try {
      window.scrollTo(0, 0);
      setLoading(true);
      const loanRequestResponse = await getLoanRequest(hash);
      setLoanRequest(loanRequestResponse.data);
    } catch (error) {
      showToast(getErrorCodeMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading, loanRequest, doCopy,
  };
};

export default useIncompatibleBrowser;
