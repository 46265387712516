import { HelmetProvider, Helmet } from 'react-helmet-async';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { routes } from '../constants/routes';
import { UserDataProvider } from '../contexts/userDataContext';
import AppBarWrapper from '../components/commons/AppBarWrapper';
import PreOnboardingPersonalLoan from '../pages/preOnboardingPersonalLoan';
import PreOnboardingServicesLoan from '../pages/preOnboardingServicesLoan';
import PreOnboardingRechargeLoan from '../pages/preOnboardingRechargeLoan';
import PreOnboardingUberLoan from '../pages/preOnboardingUberLoan';
import PreOnboardingOnePage from '../pages/preOnboardingOnePage';
import PreOnboardingAdwords from '../pages/preOnboardingAdwords';
import OnboardingRejection from '../pages/onboardingRejection';
import OnboardingErrorCode from '../pages/onboardingErrorCode';
import OnboardingInstallmentPlan from '../pages/onboardingInstallmentPlan';
import OnboardingProductLoad from '../pages/onboardingProductLoad';
import OnboardingDocumentLoad from '../pages/onboardingDocumentLoad';
import OnboardingCustomerData from '../pages/onboardingCustomerData';
import OnboardingProcessBiometricResult from '../pages/onboardingProcessBiometricResult';
import OnboardingFinal from '../pages/onboardingFinal';
import OnboardingRedirect from '../pages/onboardingRedirect';
import { GlobalLoadingProvider } from '../contexts/globalLoadingContext';
import OnboardingSummary from '../pages/onboardingSummary';
import OnboardingDownloadDisclaimer from '../pages/onboardingDownloadDisclaimer';
import Notifications from '../components/Notifications';
import PreOnboardingRevolvingLine from '../pages/preOnboardingRevolvingLine';
import OnboardingIncompatibleBrowser from '../pages/onboardingIncompatibleBrowser';

/* eslint arrow-body-style:off */
const AppRouter = () => {
  return (
    <>
      <Notifications />
      <AppBarWrapper />
      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            <title>{window.location.hostname}</title>
            <meta name="robots" content="noindex" />
            <meta name="google" content="notranslate" />
          </Helmet>
          <UserDataProvider>
            <GlobalLoadingProvider>
              <Routes>
                <Route exact path={routes.preOnboardingPersonalLoan} element={<PreOnboardingPersonalLoan />} />
                <Route exact path={routes.preOnboardingServicesLoan} element={<PreOnboardingServicesLoan />} />
                <Route exact path={routes.preOnboardingRechargeLoan} element={<PreOnboardingRechargeLoan />} />
                <Route exact path={routes.preOnboardingUberLoan} element={<PreOnboardingUberLoan />} />
                <Route exact path={routes.preOnboardingOnePage} element={<PreOnboardingOnePage />} />
                <Route exact path={routes.preOnboardingAdwords} element={<PreOnboardingAdwords />} />
                <Route exact path={routes.preOnboardingRevolvingLine} element={<PreOnboardingRevolvingLine />} />
                <Route exact path={`${routes.OnboardingRejection}/:hash`} element={<OnboardingRejection />} />
                <Route exact path={`${routes.OnboardingErrorCode}/:errorCode`} element={<OnboardingErrorCode />} />
                <Route exact path={`${routes.OnboardingInstallmentPlan}/:hash`} element={<OnboardingInstallmentPlan />} />
                <Route exact path={`${routes.OnboardingProductLoad}/:hash`} element={<OnboardingProductLoad />} />
                <Route exact path={`${routes.OnboardingDocumentsLoad}/:hash`} element={<OnboardingDocumentLoad />} />
                <Route exact path={`${routes.OnboardingCustomerData}/:hash`} element={<OnboardingCustomerData />} />
                <Route exact path={routes.OnboardingProccessBiometric} element={<OnboardingProcessBiometricResult />} />
                <Route exact path={`${routes.OnboardingIncompatibleBrowser}/:hash`} element={<OnboardingIncompatibleBrowser />} />
                <Route exact path={`${routes.OnboardingSummary}/:hash`} element={<OnboardingSummary />} />
                <Route exact path={`${routes.OnboardingFinal}/:hash`} element={<OnboardingFinal />} />
                <Route exact path={`${routes.OnboardingRedirect}/:hash`} element={<OnboardingRedirect />} />
                <Route
                  exact
                  path={`${routes.OnboardingDownloadDisclaimer}/:minHash`}
                  element={<OnboardingDownloadDisclaimer />}
                />
                <Route path="*" element={<Navigate to={routes.preOnboardingPersonalLoan} />} />
              </Routes>
            </GlobalLoadingProvider>
          </UserDataProvider>
        </HelmetProvider>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
