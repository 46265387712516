import { TextInput } from '@doit/pcnt-react-ui-library';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import '../assets/css/onboardingCustomer.css';

const CustomerDataInputTextArea = ({
  variable, handleChange, userFilled, turnOnValidations,
}) => {
  useEffect(() => {}, [userFilled[variable.name]]);

  return (
    <>
      <TextInput
        name={variable.name}
        label={variable.userInputOptions.label}
        value={userFilled[variable.name]}
        error={variable.userInputOptions.required && turnOnValidations
          && (userFilled[variable.name] === undefined
            || (userFilled[variable.name].toString()).trim() === '')}
        inputProps={{
          type: variable.userInputOptions.mask === '(9999)999999?9?9'
            || variable.userInputOptions.mask === '9999999999?9?9' ? 'tel' : 'text',
        }}
        onChange={(event) => handleChange(variable, event.target.value)}
        required={variable.userInputOptions.required}
        multiline
        fullWidth
      />
      <Typography className="customer-data-custom-hint">
        <span style={{
          color: (variable.userInputOptions.required && turnOnValidations
                  && (userFilled[variable.name] === undefined
                  || (userFilled[variable.name].toString()).trim() === '') ? '#E20000' : '#00000099'),
        }}
        >
          {variable.userInputOptions.hint}
        </span>
      </Typography>
    </>
  );
};

CustomerDataInputTextArea.propTypes = {
  variable: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  userFilled: PropTypes.shape().isRequired,
  turnOnValidations: PropTypes.bool.isRequired,
};

export default CustomerDataInputTextArea;
