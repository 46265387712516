import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import parse from 'html-react-parser';
import i18n from '../common/i18n';
import Footer, { FooterType } from '../components/commons/Footer';
import Toast from '../components/commons/Toast';

import { getLoanRequest, saveMobileUserAgent } from '../api/onboardingService';
import {
  isMobileOperatingSystem, fillMessageWith, sendDataLayerCustomerInfo, getResponseErrorOrDefault,
} from '../utils/functionsUtil';
import { capitalizeEachWord, moneyFormatter } from '../utils/formatterUtil';

import RejectionMessage from '../components/RejectionMessage';
import { REJECTION_CODES } from '../utils/errorCodeConstant';
import imgActiveLoanTrophy from '../assets/imgs/active-loan-trophy.svg';
import imgAgeless from '../assets/imgs/sin-edad.svg';
import imgDelay from '../assets/imgs/mora.svg';
import imgMonth from '../assets/imgs/meses.svg';
import imgNotAccount from '../assets/imgs/no-account.svg';
import imgNotIncome from '../assets/imgs/sin-ingreso.svg';
import imgOthers from '../assets/imgs/others.svg';
import imgPreCustomer from '../assets/imgs/cliente-pre.svg';
import imgSad from '../assets/imgs/sad.svg';
import imgSadBnpl from '../assets/imgs/sad-bnpl.svg';
import imgYounger from '../assets/imgs/edad-menor.svg';

const OnboardingRejection = () => {
  const { hash } = useParams();
  const [image, setImage] = useState();
  const [paragraph1, setParagraph1] = useState();
  const [paragraph2, setParagraph2] = useState();
  const [button, setButton] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const getEcommerceProduct = (loan) => loan.productToPay.products[0] || null;
  const goToMerchantReturnFailURL = (loan) => {
    if (loan.productToPay?.ecommerceCartType) {
      window.location.href = getEcommerceProduct(loan).productAttributes.merchantReturnFailURL;
    }
  };

  const saveMobileUserAgentInit = () => {
    if (isMobileOperatingSystem()) {
      const { userAgent } = window.navigator;
      saveMobileUserAgent(hash, userAgent);
    }
  };

  const setRejectionMessageData = (loan) => {
    const customerName = capitalizeEachWord(loan.customerName);
    let info;
    if (loan.productToPay?.ecommerceCartType) { // ECOMMERCECART
      info = i18n.RejectionData.ECOMMERCECART;
      setImage({ src: imgSadBnpl, alt: info.altImg, width: '200px' });
      setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
      setParagraph2(info.paragraph2);
      setButton({ label: info.buttonLabel, action: () => goToMerchantReturnFailURL(loan) });
    } else {
      switch (loan.response?.rejectedReasonCode) {
        case (REJECTION_CODES.TEMPORAL_BCRA):
          info = i18n.RejectionData.TEMPORAL_BCRA;
          setImage({ src: imgSad, alt: info.altImg, width: '300px' });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(parse(info.paragraph2));
          break;
        case (REJECTION_CODES.ULTIMA_CUOTA_IMPAGA):
          info = i18n.RejectionData.ULTIMA_CUOTA_IMPAGA;
          setImage({ src: imgSad, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          break;
        case (REJECTION_CODES.DESPUES_FECHA_CORTE_DEBITO):
          info = i18n.RejectionData.DESPUES_FECHA_CORTE_DEBITO;
          setImage({ src: imgPreCustomer, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(
            info.paragraph1, customerName, moneyFormatter(loan.response.maxAmount),
          )));
          break;
        case (REJECTION_CODES.CLIENTE_PRE):
          info = i18n.RejectionData.CLIENTE_PRE;
          setImage({ src: imgPreCustomer, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          break;
        case (REJECTION_CODES.NO_DISPONIBLE): case (REJECTION_CODES.PERFILOK_DEUDA):
          info = i18n.RejectionData.NO_DISPONIBLE;
          setImage({ src: imgMonth, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(info.paragraph2);
          break;
        case (REJECTION_CODES.MORA):
          info = i18n.RejectionData.MORA;
          setImage({ src: imgDelay, alt: info.altImg, width: '300px' });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(info.paragraph2);
          break;
        case (REJECTION_CODES.EDAD_NOPERMITIDA):
          info = i18n.RejectionData.EDAD_NOPERMITIDA;
          setImage({ src: imgYounger, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          break;
        case (REJECTION_CODES.SIN_CUENTAS):
          info = i18n.RejectionData.SIN_CUENTAS;
          setImage({ src: imgNotAccount, alt: info.altImg, width: '300px' });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(info.paragraph2);
          break;
        case (REJECTION_CODES.SIN_INGRESOS):
          info = i18n.RejectionData.SIN_INGRESOS;
          setImage({ src: imgNotIncome, alt: info.altImg, width: '300px' });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(info.paragraph2);
          break;
        case (REJECTION_CODES.SIN_EDAD):
          info = i18n.RejectionData.SIN_EDAD;
          setImage({ src: imgAgeless, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          break;
        case (REJECTION_CODES.PRESTAMOSACTIVOS):
          info = i18n.RejectionData.PRESTAMOSACTIVOS;
          setImage({ src: imgActiveLoanTrophy, alt: info.altImg, width: '300px' });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(info.paragraph2);
          break;
        case (REJECTION_CODES.RECHAZO_30DIAS):
          info = i18n.RejectionData.RECHAZO_30DIAS;
          setImage({ src: imgMonth, alt: info.altImg });
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setParagraph2(info.paragraph2);
          break;
        case (REJECTION_CODES.APIBANK_CAIDO):
          info = i18n.RejectionData.APIBANK_CAIDO;
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setImage({ src: imgOthers, alt: info.altImg, width: '300px' });
          break;
        case (REJECTION_CODES.DNI_CUIT_DUPLICADO):
          info = i18n.RejectionData.DNI_CUIT_DUPLICADO;
          setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
          setImage({ src: imgSad, alt: info.altImg });
          break;
        default: break;
      }
    }
    if (!info) {
      info = i18n.RejectionData.REJECTED; // DEFAULT
      setImage({ src: imgMonth, alt: info.altImg });
      setParagraph1(parse(fillMessageWith(info.paragraph1, customerName)));
      setParagraph2(info.paragraph2);
    }
  };

  const setValues = async (loan) => {
    await setRejectionMessageData(loan);
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const res = await getLoanRequest(hash);
        sendDataLayerCustomerInfo(res.data);
        await setValues(res.data);
      } catch (error) {
        console.log('error');
        console.log(error);
        setErrorMessage(getResponseErrorOrDefault(error));
      }
      saveMobileUserAgentInit();
    };
    init();
  }, []);

  return (
    <>
      <Box className="main-container-body">
        <Box mt={1} />
        <RejectionMessage image={image} paragraph1={paragraph1} paragraph2={paragraph2} button={button} />

        {/* ********** Toast con mensajes de error ********** */}
        {
          errorMessage && (
            <Toast
              messageOnError={errorMessage}
              open
              onClose={dismissMessage}
            />
          )
        }
      </Box>
      <Footer type={FooterType.DEFAULT} />
    </>
  );
};

export default OnboardingRejection;
