import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import '../assets/css/onboardingFinal.css';
import i18n from '../common/i18n';
import handLikeImg from '../assets/imgs/hand-like.svg';
import prepaidCardIconImg from '../assets/imgs/prepaid_card_icon.svg';
import WhatsappContact from '../components/WhatsappContact';
import { fillMessageWith } from '../utils/functionsUtil';

const OnboardingFinalAutomaticSuccess = ({
  loanRequest, rechargeLoan, servicePayLoan, productAttributes,
}) => (
  <>
    {/* ********** AUTOMATICO (SUCCESS) ********** */}
    <Grid container>
      <Grid item>
        <Typography className="onboarding-final-title">
          {i18n.OnboardingFinal.automaticTitleFinish}
        </Typography>
      </Grid>
    </Grid>
    {loanRequest.disbursementMode === 'PREPAID_CARD'
      ? (
        <>
          <Grid container style={{ marginTop: '40px', marginBottom: '40px' }}>
            <Grid item xs={3} sm={2}>
              <img src={prepaidCardIconImg} alt="card" />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Typography className="onboarding-final-subtitle-long">
                {fillMessageWith(i18n.OnboardingFinal.automaticSuccess.prepaidSubTitleOne,
                  loanRequest.productId === 46 ? 'crédito' : 'préstamo')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Grid item>
              <Typography className="onboarding-final-text">
                {i18n.OnboardingFinal.automaticSuccess.prepaidSubTitleTwo}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '60px' }}>
            <Grid item>
              <Typography className="onboarding-final-subtitle-pink">
                {i18n.OnboardingFinal.automaticSuccess.prepaidText}
              </Typography>
            </Grid>
          </Grid>
        </>
      )
      : (
        <>
          <Grid container style={{ marginTop: '40px', marginBottom: '40px' }}>
            <Grid item xs={3} sm={2}>
              <img src={handLikeImg} alt="like" />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Typography className="onboarding-final-subtitle">
                {i18n.OnboardingFinal.automaticSuccess.subTitleOne}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Grid item>
              <Typography className="onboarding-final-text">
                {fillMessageWith(i18n.OnboardingFinal.automaticSuccess.textOne,
                  loanRequest.productId === 46 ? 'crédito' : 'préstamo')}
                {!rechargeLoan && !servicePayLoan && (
                <>
                  {i18n.OnboardingFinal.transferToAccountMsg}
                </>
                )}
                {rechargeLoan && (
                <>
                  {i18n.OnboardingFinal.rechargeLoan}
                  { productAttributes && productAttributes.IDC && (
                  <>
                    (
                    <b>{productAttributes.IDC}</b>
                    )
                  </>
                  )}
                </>
                )}
                {servicePayLoan && (
                <>
                  {i18n.OnboardingFinal.servicePayLoan}
                  { productAttributes && productAttributes.companyName && (
                  <>
                    (
                    <b>{productAttributes.companyName}</b>
                    )
                  </>
                  )}
                </>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '60px' }}>
            <Grid item>
              <Typography className="onboarding-final-subtitle-pink">
                {i18n.OnboardingFinal.automaticSuccess.textTwo}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    <WhatsappContact />
  </>
);

OnboardingFinalAutomaticSuccess.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  rechargeLoan: PropTypes.bool.isRequired,
  servicePayLoan: PropTypes.bool.isRequired,
  productAttributes: PropTypes.shape().isRequired,
};

export default OnboardingFinalAutomaticSuccess;
