import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import {
  fillMessageWith, getServiceAmount, getServiceCompanyName, isBillLoan,
  isEcommerceCartLoan, isPrepaidCardLoan, isRechargeLoan,
  resolveRechargeCompanyLogo,
} from '../utils/functionsUtil';
import i18n from '../common/i18n';
import '../assets/css/summaryDetailCard.css';
import {
  moneyFormatter, moneyFormatterWithoutDecimals,
} from '../utils/formatterUtil';

const SummaryDetailCard = ({ loanRequest, summaryData }) => {
  const getExpirationDay = () => (loanRequest.productId === 19 ? 1 : 8);
  const getExpirationMonth = () => (new Date(summaryData.firstCalculatedInstallmentDueDate).getUTCMonth() + 1);
  const getExpirationYear = () => (new Date(summaryData.firstCalculatedInstallmentDueDate).getUTCFullYear());

  const hasFirstInstallmentDiscount = () => summaryData.firstInstallmentDiscount > 0;

  const getRechargeCompanyLogo = () =>
    resolveRechargeCompanyLogo(loanRequest.productToPay?.products[0].productAttributes.companyCode);

  const resolveTitle = () => {
    if (isRechargeLoan(loanRequest)) {
      return parse(fillMessageWith(
        i18n.SummaryDetailCard.titleRecharge,
        moneyFormatterWithoutDecimals(summaryData.requestedAmount),
        loanRequest.productToPay?.products[0].productAttributes.IDC,
      ));
    }
    if (isBillLoan(loanRequest)) {
      return parse(fillMessageWith(
        i18n.SummaryDetailCard.titleService,
        moneyFormatter(getServiceAmount(loanRequest)),
        getServiceCompanyName(loanRequest),
      ));
    }
    if (isPrepaidCardLoan(loanRequest)) {
      return parse(fillMessageWith(
        i18n.SummaryDetailCard.titleRevolvingLine,
        moneyFormatterWithoutDecimals(loanRequest.requestedAmount),
      ));
    }
    if (isEcommerceCartLoan(loanRequest)) {
      return parse(fillMessageWith(
        i18n.SummaryDetailCard.ecommerceCartTitle,
        loanRequest.productToPay?.products[0]?.productAttributes?.commerceName,
        moneyFormatter(loanRequest.requestedAmount),
      ));
    }
    return parse(fillMessageWith(
      i18n.SummaryDetailCard.title,
      moneyFormatterWithoutDecimals(loanRequest.requestedAmount - loanRequest.response.prePaidLoanAmount),
    ));
  };

  return (
    <>
      <Box className="summary-detail-box">
        {(isRechargeLoan(loanRequest)) && (
          <img
            src={getRechargeCompanyLogo()}
            alt="LOGO"
            style={{ maxWidth: '106px', marginBottom: '10px' }}
          />
        )}
        <Typography className="summary-detail-title">{resolveTitle()}</Typography>
        <hr style={{ border: '1px solid #E0E0E0' }} />
        <Grid container>
          <Grid item xs={6}>
            <Typography className="summary-detail-item">{i18n.SummaryDetailCard.term}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="summary-detail-value">
              {fillMessageWith(
                loanRequest.installmentsNumberSelected > 0
                  ? i18n.SummaryDetailCard.termValue[1]
                  : i18n.SummaryDetailCard.termValue[0],
                loanRequest.installmentsNumberSelected,
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="summary-detail-item">{i18n.SummaryDetailCard.installmentAmount}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="summary-detail-value">
              {moneyFormatterWithoutDecimals(summaryData.installmentAmountSelected)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="summary-detail-item">{i18n.SummaryDetailCard.expiration}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="summary-detail-value">
              {fillMessageWith(i18n.SummaryDetailCard.expirationDate,
                getExpirationDay(),
                getExpirationMonth(),
                getExpirationYear())}
            </Typography>
          </Grid>
        </Grid>
        {/* Descuento en primera cuota */}
        {hasFirstInstallmentDiscount() && (
          <Box mt={2}>
            <Typography className="summary-detail-benefit">
              {fillMessageWith(
                i18n.SummaryDetailCard.benefitInstallmentDiscount,
                moneyFormatter(summaryData.firstInstallmentAmount || 0),
              )}
            </Typography>
          </Box>
        )}
        {/* Aplazo cuota */}
        {loanRequest.commerceLineCategory === 'APLAZO_CUOTA' && (
          <Box mt={2}>
            <Typography className="summary-detail-benefit">{i18n.SummaryDetailCard.benefitInstallmentDefer}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

SummaryDetailCard.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
};

export default SummaryDetailCard;
