import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  fillMessageWith, isBillLoan, isBranchCashLoan, isEcommerceCartLoan, isRechargeLoan,
} from '../utils/functionsUtil';
import i18n from '../common/i18n';
import '../assets/css/summaryDisbursement.css';

const SummaryDisbursement = ({ loanRequest, summaryData, prepaidCardLastDigits }) => (
  <>
    {summaryData.disbursementMode
    && !isRechargeLoan(loanRequest)
    && !isBillLoan(loanRequest)
    && !isEcommerceCartLoan(loanRequest)
    && (
      <>
        <Box className="summary-disbursement-container">
          <img
            src="/assets/images/necesitar 1.svg"
            alt="logo"
            className="summary-disbursement-img"
          />
          {/* Desembolso sucursal efectivo tucuman */}
          {summaryData.disbursementMode === 'EFECTIVO' && isBranchCashLoan(loanRequest) && (
            <Typography className="summary-disbursement">
              {i18n.SummaryDisbursement.branchTucuman}
            </Typography>
          )}
          {/* DESEMBOLSO CBU */}
          {summaryData.disbursementMode === 'CBU' && summaryData.cbu.substring(0, 3) === '000' && (
            <>
              <Typography className="summary-disbursement">
                {parse(fillMessageWith(i18n.SummaryDisbursement.disbursementIn[1], summaryData.cbu.slice(-4)))}
              </Typography>
            </>
          )}
          {/* DESEMBOLSO CVU */}
          {summaryData.disbursementMode === 'CBU' && summaryData.cbu.substring(0, 3) !== '000' && (
            <>
              <Typography className="summary-disbursement">
                {parse(fillMessageWith(i18n.SummaryDisbursement.disbursementIn[0], summaryData.cbu.slice(-4)))}
              </Typography>
            </>
          )}
          {/* DESEMBOLSO Tarjeta prepaga credicuotas */}
          {summaryData.disbursementMode === 'PREPAID_CARD' && (
            <Typography className="summary-disbursement">
              {prepaidCardLastDigits
                ? parse(fillMessageWith(i18n.SummaryDisbursement.disbursementIn[2], prepaidCardLastDigits))
                : parse(i18n.SummaryDisbursement.disbursementIn[3])}
            </Typography>
          )}
        </Box>
      </>
    )}
  </>
);

SummaryDisbursement.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
  prepaidCardLastDigits: PropTypes.string,
};

SummaryDisbursement.defaultProps = {
  prepaidCardLastDigits: '',
};

export default SummaryDisbursement;
