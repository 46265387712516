import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

const DocumentLoadCountDown = ({ onFinish }) => {
  const [countDown, setCountDown] = useState(30);

  const init = () => {
    if (countDown <= 0) {
      onFinish();
    }
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        setCountDown(countDown - 1);
      }
    }, 1000);
    return () => {
      mounted = false;
    };
  };

  useEffect(() => init(), [countDown]);

  return (
    <>
      <Alert severity="info" sx={{ textAlign: 'left' }}>
        {fillMessageWith(i18n.DocumentLoadCountDown.message, countDown)}
      </Alert>
    </>
  );
};

DocumentLoadCountDown.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default DocumentLoadCountDown;
