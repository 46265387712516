import PropTypes from 'prop-types';
import { Button } from '@doit/pcnt-react-ui-library';
import { Grid, Typography } from '@mui/material';
import i18n from '../common/i18n';
import whatsappIconImg from '../assets/imgs/whatsapp-icon.svg';
import '../assets/css/whatsappContact.css';
import { WA_CC_NUMBER, getEnv } from '../api/env';

const WhatsappContact = ({ message }) => {
  const notifyComplete = () => {
    const number = getEnv(WA_CC_NUMBER);
    const encodedText = 'Hola, quiero coordinar mi validación de identidad';
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(encodedText)}`);
  };

  return (
    <>
      <Grid container justify="left" style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Typography className="whatsapp-contact-text">
          {message}
          <Button
            variant="tertiary"
            className="whatsapp-contact-button"
            onClick={() => { notifyComplete(); }}
          >
            {i18n.OnboardingFinal.linkCTA}
          </Button>
          {' '}
          <img src={whatsappIconImg} alt="whatsapp-icon" />
        </Typography>
      </Grid>
    </>
  );
};

WhatsappContact.propTypes = {
  message: PropTypes.string,
};
WhatsappContact.defaultProps = {
  message: 'Si necesitás comunicarte con nosotros escribinos ',
};

export default WhatsappContact;
