/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import QRCode from 'react-qr-code';
import i18n from '../common/i18n';
import '../assets/css/onboardingQRCode.css';

const OnboardingQRCode = ({ value, onClick }) => (
  <>
    <Typography className="onboarding-qr-code-title">{i18n.OnboardingQRCode.title}</Typography>
    <Box mt={3} />
    <QRCode size={190} value={value} />
    <Typography className="onboarding-qr-code-description">
      {i18n.OnboardingQRCode.description}
      <Button
        className="onboarding-qr-code-cta"
        variant="tertiary"
        onClick={onClick}
      >
        {i18n.OnboardingQRCode.CTA}
      </Button>
    </Typography>
  </>
);

OnboardingQRCode.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OnboardingQRCode;
