import { Box, Typography } from '@mui/material';
import i18n from '../common/i18n';
import '../assets/css/simpleFooter.css';

const SimpleFooter = () => (
  <Box className="simple-footer-box">
    <Typography className="simple-footer-description">{i18n.SimpleFooter.description}</Typography>
  </Box>
);

export default SimpleFooter;
