import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Grid, Typography, Dialog } from '@mui/material';
import {
  Button, Alert, SimpleSelect, Img, TextInput,
} from '@doit/pcnt-react-ui-library';
import cardIcon from '../assets/imgs/card-icon.png';
import likeIcon from '../assets/imgs/like-icon.png';
import cashIcon from '../assets/imgs/cash-icon.png';
import '../assets/css/onboardingCustomer.css';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

const CustomerDataInputPrepaidCard = ({
  provinceList, prepaidCardData, setPrepaidCardData, prepaidCardLastFour, turnOnValidations,
}) => {
  const info = i18n.OnboardingCustomerData.prepaidCard;
  const [showCardPopUp, setShowCardPopUp] = useState(false);

  const handleInputChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name !== 'provincia') {
      const regex = name === 'street' ? /^[A-Za-zÀ-ÿ0-9 -.:'°]+$/ : /^[A-Za-zÀ-ÿ0-9 -.]+$/;
      let valueToUse = '';
      for (let i = 0; i < value.length; i += 1) {
        if (regex.test(value[i])) {
          valueToUse += value[i];
        }
      }
      value = valueToUse;
    }
    setPrepaidCardData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {}, []);

  return (
    <>
      {prepaidCardLastFour ? (
        <>
          <Grid container justifyContent="center" spacing={3} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Alert severity="info">
                <Typography className="customer-data-alert" style={{ color: '#424242' }}>
                  {fillMessageWith(info.prepaidCardLastFourMessage, prepaidCardLastFour)}
                </Typography>
              </Alert>

            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{
              backgroundColor: '#F5F5F5', textAlign: 'left', marginTop: '20px', paddingBottom: '20px',
            }}
          >
            <Grid item xs={12}>
              <Typography style={{
                color: '#F2025D', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 700,
              }}
              >
                {info.cardTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: '16px', fontFamily: 'Open Sans' }}>
                {info.cardText}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Img src={cardIcon} alt="card-icon" style={{ marginRight: '10px' }} />
              <Typography>
                {parse(info.cardBenefitToBuy)}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Img src={likeIcon} alt="like-icon" style={{ marginRight: '10px' }} />
              <Typography>
                {parse(info.cardBenefitFree)}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Img src={cashIcon} alt="cash-icon" style={{ marginRight: '10px' }} />
              <Typography>
                {parse(info.cardBenefitCashout)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontStyle: 'italic' }}>
                {info.cardBenefitCashoutRestriction}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            style={{ textAlign: 'left' }}
            spacing={2}
          >
            <Grid item xs={12} style={{ marginTop: '30px', marginBottom: '30px' }}>
              <Typography style={{ color: '#F2025D', fontSize: '20px', textAlign: 'left' }}>
                {info.cardWhereToDelivery}
              </Typography>
              <Typography style={{
                fontFamily: 'Open Sans', textAlign: 'left', marginTop: '12px', marginBottom: '5px',
              }}
              >
                {info.cardDeliveryDescription}
              </Typography>
              <Button
                variant="tertiary"
                onClick={() => setShowCardPopUp(true)}
                sx={{
                  color: '#F2025D',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontFamily: 'Open sans',
                  padding: '0px',
                  display: 'contents',
                }}
              >
                {info.cardWhatIs}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <SimpleSelect
                name="province"
                label="Provincia"
                value={prepaidCardData.province || ''}
                onChange={(value) => handleInputChange({ target: { name: 'province', value } })}
                options={provinceList.map((combo) => (
                  { label: combo.descripcion, value: combo.id }))}
                error={turnOnValidations && (prepaidCardData.province === undefined
                  || prepaidCardData.province.toString().trim() === '')}
                required
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="district"
                label="Partido"
                placeholder="Partido"
                value={prepaidCardData.district}
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
                error={turnOnValidations && (prepaidCardData.district === undefined
                  || prepaidCardData.district.toString().trim() === '')}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="location"
                label="Localidad"
                placeholder="Localidad"
                value={prepaidCardData.location}
                onChange={handleInputChange}
                inputProps={{ maxLength: 80 }}
                error={turnOnValidations && (prepaidCardData.location === undefined
                  || prepaidCardData.location.toString().trim() === '')}
                required
                fullWidth
              />
              <Typography
                className="customer-data-custom-hint"
                style={{
                  fontSize: '10px',
                  color: (turnOnValidations
                  && (prepaidCardData.location === undefined || prepaidCardData.location.toString().trim() === '')
                    ? '#E20000' : '#00000099'),
                }}
              >
                {info.hintForLettersNumbersDashesDots}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="street"
                label="Calle"
                placeholder="Calle"
                value={prepaidCardData.street}
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
                error={turnOnValidations && (prepaidCardData.street === undefined
                  || prepaidCardData.street.toString().trim() === '')}
                required
                fullWidth
              />
              <Typography
                className="customer-data-custom-hint"
                style={{
                  fontSize: '10px',
                  color: (turnOnValidations
                  && (prepaidCardData.street === undefined || prepaidCardData.street.toString().trim() === '')
                    ? '#E20000' : '#00000099'),
                }}
              >
                {info.hintForLettersNumbersHypenCommaDotsApostropheOrdinals}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="number"
                label="Número"
                placeholder="Número"
                value={prepaidCardData.number}
                onChange={handleInputChange}
                inputProps={{ maxLength: 40 }}
                error={turnOnValidations && (prepaidCardData.number === undefined
                  || prepaidCardData.number.toString().trim() === '')}
                required
                fullWidth
              />
              <Typography
                className="customer-data-custom-hint"
                style={{
                  fontSize: '10px',
                  color: (turnOnValidations
                  && (prepaidCardData.number === undefined || prepaidCardData.number.toString().trim() === '')
                    ? '#E20000' : '#00000099'),
                }}
              >
                {info.hintForLettersNumbersDashesDots}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="floor"
                label="Piso"
                placeholder="Piso"
                value={prepaidCardData.floor}
                onChange={handleInputChange}
                inputProps={{ maxLength: 10 }}
                fullWidth
              />
              <Typography className="customer-data-custom-hint" style={{ fontSize: '10px' }}>
                {info.hintForLettersNumbersDashesDots}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="dpto"
                label="Departamento"
                placeholder="Departamento"
                value={prepaidCardData.dpto}
                onChange={handleInputChange}
                inputProps={{ maxLength: 40 }}
                fullWidth
              />
              <Typography className="customer-data-custom-hint" style={{ fontSize: '10px' }}>
                {info.hintForLettersNumbersDashesDots}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="zipCode"
                label="Código Postal"
                placeholder="Código Postal"
                value={prepaidCardData.zipCode}
                onChange={handleInputChange}
                inputProps={{ minLength: 4, maxLength: 10 }}
                error={turnOnValidations && (prepaidCardData.zipCode === undefined
                  || prepaidCardData.zipCode.toString().trim() === '')}
                required
                fullWidth
              />
              <Typography
                className="customer-data-custom-hint"
                style={{
                  fontSize: '10px',
                  color: (turnOnValidations
                  && (prepaidCardData.zipCode === undefined || prepaidCardData.zipCode.toString().trim() === '')
                    ? '#E20000' : '#00000099'),
                }}
              >
                {info.hintForLettersNumbersMinLenghtFour}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="additionalData"
                label="Dato adicional"
                placeholder="Dato adicional"
                value={prepaidCardData.additionalData}
                inputProps={{ maxLength: 30 }}
                onChange={handleInputChange}
                fullWidth
              />
              <Typography className="customer-data-custom-hint" style={{ fontSize: '10px' }}>
                {info.hintForAdditionalDataExample}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <Dialog
        open={showCardPopUp}
        onClose={() => setShowCardPopUp(false)}
        fullWidth
      >
        <Grid
          container
          justifyContent="center"
          spacing={2}
          style={{ padding: '20px' }}
        >
          <Grid item xs={12}>
            <Typography style={{ color: '#F2025D', fontSize: '16px' }}>
              {info.cardWhatIs}
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              {parse(info.cardWhatIsDescription)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: '#F2025D', fontSize: '16px' }}>
              {info.cardWhatIsHowToGetTitle}
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              {info.cardWhatIsHowToGetDescription}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Button
              variant="secondary"
              className="btn-primary"
              onClick={() => setShowCardPopUp(false)}
              style={{ marginTop: '20px' }}
              fullWidth
            >
              {info.cardWhatIsCTA}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

CustomerDataInputPrepaidCard.propTypes = {
  provinceList: PropTypes.shape().isRequired,
  prepaidCardData: PropTypes.shape().isRequired,
  setPrepaidCardData: PropTypes.func.isRequired,
  prepaidCardLastFour: PropTypes.string,
  turnOnValidations: PropTypes.bool.isRequired,
};

CustomerDataInputPrepaidCard.defaultProps = {
  prepaidCardLastFour: undefined,
};

export default CustomerDataInputPrepaidCard;
