import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { fillMessageWith, getCardType } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import '../assets/css/summaryPaymentMethod.css';

const SummaryPaymentMethod = ({ loanRequest, summaryData }) => {
  const getLastFourDigit = () => loanRequest.debitCardNumber.slice(-4);

  return (
    <Box className="summary-payment-method-container">
      <img
        src="/assets/images/factura 1.svg"
        alt="logo"
        className="summary-payment-method-img"
      />
      {/* Modo pago: TD */}
      {summaryData.collectionModeId === 3 && (
        <Typography className="summary-payment-method">
          {parse(fillMessageWith(
            i18n.SummaryPaymentMethod.TD,
            getCardType(loanRequest.debitCardNumber).toUpperCase(),
            getLastFourDigit(),
          ))}
        </Typography>
      )}
      {/* Modo pago: Voluntario */}
      {summaryData.collectionModeId === 2 && (
        <Typography className="summary-payment-method">
          {parse(fillMessageWith(i18n.SummaryPaymentMethod.voluntary))}
        </Typography>
      )}
    </Box>
  );
};

SummaryPaymentMethod.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
};

export default SummaryPaymentMethod;
