import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Alert } from '@doit/pcnt-react-ui-library';
import parse from 'html-react-parser';
import i18n from '../common/i18n';
import '../assets/css/onboardingFinal.css';
import whatsappRoseImg from '../assets/imgs/whatsapp-rose.svg';
import { fillMessageWith } from '../utils/functionsUtil';

const OnboardingFinalWelcomeAutomatic = ({ loanRequest }) => (
  <>
    {/* ********** BIENVENIDA AUTOMATICA ********** */}
    <Grid container>
      <Grid item>
        <Typography className="onboarding-final-title">
          {i18n.OnboardingFinal.welcomeAutomatic.title}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginTop: '30px', marginBottom: '30px' }}>
      <Grid item>
        <Typography className="onboarding-final-text">
          {parse(fillMessageWith(i18n.OnboardingFinal.welcomeAutomatic.messageOne,
            loanRequest.customerPhone))}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginBottom: '20px' }}>
      <Grid item xs={3} sm={2}>
        <img src={whatsappRoseImg} alt="whatsapp" width="55px" />
      </Grid>
      <Grid item xs={9} sm={10}>
        <Typography className="onboarding-final-subtitle-pink">
          {i18n.OnboardingFinal.phoneSubtitle}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ marginBottom: '50px' }}>
      <Grid item>
        <Alert severity="info" sx={{ textAlign: 'left' }} icon={false}>
          {i18n.OnboardingFinal.welcomeAutomatic.messageTwo}
        </Alert>
      </Grid>
    </Grid>

  </>
);

OnboardingFinalWelcomeAutomatic.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default OnboardingFinalWelcomeAutomatic;
