import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button, Img } from '@doit/pcnt-react-ui-library';

const RejectionMessage = ({
  image, paragraph1, paragraph2, button,
}) => (
  <>
    <Box mt={8} />
    <Grid
      container
      justify="center"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      {image && (
        <Grid item xs={12}>
          <Img
            src={image.src}
            alt={image.alt}
            style={{ width: '100%', maxWidth: image.width || '400px' }}
          />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginInline: '10px' }}>
        <Box mt={2} />
        <Typography variant="h3">
          {paragraph1}
        </Typography>
        <br />
        <Typography variant="h5">
          {paragraph2}
        </Typography>
      </Grid>
      {button && button.action && (
        <Grid display="flex" alignItems="center" item xs={12} sm={8} md={6} lg={4}>
          <Box mt={12} />
          <Button
            variant="primary"
            className="btn-primary"
            type="button"
            fullWidth
            onClick={button.action}
          >
            {button.label}
          </Button>
        </Grid>
      )}
    </Grid>
  </>
);

RejectionMessage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
  }),
  paragraph1: PropTypes.node,
  paragraph2: PropTypes.node,
  button: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  }),
};

RejectionMessage.defaultProps = {
  image: {
    src: '',
    alt: '',
    width: '400px',
  },
  paragraph1: '',
  paragraph2: '',
  button: {
    label: '',
    action: undefined,
  },
};

export default RejectionMessage;
