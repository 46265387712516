import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/summaryDetailCard.css';
import parse from 'html-react-parser';
import { Button, SimpleSelect, Alert } from '@doit/pcnt-react-ui-library';
import { Box, Typography, Grid } from '@mui/material';
import '../assets/css/onboardingCustomer.css';
import i18n from '../common/i18n';

const CustomerDataInputCombo = ({
  variable, handleChange, userFilled, comboData, inputHintAction, turnOnValidations,
}) => {
  const info = i18n.OnboardingCustomerData.inputCombo;
  const [defaultValue, setDefaultValue] = useState(undefined);

  const onChange = (value) => {
    let comboSelected;
    if (value !== null) {
      comboSelected = variable.userInputOptions.comboData.find((c) => c.id === value);
    }
    handleChange(variable, comboSelected);
  };

  const buildLabel = (field) => {
    const { descripcion, subDescripcion } = field;
    if (!subDescripcion) {
      return descripcion;
    }
    return (
      <>
        <Typography textAlign="left">
          {parse(`${descripcion}<br/>${subDescripcion}`)}
        </Typography>
      </>
    );
  };

  const init = () => {
    setDefaultValue(variable.userInputOptions.defaultValue);
  };

  useEffect(() => { init(); }, [userFilled[variable.name], comboData[variable.name]]);

  return (
    <>
      <Grid container spacing={3}>
        {variable.name === 'PtllaCBUDesembolsoYCobro' && defaultValue ? (
          <>
            <Grid item xs={12}>
              {comboData[variable.name].map((field) => (
                <>
                  {field.id === defaultValue && (
                  <>
                    <Typography class="customer-data-cbu-loaded">
                      {`${field.descripcion} ${field.subDescripcion || ''}`}
                    </Typography>
                  </>
                  )}
                </>
              ))}
              <Box class="customer-data-loaded-info">
                <Typography style={{ fontWeight: '800', fontSize: '14px' }}>
                  {info.disburseDefaultAccount}
                </Typography>
                <Typography style={{ fontSize: '12px' }}>
                  {`${info.disburseOtherAccount} `}
                  <Button
                    className="customer-data-show-cbu-btn"
                    variant="tertiary"
                    size="small"
                    onClick={() => setDefaultValue(undefined)}
                  >
                    {info.disburseOtherAccountCTA}
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            {comboData[variable.name] && comboData[variable.name].length > 0 && (
              <>
                <Grid item xs={12}>
                  <SimpleSelect
                    name={variable.name}
                    label={variable.userInputOptions.label}
                    value={userFilled[variable.name]?.id || ''}
                    error={variable.userInputOptions.required && turnOnValidations
                      && (userFilled[variable.name] === undefined
                        || (userFilled[variable.name].toString()).trim() === '')}
                    onChange={(value) => onChange(value)}
                    options={comboData[variable.name].map((combo) => (
                      { label: buildLabel(combo), value: combo.id }))}
                    required={variable.userInputOptions.required}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />

                  {(variable.userInputOptions.hint
                    || (variable.userInputOptions.hintAction && userFilled[variable.name]?.showHint)) && (
                    <>
                      <Typography className="customer-data-custom-hint">
                        <span style={{
                          color: (variable.userInputOptions.required && turnOnValidations
                          && (userFilled[variable.name] === undefined
                          || (userFilled[variable.name].toString()).trim() === '') ? '#E20000' : '#00000099'),
                        }}
                        >
                          {`${variable.userInputOptions.hint}.`}
                        </span>
                        {variable.userInputOptions.hintAction && (
                          <Button
                            variant="tertiary"
                            onClick={() => inputHintAction(variable)}
                            size="small"
                            className="customer-data-button-tertiary-ligth"
                          >
                            {variable.userInputOptions.hintAction}
                          </Button>
                        )}
                      </Typography>
                    </>
                  )}
                </Grid>
              </>
            )}
          </>
        )}
        {variable.name === 'PtllaFormasDePago' && userFilled[variable.name].id === '2' && (
          <>
            <Grid item xs={12}>
              <Alert severity="info" icon={false}>
                <Typography className="customer-data-alert" style={{ fontWeight: 700 }}>
                  {info.disbursePaymentMethods}
                </Typography>
              </Alert>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
CustomerDataInputCombo.propTypes = {
  variable: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  userFilled: PropTypes.shape().isRequired,
  comboData: PropTypes.shape().isRequired,
  inputHintAction: PropTypes.func.isRequired,
  turnOnValidations: PropTypes.bool.isRequired,
};

export default CustomerDataInputCombo;
