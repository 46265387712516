import { TextInput } from '@doit/pcnt-react-ui-library';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const TextInputWrapper = ({
  name, label, mandatory, helperText, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field }) => (
        <TextInput
          helperText={errors[name]?.message || helperText}
          error={!!errors[name]}
          label={mandatory ? `${label}*` : label}
          refs={field}
          {...props}
          {...field}
        />
      )}
    />
  );
};

TextInputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
};

TextInputWrapper.defaultProps = {
  mandatory: false,
  variant: 'standard',
  helperText: undefined,
};

export default TextInputWrapper;
