/* eslint "import/prefer-default-export": "off" */
export const REJECTION_CODES = {

  TEMPORAL_BCRA: 'TEMPORAL_BCRA',
  ULTIMA_CUOTA_IMPAGA: 'ULTIMA_CUOTA_IMPAGA',
  DESPUES_FECHA_CORTE_DEBITO: 'DESPUES_FECHA_CORTE_DEBITO',
  CLIENTE_PRE: 'CLIENTE_PRE',
  NO_DISPONIBLE: 'NO_DISPONIBLE',
  PERFILOK_DEUDA: 'PERFILOK_DEUDA',
  MORA: 'MORA',
  EDAD_NOPERMITIDA: 'EDAD_NOPERMITIDA',
  SIN_CUENTAS: 'SIN_CUENTAS',
  SIN_INGRESOS: 'SIN_INGRESOS',
  SIN_EDAD: 'SIN_EDAD',
  PRESTAMOSACTIVOS: 'PRESTAMOSACTIVOS',
  RECHAZO_30DIAS: 'RECHAZO_30DIAS',
  APIBANK_CAIDO: 'APIBANK_CAIDO',
  DNI_CUIT_DUPLICADO: 'DNI_CUIT_DUPLICADO',
};

export const ERROR_CODES = {

  ERROR_SENDING_SMS: 'ERROR_SENDING_SMS',
  ERROR_SENDING_SMS_BUT_EMAIL_SENT: 'ERROR_SENDING_SMS_BUT_EMAIL_SENT',
  ERROR_EMAIL_BELONG_TO_OTHER_CUSTOMER: 'ERROR_EMAIL_BELONG_TO_OTHER_CUSTOMER',
  ERROR_SENDING_EMAIL: 'ERROR_SENDING_EMAIL',
  SAPP_NO_PEN_CREDITS: 'SAPP_NO_PEN_CREDITS',
  INVALID_INSTALLMENT_PLAN: 'INVALID_INSTALLMENT_PLAN',
  SERVICE_UNAVAILABLE_FOR_MAINTENANCE: 'SERVICE_UNAVAILABLE_FOR_MAINTENANCE',
  ERROR_4XX_GET_PAYMENT_BILL_DETAIL_EP207: 'ERROR_4XX_GET_PAYMENT_BILL_DETAIL_EP207',
};
