import { Button } from '@doit/pcnt-react-ui-library';
import {
  Grid, Typography, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import '../assets/css/onboardingFinal.css';
import i18n from '../common/i18n';
import atrasImg from '../assets/imgs/atras.svg';
import ecommerceSuccessImg from '../assets/imgs/ecommerce-success.svg';
import { fillMessageWith } from '../utils/functionsUtil';
import OnboardingFinalEcommerceCountDown from '../components/OnboardingFinalEcommerceCountDown';

const OnboardingFinalEcommerce = ({ loanRequest, startCountDown }) => {
  const goToMerchantReturnSuccessURL = async () => {
    window.location.href = loanRequest.productToPay.products[0].productAttributes.merchantReturnSuccessURL;
  };

  return (
    <>
      {/* ********** Ecommerce ********** */}
      <Grid container style={{ marginTop: '20px', marginBottom: '20px' }} justifyContent="left">
        <Grid item>
          <Button
            variant="tertiary"
            onClick={() => { goToMerchantReturnSuccessURL(); }}
          >
            <ListItem style={{ padding: '0px' }}>
              <ListItemIcon style={{ minWidth: '20px' }}>
                <img src={atrasImg} alt="back" width="15px" />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography className="onboarding-final-ecommerce-arrow">
                    {i18n.OnboardingFinal.ecommerce.backToSite}
                  </Typography>
                )}
              />
            </ListItem>
          </Button>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '20px', marginBottom: '20px' }} justifyContent="center">
        <Grid item>
          <Typography className="onboarding-final-ecommerce-title">
            {fillMessageWith(i18n.OnboardingFinal.ecommerce.title,
              loanRequest.productToPay.products[0].productAttributes.commerceName)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '20px', marginBottom: '20px' }} justifyContent="center">
        <Grid item>
          <img src={ecommerceSuccessImg} alt="Tienda virtual" width="100px" />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '20px', marginBottom: '20px' }} justifyContent="center">
        <Grid item>
          <Typography className="onboarding-final-ecommerce-text">
            {parse(fillMessageWith(i18n.OnboardingFinal.ecommerce.subTitle, loanRequest.customerName.toLowerCase()))}
          </Typography>
        </Grid>
      </Grid>

      {startCountDown && (
      <>
        <Grid container style={{ marginTop: '40px', marginBottom: '20px' }} justifyContent="center">
          <Grid item xs={8} className="onboarding-final-ecommerce-countdown">
            <OnboardingFinalEcommerceCountDown onFinish={goToMerchantReturnSuccessURL} />
          </Grid>
        </Grid>
      </>
      )}
    </>
  );
};

OnboardingFinalEcommerce.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  startCountDown: PropTypes.bool.isRequired,
};

export default OnboardingFinalEcommerce;
