import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { Button, Img, Slider } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import '../assets/css/rechargeStep.css';
import TextInputWrapper from './commons/TextInputWrapper';
import { cellphoneSchemaValidation } from '../forms/schemaValidations';
import { addProductToPayRecharge, getRechargeCompaniesDetail } from '../api/onboardingService';
import { fillMessageWith, getResponseErrorOrDefault } from '../utils/functionsUtil';
import Toast from './commons/Toast';
import getCompanyImageByCode from '../utils/rechargeUtil';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { loanRedirect } from './LoanRequestRedirectService';

const RechargeStep = ({ loanRequest, offer }) => {
  const { hash } = useParams();
  const info = i18n.PRODUCT_LOAD.RECHARGE_STEP;
  const [companyList, setCompanyList] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(cellphoneSchemaValidation),
    mode: 'onSubmit',
  });

  const onSubmit = async ({ cellphone }) => {
    try {
      setLoading(true);
      const data = {
        companyCode: selectedCompany.code,
        companyName: selectedCompany.name,
        paymentModeId: selectedCompany.paymentModeId,
        form: {
          IDC: cellphone,
          IV1: selectedAmount,
        },
      };
      await addProductToPayRecharge(loanRequest.hashKey, data);
      loanRedirect(loanRequest, navigate, queryParams.toString());
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    try {
      const res = await getRechargeCompaniesDetail(hash);
      setCompanyList(res.data);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const selectCompany = (company) => {
    let maxAmountAux;
    const maxAmountCompany = Math.floor(company.maxAmmount / 100) * 100;
    if (offer.maxAmount < maxAmountCompany) {
      maxAmountAux = offer.maxAmount;
    } else {
      maxAmountAux = maxAmountCompany;
    }
    setMaxAmount(maxAmountAux);

    let minAmountAux;
    const minAmountCompany = Math.ceil(company.minAmmount / 100) * 100;
    if (offer.installments[0].minimumAmount > minAmountCompany) {
      minAmountAux = offer.installments[0].minimumAmount;
    } else {
      minAmountAux = minAmountCompany;
    }
    setMinAmount(minAmountAux);

    // Esto para evitar que el monto sea 350 por ejemplo siendo que el slider muestra redondo de 100 en 100
    let defaultAmount = ((maxAmountAux - minAmountAux) / 2) + minAmountAux;
    if (defaultAmount % 100 !== 0) {
      defaultAmount += (100 - (defaultAmount % 100));
    }
    setSelectedAmount(defaultAmount);
    setSelectedCompany(company);
  };

  const resolveCompanyClass = (company) => {
    let style = 'recharge-company-card';
    if (company.code === selectedCompany?.code) {
      style = 'recharge-company-card-selected';
    }
    return style;
  };

  const handleChange = (event, value) => {
    setSelectedAmount(value);
  };

  const resolveMarks = () => {
    const marks = [
      { value: minAmount, label: `$ ${minAmount}` },
      { value: maxAmount, label: `$ ${maxAmount}` },
    ];
    return marks;
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Grid container className="pre-onboarding-main-container">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Typography className="recharge-step-title" component="h1">
                {info.title}
              </Typography>
              <Box mt={3} />
              <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                  <TextInputWrapper
                    name="cellphone"
                    label={info.inputLabel}
                    helperText={info.inputHint}
                    inputProps={{ maxLength: 12 }}
                    mandatory
                  />
                </Grid>
              </Grid>
              <Box mt={5} />
              <Typography className="recharge-step-subtitle">
                {info.selectCompany}
              </Typography>
              <Box mt={3} />
              {companyList && (
                <Grid container>
                  {companyList.map((company) => (
                    <Grid item key={company.code}>
                      <Box className={resolveCompanyClass(company)} onClick={() => selectCompany(company)}>
                        <Img src={getCompanyImageByCode(company.code)} alt="LOGO" />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
              {selectedCompany && (
                <>
                  <Box mt={5} />
                  <Typography className="recharge-step-subtitle">
                    {info.selectAmount}
                  </Typography>
                  <Box mt={2} />
                  <Typography className="recharge-recharge-amount">
                    {parse(fillMessageWith(info.amount,
                      moneyFormatterWithoutDecimals(selectedAmount)))}
                  </Typography>
                  <Box mt={3} />
                  <Grid container>
                    <Grid item xs={12} sm={12} md={5}>
                      <Slider
                        min={minAmount}
                        max={maxAmount}
                        step={100}
                        value={selectedAmount}
                        onChange={handleChange}
                        marks={resolveMarks()}
                      />
                      <Box mt={5} />
                      <Button
                        variant="primary"
                        className="btn-primary"
                        type="submit"
                        fullWidth
                        loading={loading}
                      >
                        {info.buttonLabel}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      {/* ********** Toast con mensajes de error ********** */}
      {
        errorMessage && (
          <Toast
            messageOnError={errorMessage}
            open
            onClose={() => setErrorMessage(undefined)}
          />
        )
      }
    </>
  );
};

RechargeStep.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  offer: PropTypes.shape().isRequired,
};

export default RechargeStep;
