export const routes = {
  preOnboardingPersonalLoan: '/personal',
  preOnboardingServicesLoan: '/servicios',
  preOnboardingRechargeLoan: '/recargas',
  preOnboardingUberLoan: '/uber',
  preOnboardingOnePage: '/personal-full',
  preOnboardingAdwords: '/personal-adwords',
  preOnboardingRevolvingLine: '/linea-de-credito',
  OnboardingRejection: '/rechazo',
  OnboardingErrorCode: '/codigo-error',
  OnboardingInstallmentPlan: '/plan-cuotas',
  OnboardingProductLoad: '/cargar-producto',
  OnboardingDocumentsLoad: '/cargar-documentos',
  OnboardingCustomerData: '/cargar-datos',
  OnboardingIncompatibleBrowser: 'navegador-incompatible',
  OnboardingProccessBiometric: '/biometria',
  OnboardingSummary: '/resumen',
  OnboardingFinal: '/final',
  OnboardingRedirect: '/m',
  OnboardingDownloadDisclaimer: '/d',
};

export default routes;
