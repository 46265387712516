import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import { isMobileOperatingSystem } from '../utils/functionsUtil';
import i18n from '../common/i18n';

const NavigationActions = ({
  onContinue, onBack, loading, disabled,
}) => (
  <Grid
    mb={2}
    container
    spacing={2}
    direction={isMobileOperatingSystem() ? 'column-reverse' : 'row'}
    justifyContent="flex-end"
  >
    {onBack && (
      <>
        <Grid item md={1} />
        <Grid item xs={12} md={5}>
          <Button
            variant={isMobileOperatingSystem() ? 'tertiary' : 'secondary'}
            className="btn-primary"
            type="button"
            onClick={onBack}
            fullWidth
          >
            {i18n.goBackCTA}
          </Button>
        </Grid>
      </>
    )}
    <Grid item xs={12} md={onBack ? 5 : 12}>
      <Button
        variant="primary"
        className="btn-primary"
        type="button"
        disabled={disabled}
        onClick={onContinue}
        loading={loading}
        fullWidth
      >
        {i18n.IdentityResolverStep.btnContinue}
      </Button>
    </Grid>
  </Grid>
);

NavigationActions.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

NavigationActions.defaultProps = {
  onBack: undefined,
};

export default NavigationActions;
