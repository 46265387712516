import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '24px',
      fontWeight: 700,
      color: '#212121',
      lineHeight: '32px',
    },
    h2: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    list: {
      fontFamily: 'Open Sans,sans-serif',
    },
    p: {
      fontFamily: 'Open Sans,sans-serif',
    },
    h3: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Open Sans,sans-serif',
      fontWeight: 700,
      textTransform: 'none',
    },
    fontFamily: 'Open Sans,sans-serif',
  },
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      main: '#e72064',
    },
    secondary: {
      main: '#FBB615',
    },
    success: {
      main: '#248E00',
    },
    error: {
      main: '#E20000',
    },
    partial: {
      main: '#FF7C00',
    },
    successlight: {
      main: '#CCEFBB',
    },
    topay: {
      main: '#2165DF',
    },
    whatsapp: {
      main: '#00BB2D',
    },
    lightpink: {
      main: '#FFDCE9',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '25px',
        textTransform: 'none',
        fontFamily: 'Poppins,sans-serif',
        color: '#e72064',
      },
      contained: {
        borderRadius: '25px',
        backgroundColor: '#e72064',
        color: 'white',
        textTransform: 'none',
        fontWeight: '700',
        fontFamily: 'Poppins,sans-serif',
      },
      outlined: {
        borderRadius: '25px',
        backgroundColor: 'white',
        color: '#e72064',
        textTransform: 'none',
        fontWeight: '00',
        fontFamily: 'Open sans',
        width: '100%',
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'normal',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: 'black',
          backgroundColor: 'light gray',
          cursor: 'default',
        },
        '&$selected:hover': {
          color: 'black',
          backgroundColor: '#d6d6d6',
          cursor: 'default',
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'normal',
      },
    },
    MuiTableCell: {
      root: {
        '&:lastChild': { paddingRight: '0px' },
      },
      sizeSmall: {
        padding: '0px',
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    MuiAccordion: {
      root: {
        border: '1px',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#e72064',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: '#e72064',
        },
      },
    },
    // estilos personalizados de input
    MuiInputLabel: {
      outlined: {
        color: '#444444',
        '&$disabled': {
          color: '#6e6e6e',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#444444',
      },
    },
    // texto ingresado
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: '#444444',
        },
      },
    },
    // fin estilos personalizados de input
  },
  button: {
    card: {
      fontFamily: 'Poppins,sans-serif',
    },
  },
  body: {
    backgroundColor: 'white',
  },
});

export default Theme;
