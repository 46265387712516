import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useIncompatibleBrowser from '../hooks/useIncompatibleBrowser';
import Loading from '../components/commons/Loading';
import OnboardingHeader from '../components/OnboardingHeader';
import i18n from '../common/i18n';
import { fillMessageWith, maskPhoneNumber } from '../utils/functionsUtil';

const OnboardingIncompatibleBrowser = () => {
  const { loading, loanRequest, doCopy } = useIncompatibleBrowser();

  return (
    <>
      <Box className="main-container-body" mb={5}>
        <Grid container className="pre-onboarding-main-container" display="flex" justifyContent="center">
          <Grid item xs={11}>
            {loading ? (
              <Loading />
            ) : (
              <>
                {loanRequest && (
                  <>
                    <OnboardingHeader loan={loanRequest} />
                    <Typography variant="h1" sx={{ marginTop: '48px' }}>
                      {fillMessageWith(i18n.OnboardingIncompatibleBrowser.title, maskPhoneNumber(loanRequest.customerPhone))}
                    </Typography>
                    <Typography variant="h5" sx={{ marginTop: '48px' }}>
                      {i18n.OnboardingIncompatibleBrowser.description}
                    </Typography>
                    <Button
                      variant="tertiary"
                      className="btn-primary"
                      sx={{ marginTop: '24px' }}
                      endIcon={<ContentCopyIcon />}
                      onClick={doCopy}
                    >
                      {i18n.OnboardingIncompatibleBrowser.cta}
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OnboardingIncompatibleBrowser;
