import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import Footer, { FooterType } from '../components/commons/Footer';
import Toast from '../components/commons/Toast';

import {
  getLoanRequest, getOnboardingOffer, reminder, saveMobileUserAgent,
} from '../api/onboardingService';
import PayServices from '../components/PayServices';
import RechargeStep from '../components/RechargeStep';
import { deleteWebChat } from '../components/webchatComponentScript';
import { getResponseErrorOrDefault, sendDataLayerCustomerInfo } from '../utils/functionsUtil';

const OnboardingProductLoad = () => {
  const { hash } = useParams();
  const [loanData, setLoanData] = useState();
  const [offerData, setOfferData] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => {
    const init = async () => {
      try {
        saveMobileUserAgent(hash, window.navigator.userAgent);
        await reminder(hash);
      } catch (error) {
        console.log(error);
      }
      try {
        const loan = await getLoanRequest(hash);
        sendDataLayerCustomerInfo(loan.data);
        const offer = await getOnboardingOffer(hash);
        await setLoanData(loan.data);
        await setOfferData(offer.data);
        deleteWebChat();
      } catch (error) {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    };
    init();
  }, []);

  return (
    <>
      <Box className="main-container-body">
        <Box mt={1} />
        {/* ********** PAGO DE SERVICIOS ********** */}
        {loanData?.productToPay?.billPaymentType && offerData && (
          <PayServices loan={loanData} offer={offerData} />
        )}

        {/* ********** RECARGA CELULAR ********** */}
        {loanData?.productToPay?.rechargeType && offerData && (
          <RechargeStep loanRequest={loanData} offer={offerData} />
        )}

        {/* ********** Toast con mensajes de error ********** */}
        {
          errorMessage && (
            <Toast
              messageOnError={errorMessage}
              open
              onClose={dismissMessage}
            />
          )
        }
      </Box>
      <Footer type={FooterType.DEFAULT} />
    </>
  );
};

export default OnboardingProductLoad;
