import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useState } from 'react';
import {
  Button, Radio, RadioGroup, TextInput,
} from '@doit/pcnt-react-ui-library';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, Typography } from '@mui/material';
import '../assets/css/nextSteps.css';
import i18n from '../common/i18n';
import npsSuccessImg from '../assets/imgs/nps-img-success.svg';
import npsImg from '../assets/imgs/nps-img.svg';
import { skipSurvey, saveSurveyAnswer } from '../api/onboardingService';

const NpsForm = ({ surveyInfo, onFinish }) => {
  const { hash } = useParams();
  const [npsCompleted, setNpsCompleted] = useState(false);
  const [scoreValue, setScoreValue] = useState();
  const [freeTextValue, setFreeTextValue] = useState();

  const handleChangeScoreValue = (event) => {
    setScoreValue(event.target.value);
  };

  const handleChangeFreeText = (event) => {
    setFreeTextValue(event.target.value);
  };

  const npsAnswered = async () => {
    await onFinish();
  };

  const skipNps = async () => {
    const npsResult = {
      npsRunId: surveyInfo.npsRunId,
      userId: hash,
    };
    onFinish();
    skipSurvey(hash, npsResult);
  };

  const saveAnswer = async () => {
    const userResults = [{
      npsRunId: surveyInfo.npsRunId,
      userId: hash,
      questionResults: [],
    }];
    surveyInfo.questions.forEach((value) => {
      console.log(value);
      if (value.type === 'SINGLE_SELECTION_SCORE') {
        userResults[0].questionResults.push({ code: value.code, answerResults: [{ code: scoreValue }] });
      } else if (value.type === 'FREE_TEXT') {
        userResults[0].questionResults.push({ code: value.code, answerResults: [{ freeText: freeTextValue }] });
      }
    });
    const npsResult = {
      npsRunId: surveyInfo.npsRunId,
      userResults,
    };
    await saveSurveyAnswer(hash, npsResult);
    setNpsCompleted(true);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        style={{
          paddingTop: '40px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px',
        }}
      >

        {npsCompleted && (
          <>
            <Grid container textAlign="center">
              <Grid item xs={12}>
                <img src={npsSuccessImg} alt="Imagen decorativa" width="70px" />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Typography className="onboarding-final-title" style={{ textAlign: 'center' }}>
                  <strong>{i18n.OnboardingFinal.npsForm.completedTitle}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Typography className="onboarding-final-text" style={{ textAlign: 'center' }}>
                  {i18n.OnboardingFinal.npsForm.completedSubTitle}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '60px' }}>
                <Button
                  variant="primary"
                  className="btn-primary"
                  fullWidth
                  onClick={() => { npsAnswered(); }}
                >
                  {i18n.OnboardingFinal.npsForm.buttonAnswered}
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {!npsCompleted && surveyInfo && (
          <>
            <Grid item xs={12}>
              <img src={npsImg} width="70px" alt="Imagen decorativa" />
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Typography className="onboarding-final-title">
                <strong>{i18n.OnboardingFinal.npsForm.title}</strong>
              </Typography>
            </Grid>

            {surveyInfo.questions.map((question) => (
              <>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                  <Typography>
                    {i18n.NPS[question.label.slice('NPS.'.length)]}
                  </Typography>
                </Grid>

                {question.type === 'SINGLE_SELECTION_SCORE' && (
                  <>
                    <RadioGroup
                      aria-labelledby="Regular-group"
                      onChange={handleChangeScoreValue}
                      row
                    >
                      <Grid item xs={12}>
                        {question.answers.map((answer) => (
                          <>
                            <FormControlLabel
                              value={answer.code}
                              label={<Typography className="onboarding-final-nps-score">{answer.label}</Typography>}
                              control={(
                                <Radio
                                  size="small"
                                />
                                )}
                              labelPlacement="bottom"
                              style={{
                                marginLeft: '0px',
                                marginRight: '0px',
                                width: '10%',
                                alignItems: 'stretch',
                              }}
                            />
                          </>
                        ))}
                      </Grid>
                    </RadioGroup>
                    <Grid container style={{ padding: '0px', marginTop: '10px' }}>
                      <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <Typography style={{ color: '#afafaf' }}>{i18n.OnboardingFinal.npsForm.noLikely}</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography style={{ color: '#afafaf' }}>{i18n.OnboardingFinal.npsForm.veryLikely}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {question.type === 'FREE_TEXT' && (
                  <>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <TextInput
                        placeholder={i18n.NPS[question.answers[0].label.slice('NPS.'.length)]}
                        onChange={handleChangeFreeText}
                        value={freeTextValue}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </>
            ))}

            <Grid item xs={12} style={{ marginTop: '20px', marginBottom: '10px' }}>
              <Button
                variant="primary"
                className="btn-primary"
                fullWidth
                onClick={() => { saveAnswer(); }}
                disabled={!scoreValue && !freeTextValue}
              >
                {i18n.OnboardingFinal.npsForm.buttonSend}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Button
                variant="tertiary"
                className="btn-primary"
                fullWidth
                onClick={() => { skipNps(); }}
              >
                {i18n.OnboardingFinal.npsForm.buttonOmit}
              </Button>
            </Grid>

          </>
        )}

      </Grid>
    </>
  );
};

NpsForm.propTypes = {
  surveyInfo: PropTypes.shape().isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default NpsForm;
