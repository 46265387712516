import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer, { FooterType } from '../components/commons/Footer';
import { loanRedirectMinHash } from '../components/LoanRequestRedirectService';

const OnboardingRedirect = () => {
  const { hash } = useParams();
  const navigate = useNavigate();

  useEffect(() => { loanRedirectMinHash(hash, navigate); }, []);

  return (
    <>
      <Box className="main-container-body">
        <Box mt={1} />
      </Box>
      <Footer type={FooterType.DEFAULT} />
    </>
  );
};

export default OnboardingRedirect;
