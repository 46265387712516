import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import '../assets/css/onboardingFinal.css';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

const OnboardingFinalEcommerceCountDown = ({ onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(6);

  const init = () => {
    if (timeLeft <= 0) {
      onFinish();
    }
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    return () => {
      mounted = false;
    };
  };

  useEffect(() => init(), [timeLeft]);

  return (
    <>
      {timeLeft > 0 && (
      <>
        {fillMessageWith(i18n.OnboardingFinal.ecommerce.countDown, timeLeft)}
      </>
      )}
    </>
  );
};

OnboardingFinalEcommerceCountDown.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default OnboardingFinalEcommerceCountDown;
