import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Img } from '@doit/pcnt-react-ui-library';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { SERVICES_COMPANIES_LOGOS_S3, getEnv } from '../api/env';
import { isMobileOperatingSystem } from '../utils/functionsUtil';
import { getServiceCompaniesLogos } from '../api/onboardingService';

const CompanySlider = ({ onClickCompany }) => {
  const s3Url = getEnv(SERVICES_COMPANIES_LOGOS_S3);
  const [companyList, setCompanyList] = useState([]);
  const [companyGroupsList, setCompanyGroupsList] = useState([]);
  const SLIDER_GROUP_LENGHT_LIMIT = 3;

  const init = async () => {
    const res = await getServiceCompaniesLogos();
    if (res?.data) {
      const tempCompanyList = [];
      const tempCompanyGroupsList = [];
      if (res.data && res.data.length > 0) {
        let tempGroupList = [];
        res.data.forEach((companyID) => {
          if (tempGroupList.length === SLIDER_GROUP_LENGHT_LIMIT) {
            tempCompanyGroupsList.push(tempGroupList);
            tempGroupList = [];
          }
          const company = {
            searchString: companyID.split('.')[0].split('-')[0],
            code: companyID.split('.')[0].split('-')[1],
            fileName: companyID,
          };
          if (company.searchString && company.code && company.fileName) {
            tempGroupList.push(company);
            tempCompanyList.push(company);
          }
        });
      }
      setCompanyList(tempCompanyList);
      setCompanyGroupsList(tempCompanyGroupsList);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {isMobileOperatingSystem() ? (
        <>
          {companyGroupsList && companyGroupsList.length > 0 && (
            <Carousel
              swipeable
              showStatus={false}
            >
              {companyGroupsList.map((companyGroup) => (
                <Grid container className="services-logo-container">
                  {companyGroup.map((company) => (
                    <Grid
                      item
                      xs={12 / SLIDER_GROUP_LENGHT_LIMIT}
                      onClick={() => onClickCompany(company)}
                    >
                      <Img className="services-logo-company-img" src={s3Url + company.fileName} alt={company.fileName} />
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Carousel>
          )}
        </>
      ) : (
        <>
          {companyList && companyList.length > 0 && (
            <Grid container className="services-logo-container" justify="space-around">
              {companyList.slice(0, 6).map((company) => (
                <Grid
                  item
                  onClick={() => onClickCompany(company)}
                >
                  <Img className="services-logo-company-img" src={s3Url + company.fileName} alt={company.fileName} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

CompanySlider.propTypes = {
  onClickCompany: PropTypes.func.isRequired,
};

export default CompanySlider;
