import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import i18n from '../common/i18n';
import {
  checkBlacklist, resolveCustomers, savePreSimulationLoan,
  sendVerificationCode, startWorkflow, updateCustomerData,
  verifyCode, verifyEmail,
  verifyEmailDomain,
} from '../api/onboardingService';
import banner from '../assets/imgs/banner-recharge.png';
import Footer, { FooterType } from '../components/commons/Footer';
import { getResponseErrorOrDefault, fillMessageWith, isMobileOperatingSystem } from '../utils/functionsUtil';
import DNIStep from '../components/DNIStep';
import EmailStep from '../components/EmailStep';
import IdentityResolverStep from '../components/IdentityResolverStep';
import CellphoneStep from '../components/CellphoneStep';
import VerificationCodeStep from '../components/VerificationCodeStep';
import Toast from '../components/commons/Toast';
import CalculatorLoading from '../components/commons/CalculatorLoading';
import { saveUrlSourceParameters, dniIncludesLastDigit } from '../utils/commonServices';
import { loanRedirect } from '../components/LoanRequestRedirectService';
import { ERROR_CODES } from '../utils/errorCodeConstant';
import { routes } from '../constants/routes';
import PreOnboardinLoadData from '../components/PreOnboardingLoadData';

const PreOnboardingRechargeLoan = () => {
  const steps = {
    dni: 'dni',
    resolveCustomer: 'resolveCustomer',
    email: 'email',
    cellphone: 'cellphone',
    verificationCode: 'verificationCode',
  };

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.dni);
  const [customerList, setCustomerList] = useState();
  const [customerSelected, setCustomerSelected] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerCellphone, setCustomerCellphone] = useState();
  const [resendMessage, setResendMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [calculatingOffer, setCalculatingOffer] = useState(false);
  const [verificationCodeMethodEmail, setVerificationCodeMethodEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleSubmitDNI = async (data) => {
    try {
      setLoading(true);
      const response = await resolveCustomers(data.dni);
      setCustomerList(response.data);
      setCurrentStep(steps.resolveCustomer);
      window.scrollTo(0, 0);
      setVerificationCodeMethodEmail(dniIncludesLastDigit(data.dni));
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitIdentityResolverStep = (customer) => {
    setLoading(true);
    setCustomerSelected(customer);
    setLoading(false);
    setCurrentStep(steps.email);
    window.scrollTo(0, 0);
  };

  const handleSubmitEmailStep = async ({ email }) => {
    try {
      setLoading(true);
      await verifyEmailDomain(email);
      await updateCustomerData(customerSelected, email, 69);
      setCustomerEmail(email);
      setCurrentStep(steps.cellphone);
      window.scrollTo(0, 0);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCustomer = () => {
    setCustomerSelected(undefined);
    setCustomerList(undefined);
    setCustomerEmail(undefined);
    setCustomerCellphone(undefined);
    setCurrentStep(steps.dni);
    window.scrollTo(0, 0);
  };

  const handleChangeEmail = () => {
    setCustomerEmail(undefined);
    setCurrentStep(steps.email);
    window.scrollTo(0, 0);
  };

  const sendCode = async (cellphoneNumber, resendCode) => {
    try {
      const verificacionMethod = verificationCodeMethodEmail ? customerEmail : cellphoneNumber;
      await sendVerificationCode(verificacionMethod, cellphoneNumber, customerEmail, resendCode);

      if (!resendCode) {
        await savePreSimulationLoan(cellphoneNumber, customerEmail, customerSelected, 'recargas');
      }
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_EMAIL_BELONG_TO_OTHER_CUSTOMER) {
        handleChangeEmail();
        throw error;
      } else if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_SMS_BUT_EMAIL_SENT) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageJustMail} ${customerEmail}`);
      } else if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_EMAIL) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageErrorMail}`);
        setInvalidEmail(true);
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const handleSubmitCellphone = async ({ cellphone, resend }) => {
    try {
      setLoading(true);

      const cellphoneNumber = Number(cellphone);

      await checkBlacklist(cellphoneNumber);
      await sendCode(cellphoneNumber, resend);

      setCustomerCellphone(cellphoneNumber);
      setCurrentStep(steps.verificationCode);
      window.scrollTo(0, 0);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCellphone = () => {
    setCustomerCellphone(undefined);
    setCurrentStep(steps.cellphone);
    window.scrollTo(0, 0);
  };

  const resendVerificationCode = () => {
    handleSubmitCellphone({ cellphone: customerCellphone, resend: true });
    setResendMessage(
      fillMessageWith(i18n.VerificationCodeStep.resendMessage, (verificationCodeMethodEmail
        ? i18n.VerificationCodeStep.methodEmailLabel
        : i18n.VerificationCodeStep.methodSmsLabel)),
    );
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  const startWorkflowProcess = async () => {
    try {
      setCalculatingOffer(true);
      window.scrollTo(0, 0);
      const res = await startWorkflow();
      if (!verificationCodeMethodEmail) {
        await verifyEmail(res.data.hashKey);
      }
      saveUrlSourceParameters(res.data.hashKey);
      loanRedirect(res.data, navigate, queryParams.toString());
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE) {
        navigate({
          pathname: `${routes.OnboardingErrorCode}/${ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE}`,
          search: `?${queryParams.toString()}`,
        });
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const initWorkflow = async () => {
    try {
      startWorkflowProcess();
    } catch (error) {
      setErrorMessage(i18n.unexpectedErrorMessage);
    }
  };

  const handleSubmitVerificationCode = async ({ code }) => {
    try {
      setLoading(true);
      const data = {
        userInputName: 'WT_PHONE',
        userInputVerificationCode: code,
        userInputValue: verificationCodeMethodEmail
          ? customerEmail
          : customerCellphone,
      };
      const res = await verifyCode(data);
      if (res.data.result) {
        initWorkflow();
      } else {
        setErrorMessage(i18n.VerificationCodeStep.errors.invalidCode);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!calculatingOffer && (
        <Grid container className="preonboarding">
          <Grid item md={6} xs={12} className="preonboarding-flow">
            {/* ********** Muestra los datos que se fueron completando ********** */}
            <PreOnboardinLoadData
              customer={customerSelected}
              phone={customerCellphone}
              email={customerEmail}
            />
            {/* ********** Titulo y subtitulo recargas ********** */}
            {currentStep === steps.dni && (
              <>
                <Typography className="pre-onboarding-title" component="h1">
                  {i18n.PreOnboardingRechargeLoan.title}
                </Typography>
                <Typography className="pre-onboarding-subtitle" component="h3">
                  {i18n.PreOnboardingRechargeLoan.subtitle}
                </Typography>
                <Box mt={4} />
              </>
            )}
            {/* ********** Ingreso de DNI ********** */}
            {currentStep === steps.dni && (
              <DNIStep onSubmit={handleSubmitDNI} loading={loading} />
            )}
            {/* ********** Seleccion de CUIT ********** */}
            {currentStep === steps.resolveCustomer && (
              <IdentityResolverStep
                customers={customerList}
                onSubmit={handleSubmitIdentityResolverStep}
                goBack={handleChangeCustomer}
                loading={loading}
              />
            )}
            {/* ********** Ingreso de EMAIL ********** */}
            {currentStep === steps.email && (
              <EmailStep
                onSubmit={handleSubmitEmailStep}
                goBack={handleChangeCustomer}
                loading={loading}
              />
            )}
            {/* ********** Ingreso de Celular ********** */}
            {currentStep === steps.cellphone && (
              <CellphoneStep
                onSubmit={handleSubmitCellphone}
                goBack={handleChangeCustomer}
                currentCellphone={customerCellphone}
                loading={loading}
              />
            )}
            {/* ********** Verificacion codigo SMS ********** */}
            {currentStep === steps.verificationCode && (
              <VerificationCodeStep
                userInputLabel={
                  verificationCodeMethodEmail
                    ? i18n.VerificationCodeStep.methodEmailLabel
                    : i18n.VerificationCodeStep.methodSmsLabel
                }
                userInput={verificationCodeMethodEmail ? customerEmail : customerCellphone}
                onChangeUserInput={verificationCodeMethodEmail ? handleChangeEmail : handleChangeCellphone}
                onResend={resendVerificationCode}
                onSubmit={handleSubmitVerificationCode}
                loading={loading}
                resendMessage={resendMessage}
                onError={invalidEmail}
                goBack={handleChangeCustomer}
              />
            )}
          </Grid>
          {!isMobileOperatingSystem() && (
            <Grid item md={6}>
              <img src={banner} alt="Credicuotas Banner" width="100%" />
            </Grid>
          )}
        </Grid>
      )}
      {/* ********** Loading calculadora ********** */}
      {calculatingOffer && (
        <>
          <CalculatorLoading />
          <Box mt={4} />
        </>
      )}
      {/* ********** Toast con mensajes de error ********** */}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />
      )}
      <Footer type={FooterType.RECHARGES} />
    </>
  );
};

export default PreOnboardingRechargeLoan;
