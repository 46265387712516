import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { getFooterData } from '../../api/onboardingService';
import i18n from '../../common/i18n';
import '../../assets/css/footer.css';

export const FooterType = {
  DEFAULT: 'preonboarding_default',
  UBER: 'preonboarding_uber',
  SERVICES: 'preonboarding_servicios',
  RECHARGES: 'preonboarding_recargas',
};

const Footer = ({ type }) => {
  const [footerData, setFooterData] = useState();
  const getData = async () => {
    try {
      const response = await getFooterData(type);
      setFooterData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const init = () => {
    getData();
  };

  useEffect(() => init(), []);

  return (
    <Box className="footer">
      <Grid container>
        <Grid item xs={12}>
          <Typography align="left" className="footerMediumText">
            {i18n.footer.firstParagraph}
            {new Date().getFullYear()}
          </Typography>
          <Typography align="left" mt={4} className="footerSmallText">
            {footerData ? footerData.ratesAndExample : ''}
          </Typography>
          <Typography align="left" mt={2} className="footerSmallText">
            {footerData ? footerData.ratesAndExample2 : ''}
          </Typography>
          <Typography align="left" mt={1} className="footerBigText">
            {footerData ? footerData.totalFinantialCost : ''}
          </Typography>
          <Typography align="left" mt={1} className="footerBigText">
            {footerData ? footerData.taxesPhrase : ''}
          </Typography>
          <Typography align="left" mt={2} className="footerSmallText">
            {footerData ? footerData.finantialCostWithoutTaxes : ''}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Box>
  );
};

Footer.propTypes = {
  type: PropTypes.string,
};

Footer.defaultProps = {
  type: FooterType.DEFAULT,
};

export default Footer;
