import PropTypes from 'prop-types';
import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Img } from '@doit/pcnt-react-ui-library';
import parse from 'html-react-parser';
import { fillMessageWith, getResponseErrorOrDefault } from '../utils/functionsUtil';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import i18n from '../common/i18n';
import Toast from './commons/Toast';
import '../assets/css/payServices.css';
import {
  getServicesCompanies, getPaymentModes,
} from '../api/onboardingService';
import PayServicesBillList from './PayServicesBillList';
import PayServicesDynamicForm from './PayServicesDynamicForm';
import PayServicesBusinessSearch from './PayServicesBusinessSearch';
import PayServicesBusinessSelect from './PayServicesBusinessSelect';
import CompanySlider from './CompanySlider';
import imgLogoLuz from '../assets/imgs/services-luz.png';
import imgLogoAgua from '../assets/imgs/services-agua.png';
import imgLogoTelefonia from '../assets/imgs/services-telefonia.png';
import imgLogoGas from '../assets/imgs/services-gas.png';
import imgLogoPrepaga from '../assets/imgs/services-prepaga.png';
import imgLogoInternet from '../assets/imgs/services-internet-tv.png';
import imgServices from '../assets/imgs/services-image.png';

/* eslint arrow-body-style:off */
const PayServices = ({ loan, offer }) => {
  const maxAmount = offer?.maxAmount;
  const maxInstallments = offer?.installments[0].installments;
  const info = i18n.PRODUCT_LOAD.PAY_SERVICE;
  const [selectedServiceCompany, setSelectedServiceCompany] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [paymentBillDetailList, setPaymentBillDetailList] = useState();
  const [selectedCompanyList, setSelectedCompanyList] = useState();
  const [serviceSelected, setServiceSelected] = useState();
  const [serviceFormFilled, setServiceFormFilled] = useState({});

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  const cleanServiceCompanyInit = async () => {
    await setSelectedServiceCompany();
    await setSelectedCompanyList();
    await setPaymentBillDetailList();
    await setServiceSelected();
    await setServiceFormFilled({});
  };

  const onClickCompany = async (searchCompany) => {
    cleanServiceCompanyInit();
    try {
      const resCompanies = await getServicesCompanies(loan.hashKey, searchCompany.searchString);
      if (resCompanies.data?.length > 0) {
        const serviceCompany = resCompanies.data.filter((c) => c.code === searchCompany.code)?.[0];
        const resModes = await getPaymentModes(loan.hashKey, serviceCompany.code);
        const selectedServiceCompanyTemp = {
          name: serviceCompany?.name,
          code: serviceCompany?.code,
          listCompanies: resModes?.data,
        };
        setSelectedServiceCompany(selectedServiceCompanyTemp);
        const selectedCompanyListTemp = selectedServiceCompanyTemp.listCompanies?.filter((method) => {
          return method?.paymentType === 'CSF' || method?.paymentType === 'CAB' || method?.paymentType === 'SFM'
            || method?.paymentType === 'CLR';
        });
        if (selectedCompanyListTemp.length > 0) {
          setSelectedCompanyList(selectedCompanyListTemp);
        }
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
      setSelectedServiceCompany();
    }
  };

  return (
    <>
      <Img src={imgServices} alt="Pago de Servicios Banner" className="services-banner hide-lg" />
      <Grid container justifyContent="center" style={{ textAlign: 'left' }}>
        <Grid item xs={10}>

          <Grid item sm={11} md={8} lg={6} xl={5}>
            <Typography className="services-title">
              {parse(fillMessageWith(info.title, moneyFormatterWithoutDecimals(maxAmount)))}
            </Typography>
            <Typography className="services-subtitle">
              {parse(fillMessageWith(info.subtitle, maxInstallments))}
            </Typography>
          </Grid>

          {!selectedServiceCompany && (
            <>
              <Grid item sm={11} md={8} lg={6} xl={5}>
                <PayServicesBusinessSearch
                  loan={loan}
                  setSelectedCompanyList={setSelectedCompanyList}
                  setSelectedServiceCompany={setSelectedServiceCompany}
                  setErrorMessage={setErrorMessage}
                  cleanServiceCompanyInit={cleanServiceCompanyInit}
                />
              </Grid>

              <Grid item sm={11} md={8} lg={6} style={{ paddingTop: '50px' }}>
                <Typography className="services-title-companies">
                  {info.exampleServicesTitle}
                </Typography>
                <Grid
                  container
                  className="services-types-container"
                  justify="space-around"
                >
                  <Grid item xs={3} sm={2}>
                    <Img className="services-logo-img" src={imgLogoLuz} alt="luz" />
                    <Typography>{info.exampleServicesElectricity}</Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Img className="services-logo-img" src={imgLogoAgua} alt="agua" />
                    <Typography>{info.exampleServicesWater}</Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Img className="services-logo-img" src={imgLogoTelefonia} alt="telefonia" />
                    <Typography>{info.exampleServicesPhone}</Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Img className="services-logo-img" src={imgLogoGas} alt="gas" />
                    <Typography>{info.exampleServicesGas}</Typography>
                  </Grid>
                  <Grid item xs={3} sm={2} className="hide-md">
                    <Img className="services-logo-img" src={imgLogoPrepaga} alt="prepaga" />
                    <Typography>{info.exampleServicesPrepaid}</Typography>
                  </Grid>
                  <Grid item xs={3} sm={2} className="hide-md">
                    <Img className="services-logo-img" src={imgLogoInternet} alt="internet" />
                    <Typography>{info.exampleServicesInternetAndTv}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={11} md={8} lg={6} style={{ paddingTop: '20px' }}>
                <Typography className="services-title-companies">
                  {info.frequentCompaniesTitle}
                </Typography>
                <Grid container>
                  <Grid item>
                    <CompanySlider
                      onClickCompany={onClickCompany}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {selectedServiceCompany && (
          <Grid item sm={11} md={8} lg={6} xl={5}>
            <PayServicesBusinessSelect
              selectedServiceCompany={selectedServiceCompany}
              selectedCompanyList={selectedCompanyList}
              paymentBillDetailList={paymentBillDetailList}
              setServiceSelected={setServiceSelected}
              cleanServiceCompanyInit={cleanServiceCompanyInit}
            />

            { /* Formulario dinamico */}
            { /* Muestra el formulario del servicio seleccionado */}
            <PayServicesDynamicForm
              loan={loan}
              selectedServiceCompany={selectedServiceCompany}
              paymentBillDetailList={paymentBillDetailList}
              serviceSelected={serviceSelected}
              serviceFormFilled={serviceFormFilled}
              setErrorMessage={setErrorMessage}
              setServiceFormFilled={setServiceFormFilled}
              setPaymentBillDetailList={setPaymentBillDetailList}
            />

            { /* LISTADO DE FACTURAS */}
            { /* Muestra el listado de facturas para el servicio ingresado,
                   por ejemplo las facturas de impuesto automotor */}
            <PayServicesBillList
              loan={loan}
              offer={offer}
              selectedServiceCompany={selectedServiceCompany}
              paymentBillDetailList={paymentBillDetailList}
              serviceSelected={serviceSelected}
              serviceFormFilled={serviceFormFilled}
              setErrorMessage={setErrorMessage}
            />
          </Grid>
          )}

        </Grid>
      </Grid>
      {
        errorMessage && (
          <Toast
            messageOnError={errorMessage}
            open
            onClose={dismissMessage}
          />
        )
      }
    </>
  );
};

PayServices.propTypes = {
  loan: PropTypes.shape(),
  offer: PropTypes.shape(),
};

PayServices.defaultProps = {
  loan: undefined,
  offer: undefined,
};

export default PayServices;
