import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import '../assets/css/documentLoadStep.css';
import { fillMessageWith } from '../utils/functionsUtil';
import i18n from '../common/i18n';

const DocumentLoanStep = ({ step, img, items }) => (
  <>
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '30px' }}>
      <Typography className="document-load-step-number">{step}</Typography>
      <img src={img} alt="IMG" className="document-load-step-img" />
      <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ marginLeft: '20px' }}>
        {items.map((item) => (
          <Typography className="document-load-step-item" key={item}>
            {parse(fillMessageWith(
              i18n.DocumentLoadStep.item,
              item,
            ))}
          </Typography>
        ))}
      </Box>
    </Box>
  </>
);

DocumentLoanStep.propTypes = {
  step: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DocumentLoanStep;
