import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import BenefitBanner from './BenefitBanner';
import InstallmentCardList from './InstallmentCardList';
import OnboardingSlider from './OnboardingSlider';
import {
  getResponseErrorOrDefault, sendDataLayerEvent, EcommerceEvents, sendDataLayerEventObject,
} from '../utils/functionsUtil';
import {
  getOnboardingCustomOffer, getOnboardingCustomOfferWithOriginalLine, setInstallment,
} from '../api/onboardingService';
import Toast from './commons/Toast';
import i18n from '../common/i18n';
import NextSteps from './NextSteps';
import { loanRedirect } from './LoanRequestRedirectService';

const SimpleOffer = ({ loanRequest }) => {
  const [installments, setInstallments] = useState();
  const [installmentsOriginalLine, setInstallmentsOriginalLine] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const getCustomOffer = async (amount) => {
    try {
      setLoading(true);
      // Se definio utilizar el 70% del maxAmount del loanRequest como valor default,
      // asi evitar el request para obtener el minimo
      const requestedAmount = amount || Math.ceil((loanRequest.response.maxAmount * 0.7) / 100) * 100;
      setSelectedAmount(requestedAmount);
      const installmentDetailsResponse = await getOnboardingCustomOffer(loanRequest.hashKey, requestedAmount, true);
      setSelectedInstallment(installmentDetailsResponse.data[0]);
      setInstallments(installmentDetailsResponse.data);

      const installmentsOriginalLineResponse = await getOnboardingCustomOfferWithOriginalLine(
        loanRequest.hashKey, requestedAmount, installmentDetailsResponse.data[0].idLinea,
      );
      setInstallmentsOriginalLine(installmentsOriginalLineResponse.data);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
      setSelectedAmount(undefined);
      setInstallments(undefined);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeAmount = (selectedAmountAux) => {
    getCustomOffer(selectedAmountAux);
  };

  const handleContinue = async () => {
    try {
      setLoading(true);
      const installment = { ...selectedInstallment, requestedAmount: selectedAmount, isDual: false };
      await setInstallment(loanRequest.hashKey, installment);
      sendDataLayerEvent(EcommerceEvents.ADD_TO_CART, selectedAmount, loanRequest.sappCreditId);
      sendDataLayerEventObject({
        event: 'seleccion_cuota',
        CMPTestC: !loanRequest.isNewClient,
        organismo: loanRequest.subProductId,
      });
      loanRedirect(loanRequest, navigate, queryParams.toString());
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const resolveMessage = () => {
    let message;
    if (loanRequest.productToPay.rechargeType) {
      message = i18n.SimpleOffer.rechargeInstallmentMessage;
    } else if (loanRequest.productToPay.billPaymentType) {
      message = i18n.SimpleOffer.billInstallmentMessage;
    } else if (loanRequest.productToPay.ecommerceCartType) {
      message = i18n.SimpleOffer.bnplInstallmentMessage;
    } else if (loanRequest.productToPay.showcaseType) {
      message = i18n.SimpleOffer.showcaseInstallmentMessage;
    }
    return message;
  };

  const init = () => {
    let amount = loanRequest.requestedAmount;
    if (loanRequest.productToPay) {
      if (loanRequest.productToPay.rechargeType) {
        amount = loanRequest.productToPay.products[0].productAttributes.IV1;
      } else if (loanRequest.productToPay.billPaymentType) {
        amount = loanRequest.productToPay.products[0].productAttributes.amount;
        if (!amount) {
          amount = loanRequest.productToPay.products[0].productAttributes.IV1;
        }
      } else if (loanRequest.productToPay.ecommerceCartType) {
        amount = loanRequest.productToPay.products[0].productAttributes.price;
      } else if (loanRequest.productToPay.showcaseType) {
        amount = loanRequest.productToPay.products[0].productAttributes.productAmount;
      }
    }
    getCustomOffer(amount);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {installments && (
        <>
          {/* ********** Componente slider ********** */}
          {!loanRequest.productToPay ? (
            <OnboardingSlider
              loanRequest={loanRequest}
              installments={installments}
              selectedAmount={selectedAmount}
              onChangeAmount={handleChangeAmount}
              loading={loading}
            />
          ) : (
            <Typography className="simple-offer-installment-message">{resolveMessage()}</Typography>
          )}
          {/* ********** Componente banner de beneficios ********** */}
          <BenefitBanner
            loanRequest={loanRequest}
            installments={installments}
            selectedInstallment={selectedInstallment}
          />
          {/* ********** Componente plan de cuotas ********** */}
          <Box mt={2} />
          <InstallmentCardList
            installments={installments}
            installmentsOriginalLine={installmentsOriginalLine}
            onSelect={setSelectedInstallment}
            loading={loading}
          />
          <Box mt={3} />
          <Grid container>
            <Grid item md={3} />
            <Grid item xs={12} sm={12} md={6}>
              <Button
                variant="primary"
                className="btn-primary"
                type="button"
                onClick={handleContinue}
                fullWidth
                disabled={loading}
                loading={loading}
              >
                {i18n.SimpleOffer.cta}
              </Button>
            </Grid>
          </Grid>
          {/* ********** Proximos pasos ********** */}
          <Box mt={5} />
          <NextSteps
            installments={installments}
            amount={selectedAmount}
            selectedInstallment={selectedInstallment}
          />
        </>
      )}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={() => setErrorMessage(undefined)}
        />
      )}
    </>
  );
};

SimpleOffer.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default SimpleOffer;
