import './App.css';
import { ThemeProvider } from '@mui/material/styles';

import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';
import { ToasterProvider } from './contexts/ToasterContext';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <ToasterProvider>
          <AppRouter />
        </ToasterProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
