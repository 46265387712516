import { Alert } from '@doit/pcnt-react-ui-library';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';

const SummarySaleCompletionWelcome = ({ loanRequest }) => {
  const isAutomaticWelcome = () => loanRequest.saleCompletionWelcomeAutomatic;
  const isManualWelcome = () => loanRequest.saleCompletionManual;

  return (
    <>
      {isAutomaticWelcome() && (
        <Box mt={3}>
          <Alert severity="info">
            <Typography align="left" fontSize="12px">{i18n.OnboardingSummary.automaticWelcomeMessage}</Typography>
          </Alert>
        </Box>
      )}
      {isManualWelcome() && (
        <Box mt={3}>
          <Alert severity="info">
            <Typography align="left" fontSize="12px">{i18n.OnboardingSummary.manualWelcomeMessage}</Typography>
          </Alert>
        </Box>
      )}
    </>
  );
};

SummarySaleCompletionWelcome.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default SummarySaleCompletionWelcome;
