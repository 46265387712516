import { useLocation, useNavigate } from 'react-router';
import { Button } from '@doit/pcnt-react-ui-library';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import DocumentLoadedList from './DocumentLoadedList';
import '../assets/css/uploadDocumentCompleted.css';
import { loanRedirect } from './LoanRequestRedirectService';

const UploadDocumentCompleted = ({ loan, requiredDocumentList }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const handleOnContinue = () => {
    loanRedirect(loan, navigate, queryParams.toString());
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box mb={2} sx={{ margin: '20px' }}>
          <Typography className="upload-document-completed-title">{i18n.UploadDocumentCompleted.title}</Typography>
          <Box mt={2} />
          <DocumentLoadedList requiredDocumentList={requiredDocumentList} />
          <Box mt={5} />
          <Button
            variant="primary"
            className="btn-primary"
            type="submit"
            fullWidth
            onClick={handleOnContinue}
          >
            {i18n.UploadDocumentCompleted.ctaLabel}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

UploadDocumentCompleted.propTypes = {
  loan: PropTypes.shape().isRequired,
  requiredDocumentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default UploadDocumentCompleted;
