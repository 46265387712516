import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { fillMessageWith, isRevolvingLine } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import { moneyFormatter, numberFormatter } from '../utils/formatterUtil';

const SummaryFooter = ({ loanRequest, summaryData }) => {
  const calculateAmountToReturn = () => summaryData.requestedAmount + summaryData.interests + summaryData.serviceCost
  + summaryData.tax + summaryData.impDebitCredit;

  return (
    <>
      <Typography className="summary-interest-taxes">
        {fillMessageWith(
          i18n.SummaryFooter.interestsAndTaxes,
          moneyFormatter(summaryData.interests),
          moneyFormatter(summaryData.tax),
          moneyFormatter(calculateAmountToReturn()),
          moneyFormatter(summaryData.impDebitCredit),
        )}
      </Typography>
      <Typography className="summary-footer">
        {parse(fillMessageWith(i18n.SummaryFooter.footer,
          isRevolvingLine(loanRequest) ? 'Créditos' : 'Préstamos',
          isRevolvingLine(loanRequest) ? 'Crédito' : 'Préstamo',
          isRevolvingLine(loanRequest) ? 'crédito' : 'préstamo'))}
      </Typography>
      <Grid item xs={12}>
        <Typography className="summary-detail-cfta">
          {fillMessageWith(i18n.SummaryFooter.CFTEA, numberFormatter.format(summaryData.totalFinancialCost))}
        </Typography>
        <Typography className="summary-detail-tna">
          {fillMessageWith(i18n.SummaryFooter.TNA, numberFormatter.format(summaryData.nominalAnnualRate))}
        </Typography>
        <Typography className="summary-detail-tea">
          {fillMessageWith(i18n.SummaryFooter.TEA, numberFormatter.format(summaryData.estimatedAnnualRate))}
        </Typography>
      </Grid>
    </>
  );
};

SummaryFooter.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
};

export default SummaryFooter;
