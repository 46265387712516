import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import DualOfferTab from './DualOfferTab';
import '../assets/css/dualOffer.css';
import {
  getOnboardingCustomOfferDual, getOnboardingCustomOffer, getOnboardingCustomOfferWithOriginalLine,
  getOnboardingCustomOfferWithOriginalLineDual,
  getOnboardingOfferDual,
  setInstallment,
} from '../api/onboardingService';
import {
  getResponseErrorOrDefault, sendDataLayerEvent, EcommerceEvents, sendDataLayerEventObject,
} from '../utils/functionsUtil';
import Toast from './commons/Toast';
import DualOfferTabContent from './DualOfferTabContent';
import NextSteps from './NextSteps';
import { loanRedirect } from './LoanRequestRedirectService';

const DualOffer = ({ loanRequest }) => {
  const [maxAmountDual, setMaxAmountDual] = useState();
  const [maxAmountRenew, setMaxAmountRenew] = useState();
  const [dual, setDual] = useState(false);
  const [installments, setInstallments] = useState();
  const [installmentsOriginalLine, setInstallmentsOriginalLine] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [loading, setLoading] = useState();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const getCustomOfferDual = async (amount) => {
    try {
      setLoading(true);
      // Se definio utilizar el 70% del maxAmount del loanRequest como valor default,
      // y hago que caiga justo en un step del slider
      const requestedAmount = amount || Math.ceil((maxAmountDual * 0.7) / 100) * 100;
      setSelectedAmount(requestedAmount);
      const installmentDetailsResponse = await getOnboardingCustomOfferDual(loanRequest.hashKey, requestedAmount, true);
      setSelectedInstallment(installmentDetailsResponse.data[0]);
      setInstallments(installmentDetailsResponse.data);

      const installmentsOriginalLineResponse = await getOnboardingCustomOfferWithOriginalLineDual(
        loanRequest.hashKey, requestedAmount, installmentDetailsResponse.data[0].idLinea,
      );
      setInstallmentsOriginalLine(installmentsOriginalLineResponse.data);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const getCustomOffer = async (amount) => {
    try {
      setLoading(true);
      let requestedAmount = loanRequest.response.prePaidLoanAmount;
      requestedAmount += amount || Math.ceil((maxAmountRenew * 0.7) / 100) * 100;
      requestedAmount = Math.ceil(requestedAmount / 100) * 100;
      setSelectedAmount(requestedAmount);
      const installmentDetailsResponse = await getOnboardingCustomOffer(loanRequest.hashKey, requestedAmount, true);
      setSelectedInstallment(installmentDetailsResponse.data[0]);
      setInstallments(installmentDetailsResponse.data);

      const installmentsOriginalLineResponse = await getOnboardingCustomOfferWithOriginalLine(
        loanRequest.hashKey, requestedAmount, installmentDetailsResponse.data[0].idLinea,
      );
      setInstallmentsOriginalLine(installmentsOriginalLineResponse.data);
      setDual(false);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeAmount = (selectedAmountAux) => {
    if (dual) {
      getCustomOfferDual(selectedAmountAux);
    } else {
      getCustomOffer(selectedAmountAux);
    }
  };

  const selectDual = () => {
    setDual(true);
    setInstallments(undefined);
    getCustomOfferDual();
  };

  const selectRenew = () => {
    setDual(false);
    setInstallments(undefined);
    getCustomOffer();
  };

  const onContinue = async () => {
    try {
      setLoading(true);
      const installment = { ...selectedInstallment, requestedAmount: selectedAmount, isDual: dual };
      await setInstallment(loanRequest.hashKey, installment);
      sendDataLayerEvent(EcommerceEvents.ADD_TO_CART, selectedAmount, loanRequest.sappCreditId);
      sendDataLayerEventObject({
        event: 'seleccion_cuota',
        CMPTestC: !loanRequest.isNewClient,
        organismo: loanRequest.subProductId,
      });
      loanRedirect(loanRequest, navigate, queryParams.toString());
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      setDual(true);
      const res = await getOnboardingOfferDual(loanRequest.hashKey);
      const maxAmount = Math.floor(res.data.maxAmount / 100) * 100;
      setMaxAmountDual(maxAmount);
      setMaxAmountRenew(
        Math.floor((loanRequest.response.maxAmount - loanRequest.response.prePaidLoanAmount) / 100) * 100,
      );
      // Se definio utilizar el 70% del maxAmount del loanRequest como valor default,
      // y hago que caiga justo en un step del slider
      await getCustomOfferDual(Math.ceil((maxAmount * 0.7) / 100) * 100);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Box className="dual-offer-container">
        {/* ********** Componente selector ********** */}
        {(maxAmountDual && maxAmountRenew) && (
          <DualOfferTab
            dualAmount={maxAmountDual}
            renewAmount={maxAmountRenew}
            onDual={selectDual}
            onRenew={selectRenew}
          />
        )}
        {/* ********** Componente contenido ********** */}
        {installments && (
          <>
            <DualOfferTabContent
              isDual={dual}
              onChangeAmount={handleChangeAmount}
              onSelectInstallment={setSelectedInstallment}
              selectedInstallment={selectedInstallment}
              maxAmount={dual ? maxAmountDual : maxAmountRenew}
              loanRequest={loanRequest}
              installments={installments}
              installmentsOriginalLine={installmentsOriginalLine}
              onContinue={onContinue}
              loading={loading}
            />
          </>
        )}
      </Box>
      {/* ********** Proximos pasos ********** */}
      {installments && (
        <>
          <Box mt={5} />
          <NextSteps
            installments={installments}
            amount={selectedAmount}
            selectedInstallment={selectedInstallment}
          />
        </>
      )}
      {/* ********** Mensajes de error ********** */}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={() => setErrorMessage(undefined)}
        />
      )}
    </>
  );
};

DualOffer.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default DualOffer;
