import imgWhatsapp from '../assets/imgs/btn_whatsapp.png';
import { isMobileOperatingSystem } from '../utils/functionsUtil';
import { getEnv, WA_CC_NUMBER, WA_CC_TUCUMAN_BRANCH_CASH } from '../api/env';

export const loadWebchat = (loanRequest) => {
  let js;
  if (isMobileOperatingSystem()) {
    js = document.createElement('div');
    js.id = 'webchat';
    js.style.position = 'fixed';
    js.style.bottom = 0;
    js.style.right = 0;
    js.style.float = 'right';
    js.style.marginBottom = '10px';
    js.style.zIndex = 100;
    js.addEventListener('click', () => {
      let ccPhoneNumberWhatsapp = getEnv(WA_CC_NUMBER);
      if (loanRequest?.commerceId === 99) {
        ccPhoneNumberWhatsapp = getEnv(WA_CC_TUCUMAN_BRANCH_CASH);
      }
      window.open(`https://api.whatsapp.com/send?phone=${ccPhoneNumberWhatsapp}&text=${encodeURIComponent('Hola quiero hacer una consulta por mi solicitud')}`);
    });
    const img = document.createElement('img');
    img.style.width = '150px';
    img.src = imgWhatsapp;
    js.appendChild(img);
  } else {
    js = document.createElement('script');
    js.type = 'text/javascript';
    js.async = 1;
    js.src = 'https://s3-sa-east-1.amazonaws.com/static.credicuotas.com.ar/botmakerWebChat/cc-botmaker-webchat-no-google-analytics-new-icon.js';
  }
  document.body.appendChild(js);
};

export const deleteWebChat = () => {
  const webchatDiv = document.getElementById('webchat');
  if (webchatDiv) {
    webchatDiv.remove();
  }
};
