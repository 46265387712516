import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import { Button, Signature } from '@doit/pcnt-react-ui-library';
import { fillMessageWith, isEcommerceCartLoan, isMobileOperatingSystem } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import SummaryDetailCard from './SummaryDetailCard';
import SummaryDisbursement from './SummaryDisbursement';
import SummaryPaymentMethod from './SummaryPaymentMethod';
import SummaryFooter from './SummaryFooter';
import SummarySaleCompletionWelcome from './SummarySaleCompletionWelcome';

const SummarySignatureMode = ({
  loanRequest, summaryData, prepaidCardLastDigits, sigPadRef, showAgreement, signAgreement, signature, signLoading,
}) =>
  (
    <>
      {/* 1er columna en desktop */}
      <Grid item xs={12} md={6} pt={10} pl={4} pr={4}>
        <Typography className="summary-title" variant="h1">
          {isEcommerceCartLoan(loanRequest) ? i18n.OnboardingSummary.title[1] : i18n.OnboardingSummary.title[0]}
        </Typography>
        <Typography className="summary-description">
          {parse(fillMessageWith(i18n.OnboardingSummary.description, loanRequest.customerName))}
        </Typography>
        <Box mt={3} />
        <SummaryDetailCard
          loanRequest={loanRequest}
          summaryData={summaryData}
        />
        <Box mt={3} />
        <SummaryDisbursement
          loanRequest={loanRequest}
          summaryData={summaryData}
          prepaidCardLastDigits={prepaidCardLastDigits}
        />
        <Box mt={3} />
        <SummaryPaymentMethod
          loanRequest={loanRequest}
          summaryData={summaryData}
        />
        <SummarySaleCompletionWelcome
          loanRequest={loanRequest}
        />
        {!isMobileOperatingSystem() && (
          <SummaryFooter loanRequest={loanRequest} summaryData={summaryData} />
        )}
      </Grid>
      <Grid item md={1} />
      {/* 2da columna en desktop */}
      <Grid
        item
        xs={12}
        md={5}
        pl={4}
        pr={4}
        className={isMobileOperatingSystem() ? '' : 'summary-second-column'}
      >
        {!isMobileOperatingSystem() && (
          <Typography className="summary-title" variant="h2">
            {isEcommerceCartLoan(loanRequest)
              ? i18n.OnboardingSummary.signatureTitle[1] : i18n.OnboardingSummary.signatureTitle[0]}
          </Typography>
        )}
        <Box mt={6} className="summary-signature-container">
          <Signature
            clearLabel={i18n.OnboardingSummary.clearSignature}
            sigPadRef={sigPadRef}
            width={isMobileOperatingSystem() ? 290 : 450}
            height={200}
          />
        </Box>
        <Button
          className="summary-download-agreement"
          onClick={showAgreement}
          variant="tertiary"
        >
          {i18n.OnboardingSummary.downloadAgreement}
        </Button>
        <Box mt={3} />
        <Button
          variant="primary"
          className="btn-primary"
          fullWidth
          onClick={signAgreement}
          disabled={signature === undefined}
          loading={signLoading}
        >
          {i18n.OnboardingSummary.signCTA}
        </Button>
        {isMobileOperatingSystem() && (
          <SummaryFooter loanRequest={loanRequest} summaryData={summaryData} />
        )}
      </Grid>
    </>
  );

SummarySignatureMode.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
  prepaidCardLastDigits: PropTypes.string.isRequired,
  sigPadRef: PropTypes.func.isRequired,
  showAgreement: PropTypes.func.isRequired,
  signAgreement: PropTypes.func.isRequired,
  signature: PropTypes.shape().isRequired,
  signLoading: PropTypes.bool.isRequired,
};

export default SummarySignatureMode;
