import { routes } from '../constants/routes';
import { getLoanRequest, getHashLoanByMinifyIdWithUrlParams } from '../api/onboardingService';
import { redirectToPreviousOnboardingSlashM } from '../utils/commonServices';

const buildUrl = (url, urlParams) => {
  let redirectUrl = url;
  if (urlParams) {
    redirectUrl += `?${urlParams}`;
  }
  return redirectUrl;
};

const redirectTo = (url, urlParams) => {
  window.location.href = buildUrl(url, urlParams);
};

const navigateTo = (url, urlParams, navigate) => {
  navigate(buildUrl(url, urlParams));
};

export const loanRedirect = async (loan, navigate, urlParams) => {
  const res = await getLoanRequest(loan.hashKey);
  const loanRequest = res.data;
  if (!loanRequest.isApproved) {
    navigateTo(`${routes.OnboardingRejection}/${loan.hashKey}`, urlParams, navigate);
  } else if (loanRequest.mustLoadProduct) {
    navigateTo(`${routes.OnboardingProductLoad}/${loan.hashKey}`, urlParams, navigate);
  } else if (!loanRequest.installmentAmountSelected) {
    navigateTo(`${routes.OnboardingInstallmentPlan}/${loan.hashKey}`, urlParams, navigate);
  } else if (!loanRequest.documentsCompleted) {
    navigateTo(`${routes.OnboardingDocumentsLoad}/${loan.hashKey}`, urlParams, navigate);
  } else if (!loanRequest.customerDataCompleted) {
    navigateTo(`${routes.OnboardingCustomerData}/${loan.hashKey}`, urlParams, navigate);
  } else if (!loanRequest.userQuestionWasAnswered && !loanRequest.skipUserValidations) {
    redirectToPreviousOnboardingSlashM(loan.hashKey);
  } else if (!loanRequest.hasSigned) {
    navigateTo(`${routes.OnboardingSummary}/${loan.hashKey}`, urlParams, navigate);
  } else {
    navigateTo(`${routes.OnboardingFinal}/${loan.hashKey}`, urlParams, navigate);
  }
};

export const loanRedirectMinHash = async (hash, navigate) => {
  try {
    const response = await getHashLoanByMinifyIdWithUrlParams(hash);
    const { url, urlParamsFromMiniLink, loanRequest } = response.data;
    if (url) {
      redirectTo(url, urlParamsFromMiniLink);
    } else {
      const urlParamsFromQueryParamURL = new URLSearchParams(window.location.search);
      loanRedirect(loanRequest, navigate, urlParamsFromMiniLink || urlParamsFromQueryParamURL);
    }
  } catch (error) {
    console.log(error);
  }
};

export default loanRedirect;
