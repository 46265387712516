import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@doit/pcnt-react-ui-library';
import i18n from '../../common/i18n';

const DialogWrapper = ({
  open, title, message, onClose, primaryAction, secondaryAction, primaryActionLabel, secondaryActionLabel,
}) => (
  <>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        closeButton={<CloseIcon />}
        closeButtonOnClick={onClose}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions alignContent="center">
        {secondaryAction && (
          <Button variant="secondary" onClick={secondaryAction}>
            {secondaryActionLabel}
          </Button>
        )}
        {primaryAction && (
          <Button onClick={primaryAction} className="btn-primary">
            {primaryActionLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </>
);

DialogWrapper.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  primaryActionLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
};

DialogWrapper.defaultProps = {
  open: true,
  title: undefined,
  message: undefined,
  primaryAction: undefined,
  secondaryAction: undefined,
  primaryActionLabel: i18n.DialogWrapper.primaryActionLabel,
  secondaryActionLabel: i18n.DialogWrapper.secondaryActionLabel,
};

export default DialogWrapper;
