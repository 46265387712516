import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import { Img, Button, Checkbox } from '@doit/pcnt-react-ui-library';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../assets/css/onboardingCustomer.css';
import parse from 'html-react-parser';
import i18n from '../common/i18n';
import cardinalLogo from '../assets/imgs/cardinal-logo.svg';
import checkYellow from '../assets/imgs/check-yellow.svg';
import { fillMessageWith } from '../utils/functionsUtil';

const CustomerDataInputCardinalAssistance = ({
  cardinalData, cardinalAssistanceDebitCardCheck, setCardinalAssistanceDebitCardCheck,
}) => {
  const info = i18n.OnboardingCustomerData.cardinal;
  const [showDetails, setShowDetails] = useState(false);

  const handleChange = () => {
    setCardinalAssistanceDebitCardCheck(!cardinalAssistanceDebitCardCheck);
  };

  useEffect(() => {}, [cardinalData, cardinalAssistanceDebitCardCheck]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>

        <Grid item xs={2}>
          <Img src={cardinalLogo} alt="cardinal-logo" width={50} />
        </Grid>
        <Grid item xs={10}>
          <Typography
            className="customer-data-cardinal-text"
            style={{ fontWeight: '700', fontSize: '20px', textAlign: 'left' }}
          >
            {info.title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="customer-data-cardinal-text">
            {info.subTitle}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="customer-data-cardinal-text">
            <Img src={checkYellow} />
            {parse(info.mainItems[0])}
          </Typography>
          <Typography className="customer-data-cardinal-text">
            <Img src={checkYellow} />
            {parse(info.mainItems[1])}
          </Typography>
          <Typography className="customer-data-cardinal-text">
            <Img src={checkYellow} />
            {parse(info.mainItems[2])}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className="customer-data-cardinal-text"
            style={{ textAlign: 'center', fontSize: '18px', paddingTop: '10px' }}
          >
            {parse(fillMessageWith(info.oneTimePayment,
              `$${cardinalData.cardinalAssistancePolicyAmount}`))}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="customer-data-cardinal-text">
            <Checkbox
              name="accept"
              label={parse(fillMessageWith(info.acceptPayment, cardinalData.customerDebitCardLastFour))}
              checked={cardinalAssistanceDebitCardCheck}
              onChange={handleChange}
            />
          </Typography>
        </Grid>

        {showDetails ? (
          <>
            <Grid item xs={12}>
              <Typography
                className="customer-data-cardinal-text"
                style={{ fontWeight: '700', fontSize: '18px', textAlign: 'left' }}
              >
                {info.detailTitle}
              </Typography>
              <Typography className="customer-data-cardinal-text">
                {info.detailSubtitle}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary style={{ border: '1px solid #ddd' }} expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography
                            className="customer-data-cardinal-text"
                            style={{ fontSize: '18px', textAlign: 'left' }}
                          >
                            {info.healthTitle}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '0px' }}>
                      <Grid container>
                        {info.healthInsuranceItems.map((item) => (
                          <Grid item xs={12} style={{ border: '1px solid #ddd', padding: '8px 16px' }}>
                            <Typography
                              className="customer-data-cardinal-text"
                              style={{ fontSize: '14px' }}
                            >
                              <b>{item.description}</b>
                              <br />
                              {item.events}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary style={{ border: '1px solid #ddd' }} expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            className="customer-data-cardinal-text"
                            style={{ fontSize: '18px', textAlign: 'left' }}
                          >
                            {info.homeTitle}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '0px' }}>
                      <Grid container>
                        {info.homeInsuranceItems.map((item) => (
                          <>
                            <Grid item xs={12} style={{ border: '1px solid #ddd', padding: '8px 16px' }}>
                              <Typography
                                className="customer-data-cardinal-text"
                                style={{ fontSize: '14px' }}
                              >
                                <b>{item.description}</b>
                                <br />
                                {item.events}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                className="customer-data-show-details-btn"
                variant="tertiary"
                onClick={() => setShowDetails(false)}
              >
                {info.hideDetails}
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Button
              className="customer-data-show-details-btn"
              variant="tertiary"
              onClick={() => setShowDetails(true)}
            >
              {info.showDetails}
            </Button>
          </Grid>
        )}

      </Grid>
    </>
  );
};

CustomerDataInputCardinalAssistance.propTypes = {
  cardinalData: PropTypes.shape({
    cardinalAssistanceEnabled: PropTypes.bool,
    customerDebitCardLastFour: PropTypes.string,
    cardinalAssistancePolicyAmount: PropTypes.string,
    cardinalAssistanceDebitCardCheck: PropTypes.bool,
  }).isRequired,
  cardinalAssistanceDebitCardCheck: PropTypes.bool.isRequired,
  setCardinalAssistanceDebitCardCheck: PropTypes.func.isRequired,
};

export default CustomerDataInputCardinalAssistance;
