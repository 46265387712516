import { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Dialog, Grid, Typography,
} from '@mui/material';
import threeReasonsImg from '../assets/imgs/like.svg';
import whatsappImg from '../assets/imgs/whatsapp.svg';
import watchImg from '../assets/imgs/watch.svg';
import smartPhoneImg from '../assets/imgs/smartphone.svg';
import '../assets/css/nextSteps.css';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import { moneyFormatterWithoutDecimals, numberFormatter } from '../utils/formatterUtil';
import CardImage from './commons/CardImage';
import iconSelfie from '../assets/imgs/icon-selfie.svg';
import iconPen from '../assets/imgs/icon-pen.svg';
import iconMoney from '../assets/imgs/icon-money.svg';

const NextSteps = ({ installments, amount, selectedInstallment }) => {
  const [open, setOpen] = useState(false);

  const share = () => {
    let message = fillMessageWith(
      i18n.NextSteps.shareMessage[0],
      moneyFormatterWithoutDecimals(amount),
    );

    installments.forEach((installment) => {
      message += fillMessageWith(
        i18n.NextSteps.shareMessage[1],
        installment.installments,
        moneyFormatterWithoutDecimals(installment.amount),
      );
    });

    message += i18n.NextSteps.shareMessage[2];
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`);
  };

  const getReasonContent = () => (
    <Box className="next-steps-modal-container">
      <Typography className="next-steps-modal-title">{i18n.NextSteps.dialogTitle}</Typography>
      <Typography className="next-steps-modal-subtitle">{i18n.NextSteps.dialogSubtitle}</Typography>
      <Box className="next-steps-modal-box">
        <img src={watchImg} alt="priorizamos" className="next-steps-modal-box-img" />
        <Typography className="next-steps-modal-box-title">{i18n.NextSteps.dialogBox[0].title}</Typography>
        <Typography className="next-steps-modal-box-description">
          {parse(i18n.NextSteps.dialogBox[0].description)}
        </Typography>
      </Box>
      <Box className="next-steps-modal-box">
        <img src={smartPhoneImg} alt="control" className="next-steps-modal-box-img" />
        <Typography className="next-steps-modal-box-title">{i18n.NextSteps.dialogBox[1].title}</Typography>
        <Typography className="next-steps-modal-box-description">
          {parse(i18n.NextSteps.dialogBox[1].description)}
        </Typography>
      </Box>
      <Box className="next-steps-modal-box">
        <img src={threeReasonsImg} alt="beneficios" className="next-steps-modal-box-img" />
        <Typography className="next-steps-modal-box-title">{i18n.NextSteps.dialogBox[2].title}</Typography>
        <Typography className="next-steps-modal-box-description">
          {parse(i18n.NextSteps.dialogBox[2].description)}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {/* ********** Razones y compartir ********** */}
      <Box className="next-steps-first-box">
        <Box className="next-steps-first-subbox" onClick={() => setOpen(true)}>
          <img src={threeReasonsImg} alt="Buenas razones" className="next-steps-imgs" />
          <Typography className="next-steps-text-grey">{i18n.NextSteps.threeReasons}</Typography>
        </Box>
        <Box className="next-steps-first-subbox" onClick={share}>
          <img src={whatsappImg} alt="Compartir" className="next-steps-imgs" />
          <Typography className="next-steps-text-grey">{i18n.NextSteps.share}</Typography>
        </Box>
      </Box>
      {/* ********** Modal 3 razones ********** */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        {getReasonContent()}
      </Dialog>
      {/* ********** Proximos pasos ********** */}
      <Box mt={5}>
        <Typography className="next-steps-section-title">{i18n.NextSteps.nextSteps}</Typography>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <CardImage image={iconSelfie} description={i18n.NextSteps.step1} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardImage image={iconPen} description={i18n.NextSteps.step2} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardImage image={iconMoney} description={i18n.NextSteps.step3} />
          </Grid>
        </Grid>
      </Box>
      {/* ********** Tasas ********** */}
      <Box mt={5} mb={5}>
        <Typography className="next-steps-taxes-main">
          {fillMessageWith(
            i18n.NextSteps.taxes[0],
            numberFormatter.format(selectedInstallment.totalFinancialCost),
          )}
        </Typography>
        <Typography className="next-steps-taxes-sub">
          {fillMessageWith(
            i18n.NextSteps.taxes[1],
            numberFormatter.format(selectedInstallment.nominalAnnualRate),
          )}
        </Typography>
        <Typography className="next-steps-taxes-sub">
          {fillMessageWith(
            i18n.NextSteps.taxes[2],
            numberFormatter.format(selectedInstallment.estimatedAnnualRate),
          )}
        </Typography>
        <Typography className="next-steps-taxes-sub">{i18n.NextSteps.taxes[3]}</Typography>
      </Box>
    </>
  );
};

NextSteps.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedInstallment: PropTypes.shape().isRequired,
};

export default NextSteps;
