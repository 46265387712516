/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { Button, Link } from '@doit/pcnt-react-ui-library';
import {
  getLoanRequest, reminder, getUserInputFields, getProvinces, resolveConditionalComboData, setCustomerData,
  getCardinalAssistanceInfo, saveAdditionalProductRequest, getPrepaidCardLastFour,
} from '../api/onboardingService';
import i18n from '../common/i18n';
import Loading from '../components/commons/Loading';
import { loadWebchat } from '../components/webchatComponentScript';
import CustomerDataInputText from '../components/CustomerDataInputText';
import CustomerDataInputTextArea from '../components/CustomerDataInputTextArea';
import CustomerDataInputCheck from '../components/CustomerDataInputCheck';
import CustomerDataInputCombo from '../components/CustomerDataInputCombo';
import CustomerDataInputRadio from '../components/CustomerDataInputRadio';
import CustomerDataInputCard from '../components/CustomerDataInputCard';
import CustomerDataInputTextAutocomplete from '../components/CustomerDataInputTextAutocomplete';
import CustomerDataInputPrepaidCard from '../components/CustomerDataInputPrepaidCard';
import CustomerDataInputCardinalAssistance from '../components/CustomerDataInputCardinalAssistance';
import {
  getResponseErrorOrDefault, sendDataLayerCustomerInfo, isEcommerceCartLoan,
  resolveCreditCardType, sendDataLayerEventObject,
} from '../utils/functionsUtil';
import '../assets/css/onboardingCustomer.css';
import { routes } from '../constants/routes';
import Toast from '../components/commons/Toast';
import OnboardingHeader from '../components/OnboardingHeader';
import SimpleFooter from '../components/SimpleFooter';
import { loanRedirect } from '../components/LoanRequestRedirectService';
import { PREVIOUS_ORIGINATION_ONBOARDING_URL, ENVIRONMENT, getEnv } from '../api/env';

const OnboardingCustomerData = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const [enableCodeInputByUrlParam, setEnableCodeInputByUrlParam] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const info = i18n.OnboardingCustomerData;
  const [loanRequest, setLoanRequest] = useState();
  const [inputGroups, setInputGroups] = useState();
  const [groupSelected, setGroupSelected] = useState();
  const [userFilled, setUserFilled] = useState({});
  const [comboData, setComboData] = useState({});
  const [provinceList, setProvinceList] = useState();
  const [updateInputDataLoading, setUpdateInputDataLoading] = useState(false);
  const [dc, setDC] = useState({});
  const [turnOnValidations, setTurnOnValidations] = useState(false);
  const [cardinalAssistanceInfo, setCardinalAssistanceInfo] = useState();
  const [bankAccountData, setBankAccountData] = useState();
  const [bankAccountErrorMessage, setBankAccountErrorMessage] = useState();
  const [bankAccountUserInputVarName, setBankAccountUserInputVarName] = useState();
  const [accountPreValid, setAccountPreValid] = useState(false);
  const [accountPreError, setAccountPreError] = useState(false);
  const [showVerificationCbu, setShowVerificationCbu] = useState(false);
  const [showPrepaidProblems, setShowPrepaidProblems] = useState(false);
  const [showContinueBtn, setShowContinueBtn] = useState(false);

  const [cardinalAssistanceDebitCardCheck, setCardinalAssistanceDebitCardCheck] = useState(false);
  const prepaidCardDataInit = {
    province: '',
    district: '',
    location: '',
    street: '',
    number: '',
    zipCode: '',
    floor: '',
    dpto: '',
    additionalData: '',
  };
  const [prepaidCardData, setPrepaidCardData] = useState(prepaidCardDataInit);
  const [prepaidCardLastFour, setPrepaidCardLastFour] = useState(undefined);
  const PREPAID_CARD_ADDRESS_NOT_APPLY = 'N/A';

  const updateUserFilled = (name, newValue) => {
    setUserFilled((prev) => ({ ...prev, [name]: newValue }));
  };

  const updateComboData = (name, newValue) => {
    setComboData((prev) => ({ ...prev, [name]: newValue }));
  };

  const changeCBUComboToText = (variable) => {
    if (variable.userInputOptions.type === 'COMBO') {
      variable.userInputOptions.type = 'TEXT';
      variable.userInputOptions.hintAction = 'Seleccionar otra cuenta bancaria';
      variable.userInputOptions.label = 'Escribí el CBU o ALIAS donde depositaremos el préstamo';
      setShowVerificationCbu(true);
      updateUserFilled(variable.name, undefined);
    } else {
      variable.userInputOptions.type = 'COMBO';
      variable.userInputOptions.hintAction = 'Ingresar otro CBU o Alias';
      variable.userInputOptions.label = 'Seleccioná Cuenta Bancaria donde depositaremos el préstamo';
      // reset variables inital values for next cbu manual input validation
      setShowVerificationCbu(false);
      setBankAccountData(undefined);
      setAccountPreValid(false);
      setAccountPreError(false);
    }
  };

  const inputHintAction = (variable) => {
    if (variable.name === 'PtllaCBUDesembolsoYCobro' || variable.name === 'PtllaCBUCobro') {
      changeCBUComboToText(variable);
    }
  };

  const getIndexOfInputGroup = (inputGroup) => inputGroups.map((e) => e.name).indexOf(inputGroup.name);

  const isLastInputGroup = (inputGroup) => {
    const index = getIndexOfInputGroup(inputGroup);
    const lastIndex = inputGroups.length - 1;
    return index === lastIndex;
  };

  const shouldShowContinueButton = () => {
    // si el modo pago es TD y la tarjeta no esta validada
    if (userFilled.PtllaFormasDePago?.id === '3' && !showContinueBtn) {
      return false;
    }
    // si el modo desembolso es CBU y no esta seleccionado
    if (userFilled.PtllaModoDesembolso?.id === 'CBU'
        && (!userFilled.PtllaCBUDesembolsoYCobro && !userFilled.PtllaCBUCobro)) {
      return false;
    }
    // si el modo desembolso es CBU y no esta validado
    if (userFilled.PtllaModoDesembolso?.id === 'CBU' && showVerificationCbu) {
      return false;
    }
    return true;
  };

  const setNextGroupSelected = (inputGroup) => {
    if (inputGroup) {
      if (userFilled && userFilled.PtllaFormasDePago && userFilled.PtllaFormasDePago.id === '2') {
        // Si es pago voluntario
        sendDataLayerEventObject({ event: 'pago_voluntario' });
      }
      const groupName = inputGroup.name.replaceAll(' ', '_').toLowerCase();
      sendDataLayerEventObject({ event: `carga_datos_siguiente_${groupName}` });
    }
    const index = getIndexOfInputGroup(inputGroup);
    const lastIndex = inputGroups.length - 1;
    if (index < lastIndex) {
      setGroupSelected(inputGroups[index + 1]);
      // TODO: migrar animacion scroll al siguiente grupo?
      // if (groupId > 0) {
      //   $timeout(function () {
      //       nextGroupOffset = $('#inputGroup-' + (groupId - 1)).height() + $('#inputGroup-' + (groupId - 1)).offset().top;
      //       $([document.documentElement, document.body]).animate({ scrollTop: nextGroupOffset }, 2000);
      //   }, 250);
      // }
    }
  };

  const resolveToShowConditional = (variable) => !variable.userInputOptions.conditionalUserInputName
      || (variable.userInputOptions.conditionalUserInputName && variable.userInputOptions.isConditionalToShow);

  const shouldHideInputGroupVariable = (variable) => variable.userInputOptions.mustHideForBusinessPurpose
    && !(variable.name === 'PtllaTelCelular' && !loanRequest.customerPhoneValidated);

  const mustHidenIfUniqueResult = (variable) => (
    variable.userInputOptions.mustHidenIfUniqueResult && variable.userInputOptions.comboData.length === 1
  );

  const isValidBankAccountKey = () => {
    // Only validate bank account key if bankAccountUserInputVarName is set.
    // bankAccountUserInputVarName is set only if PtllaCBU* is of type TEXT
    // So if the type is COMBO, the normal "required" validation is used.
    if (bankAccountUserInputVarName) {
      return bankAccountData !== undefined;
    }
    return true;
  };

  const preloadPrepaidCardValue = (prepaidCardDefaultValue) => {
    try {
      const defaultValueMap = {};
      prepaidCardDefaultValue.split('@@').forEach((item) => {
        const [itemKey, itemValue] = item.split(':');
        defaultValueMap[itemKey] = itemValue;
      });
      setPrepaidCardData({
        province: defaultValueMap.province,
        district: defaultValueMap.city,
        location: defaultValueMap.neighborhood,
        street: defaultValueMap.street,
        number: defaultValueMap.number,
        zipCode: defaultValueMap.zipCode,
        floor: defaultValueMap.floor,
        dpto: defaultValueMap.dpto,
        additionalData: defaultValueMap.additionalData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const resolveProvinceListForPrePaidCardCombo = async () => {
    try {
      const response = await getProvinces();
      setProvinceList(response.data);
    } catch (error) {
      console.log(error);
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const resolveIfOtherFieldsDependsOnMe = async (inputVar, group, inputComboSelected) => {
    if (inputVar.name === 'PtllaModoDesembolso') {
      setBankAccountData(undefined);
      setBankAccountErrorMessage(undefined);
      setBankAccountUserInputVarName(undefined);
      if (inputVar.userInputOptions.hintAction && userFilled[inputVar.name]?.showHint) {
        inputHintAction(inputVar);
      }
    }

    // si hay campos que dependen de mi los resuelvo
    if (inputVar.userInputOptions?.inputsThatDependsOnMe?.length > 0) {
      // recorro todos los campos que están en el mismo grupo
      group.variables.forEach(async (variable) => {
        // si existe una variable que está dentro de los campos que dependen de mi
        if (inputVar.userInputOptions.inputsThatDependsOnMe.indexOf(variable.name) > -1) {
          // reseteo el valor del campo condicionado
          updateUserFilled(variable.name, undefined);

          // el valor a usar depende de si viene por default (o de la db porque ya est seteado) o si
          // se selecciona en la pantalla
          const valueToUse = inputComboSelected?.id || inputVar.userInputOptions.defaultValue;

          // si mi valor no es undefined y (el campo
          // condicional no depende de un valor o tiene un valor que lo condiciona y coincide con mi
          // valor), lo muestro
          if ((inputComboSelected || inputVar.userInputOptions.defaultValue)
            && (!variable.userInputOptions.conditionalUserInputValue
              || (variable.userInputOptions.conditionalUserInputValue
                && variable.userInputOptions.conditionalUserInputValue.includes(valueToUse)))) {
            variable.userInputOptions.isConditionalToShow = true;
          } else {
            variable.userInputOptions.isConditionalToShow = false;
          }
          // si tengo que mostrar el campo condicional y es un combo, voy al backend a resolver la
          // lista de opciones
          if (variable.userInputOptions.isConditionalToShow) {
            if (variable.userInputOptions.type === 'COMBO' || variable.userInputOptions.type === 'TYPEAHEAD'
                || variable.userInputOptions.type === 'RADIO') {
              try {
                const resComboData = await resolveConditionalComboData(hash, variable.name, valueToUse);
                variable.userInputOptions.comboData = resComboData.data;
                updateComboData(variable.name, resComboData.data);

                let comboSelected;
                if (variable.userInputOptions.comboData) {
                  comboSelected = variable.userInputOptions.comboData.find((combo) => combo.selected);
                }
                if (!comboSelected && variable.userInputOptions.comboData?.length === 1) {
                  [comboSelected] = variable.userInputOptions.comboData;
                  updateUserFilled(variable.name, comboSelected);
                  resolveIfOtherFieldsDependsOnMe(variable, group, comboSelected);
                } else {
                  updateUserFilled(variable.name, comboSelected);
                }
              } catch (error) {
                console.log(error);
                setErrorMessage(getResponseErrorOrDefault(error));
              }
            } else if (variable.userInputOptions.type === 'TEXT') {
              if (variable.name.startsWith('PtllaCBU')) {
                setBankAccountUserInputVarName(variable.name);
              }
              // Resuelvo si se le aplica un valor automaticamente que proviene de la opcion
              // seleccionada del combo que lo condiciona
              // para simplificar y como solo tengo este caso de uso lo hago solo con TEXT
              setTimeout(() => {
                // inicializo con el valor por defecto
                if (userFilled[inputVar.name]?.descripcion === 'CAPITAL FEDERAL') {
                  // fix horrible. El tema es que en sapp el codigo postal de Cpaital
                  // Federal esta en 1000 y no qiero que setee
                  updateUserFilled(variable.name, variable.userInputOptions.defaultValue);
                  return;
                }
                updateUserFilled(variable.name, userFilled[inputVar.name]?.valueToAutoCompleteConditiningInput);
              });
            }
          }
        }
      });
    }
  };

  const getComboDataSelectedAndSetIfSingleOption = (variable) => {
    let comboSelected;
    try {
      if (variable.userInputOptions.comboData) {
        comboSelected = variable.userInputOptions.comboData.find((combo) => combo.selected);
      }
      if (!comboSelected && variable.userInputOptions.comboData?.length === 1
        && variable.userInputOptions.required) {
        [comboSelected] = variable.userInputOptions.comboData;
      }
      updateUserFilled(variable.name, comboSelected);
    } catch (error) {
      console.log(error);
      setErrorMessage(getResponseErrorOrDefault(error));
    }
    return comboSelected;
  };

  const resolveField = (resInputGroups) => {
    try {
      resInputGroups.forEach((group) => {
        group.variables.forEach((variable) => {
          if (variable.userInputOptions.type === 'COMBO' || variable.userInputOptions.type === 'TYPEAHEAD'
            || variable.userInputOptions.type === 'RADIO') {
            if (variable.userInputOptions.comboData) {
              variable.userInputOptions.comboData.forEach((comboOption) => {
                if (variable.name === 'PtllaFormasDePago' && comboOption.id === '3') {
                  comboOption.selected = true;
                }
              });
              updateComboData(variable.name, variable.userInputOptions.comboData);
            }
            const comboSelected = getComboDataSelectedAndSetIfSingleOption(variable);
            resolveIfOtherFieldsDependsOnMe(variable, group, comboSelected);
          } else if (variable.userInputOptions.type === 'CHECK') {
            updateUserFilled(variable.name, variable.userInputOptions.defaultValue === 'true');
          } else if (variable.userInputOptions.type === 'TEXT'
             || variable.userInputOptions.type === 'TEXT_AREA'
             || variable.userInputOptions.type === 'PREPAID_CARD') {
            updateUserFilled(variable.name, variable.userInputOptions.defaultValue);
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const resolveUserInputFields = async (loan) => {
    try {
      const resInputGroups = [];
      const response = await getUserInputFields(hash);
      response.data.groups.forEach((inputGroup) => {
        let result = false;
        inputGroup.variables.forEach((variable) => {
          if (!result) {
            if ((!variable.userInputOptions.mustLoadInputInOtherStep || loan.disableMustLoadInputInOtherStep)
              && !variable.userInputOptions.mustHideForBusinessPurpose) {
              result = true;
            } else if (loan.inputToCompleted.length !== 0 && loan.inputToCompleted.includes(variable.name)
              && !variable.userInputOptions.mustHideForBusinessPurpose) {
              result = true;
            }
          }
          if (variable.name === 'PtllaTarjetaDebito' && variable.userInputOptions.defaultValue) {
            setDC({ type: resolveCreditCardType(variable.userInputOptions.defaultValue) });
          }
          if (variable.name === 'PtllaDomicilioTarjetaPrepaga' && variable.userInputOptions.defaultValue) {
            preloadPrepaidCardValue(variable.userInputOptions.defaultValue);
          }
          if (variable.name === 'PtllaModoDesembolso' && variable.userInputOptions.comboData
            && variable.userInputOptions.comboData.find((option) => option.id === 'PREPAID_CARD')) {
            resolveProvinceListForPrePaidCardCombo();
          }
        });
        if (result) {
          resInputGroups.push(inputGroup);
        }
      });
      resolveField(resInputGroups);
      setGroupSelected(resInputGroups[0]);
      setInputGroups(resInputGroups);
    } catch (error) {
      console.log(error);
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const setReminder = () => {
    try {
      reminder(hash);
    } catch (error) {
      console.log(error);
    }
  };

  const resolvePrepaidCardLastFour = async () => {
    try {
      const response = await getPrepaidCardLastFour(hash);
      if (response && response.data) {
        setPrepaidCardLastFour(response.data);
        // por el momento debo setearlo así, pero no debería pedir el dato si quiera si ya tiene Tarejta Credicuotas
        updateUserFilled('PtllaDomicilioTarjetaPrepaga', PREPAID_CARD_ADDRESS_NOT_APPLY);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resolveCardinalAssistance = async () => {
    try {
      const response = await getCardinalAssistanceInfo(hash);
      if (response && response.data !== '' && response.data.assistanceCanBeOffered
          && !response.data.assistanceRequested && !response.data.assistanceActive) {
        setCardinalAssistanceInfo({
          cardinalAssistanceEnabled: true,
          customerDebitCardLastFour: response.data.customerDebitCard.substr(-4),
          cardinalAssistancePolicyAmount: response.data.policyAmount,
        });
        setCardinalAssistanceDebitCardCheck(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addPaymentModeVoluntary = async () => {
    const comboPaymentVoluntary = {
      selected: false,
      showHint: true,
      valueToAutoCompleteConditiningInput: null,
      descripcion: 'Pagofacil/Rapipago/Otros',
      subDescripcion: null,
      id: '2',
    };

    const resInputGroups = Object.assign(inputGroups);
    resInputGroups.forEach((inputGroup) => {
      if (inputGroup.name === 'Forma de Pago') {
        inputGroup.variables.forEach((variable) => {
          if (variable.name === 'PtllaFormasDePago') {
            variable.userInputOptions.comboData.push(comboPaymentVoluntary);
          }
        });
      }
    });
    resolveField(resInputGroups);
    setInputGroups(resInputGroups);
  };

  const init = async () => {
    setLoading(true);
    try {
      setEnableCodeInputByUrlParam(queryParams.get('eci') === '1');
      const loanRequestResponse = await getLoanRequest(hash);
      const loan = loanRequestResponse.data;
      if (loan) {
        setReminder();
        setLoanRequest(loan);
        sendDataLayerCustomerInfo(loan);
        resolveUserInputFields(loan);
        resolvePrepaidCardLastFour();
        resolveCardinalAssistance();
        if (!isEcommerceCartLoan(loan)) {
          loadWebchat(loan);
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const getInputGroupVariableByName = (name) => {
    let inputVar;
    inputGroups.find((g) => {
      const inputVarTemp = g.variables.find((v) => v.name === name);
      if (inputVarTemp) {
        inputVar = inputVarTemp;
      }
      return inputVarTemp;
    });
    return inputVar;
  };

  const handleChange = async (variable, value) => {
    updateUserFilled(variable.name, value);
    if (['COMBO', 'RADIO', 'TYPEAHEAD'].includes(variable.userInputOptions.type)) {
      const group = inputGroups.find((g) => g.name === variable.group);
      if (group) {
        await resolveIfOtherFieldsDependsOnMe(variable, group, value);
      }
    }
  };

  const resolvePrepaidCardValue = () => {
    if (prepaidCardLastFour) {
      return PREPAID_CARD_ADDRESS_NOT_APPLY;
    }

    let prepaidCardValue = `province:${prepaidCardData.province}`;
    prepaidCardValue += `@@city:${prepaidCardData.district}`;
    prepaidCardValue += `@@neighborhood:${prepaidCardData.location}`;
    prepaidCardValue += `@@street:${prepaidCardData.street}`;
    prepaidCardValue += `@@number:${prepaidCardData.number}`;
    prepaidCardValue += `@@zipCode:${prepaidCardData.zipCode}`;
    if (prepaidCardData.floor?.toString().trim() !== '') {
      prepaidCardValue += `@@floor:${prepaidCardData.floor}`;
    }
    if (prepaidCardData.dpto?.toString().trim() !== '') {
      prepaidCardValue += `@@dpto:${prepaidCardData.dpto}`;
    }
    if (prepaidCardData.additionalData?.toString().trim() !== '') {
      prepaidCardValue += `@@additionalInfo:${prepaidCardData.additionalData}`;
    }

    return prepaidCardValue;
  };

  const isType = (name, type) => getInputGroupVariableByName(name)?.userInputOptions.type === type;

  const convertMapToObject = () => {
    let result = '';
    const keys = Object.keys(userFilled);
    keys.forEach((userInputName) => {
      if (userFilled[userInputName] !== undefined && userFilled[userInputName] !== null) {
        let valueAsString = userFilled[userInputName];
        if (isType(userInputName, 'DATE')) {
          valueAsString = userFilled[userInputName].toISOString();
        }
        if (isType(userInputName, 'COMBO') || isType(userInputName, 'TYPEAHEAD') || isType(userInputName, 'RADIO')) {
          valueAsString = userFilled[userInputName].id;
        }
        result = `${result}"${userInputName}":"${valueAsString}",`;
      }
    });
    return JSON.parse(`{${result.substring(0, result.length - 1)}}`);
  };

  const clearShowReloadValidField = () => {
    inputGroups.forEach((group) => {
      group.variables.forEach((variable) => {
        if (variable.userInputOptions.showReloadValidField) {
          variable.userInputOptions.showReloadValidField = false;
        }
      });
    });
  };

  const acceptCardinalContract = async () => {
    if (cardinalAssistanceDebitCardCheck
      && cardinalAssistanceInfo.cardinalAssistanceEnabled
      && userFilled.PtllaFormasDePago.id === '3'
      && userFilled.PtllaModoDesembolso) {
      try {
        await saveAdditionalProductRequest(hash);
      } catch (error) {
        console.log('Error al intentar grabar el producto');
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const refreshLoan = async () => {
    try {
      const loanRequestResponse = await getLoanRequest(hash);
      const loan = loanRequestResponse.data;
      if (loan) {
        setLoanRequest(loan);
        loanRedirect(loan, navigate, queryParams.toString());
      }
    } catch (error) {
      console.log('Error al recuperar la solicitud');
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const getInvalidInputs = () => {
    const isInvalid = [];
    // recorrer las variables, si son requeridas, buscar en el userFilled si esta cargada.
    inputGroups.forEach((group) => {
      group.variables.forEach((variable) => {
        // Que no sea una variable que esté oculta o que no se deba mostrar o que no sea 'COMBO', 'RADIO', 'TEXT', 'CARD'
        // CARD no la evaluo ocmo inválida porque no se carga en el userFilled, quedo grabado en el LR cuando se valida la TD
        if (variable.userInputOptions.required && resolveToShowConditional(variable)) {
          if (((['COMBO', 'RADIO'].includes(variable.userInputOptions.type)) && !mustHidenIfUniqueResult(variable))
              || (variable.userInputOptions.type === 'TEXT' && !shouldHideInputGroupVariable(variable))
              || (!['COMBO', 'RADIO', 'TEXT', 'CARD', 'PREPAID_CARD'].includes(variable.userInputOptions.type))
          ) {
            const value = userFilled[variable.name];
            if (value === undefined || value === null || (value.toString()).trim() === '') {
              isInvalid.push(variable.name);
            }
          }
        }
        if (variable.name === 'PtllaDomicilioTarjetaPrepaga' && variable.userInputOptions.defaultValue === null
          && userFilled.PtllaModoDesembolso?.id === 'PREPAID_CARD'
          && userFilled.PtllaDomicilioTarjetaPrepaga !== 'PREPAID_CARD_ADDRESS_NOT_APPLY'
          && prepaidCardLastFour === undefined
        ) {
          if ((prepaidCardData.province === undefined || prepaidCardData.province.toString().trim() === '')
            && (prepaidCardData.district === undefined || prepaidCardData.district.toString().trim() === '')
            && (prepaidCardData.location === undefined || prepaidCardData.location.toString().trim() === '')
            && (prepaidCardData.street === undefined || prepaidCardData.street.toString().trim() === '')
            && (prepaidCardData.number === undefined || prepaidCardData.number.toString().trim() === '')
            && (prepaidCardData.zipCode === undefined || prepaidCardData.zipCode.toString().trim() === '')) {
            isInvalid.push('PtllaDomicilioTarjetaPrepaga');
          }
        }
      });
    });
    return isInvalid;
  };

  const updateInputData = async () => {
    const invalidInputs = getInvalidInputs();
    if (invalidInputs.includes('PtllaModoDesembolso')) {
      setErrorMessage('Por favor completá cómo querés recibir el préstamo');
    } else if (!isValidBankAccountKey()) {
      setErrorMessage('Por favor completá correctamente y verificá el campo con tu CBU/CVU o Alias');
    } else if (invalidInputs.length > 0) {
      setTurnOnValidations(true);
      setErrorMessage('Por favor completá correctamente los campos indicados en rojo');
    } else {
      try {
        const customerDataObj = convertMapToObject();
        if (customerDataObj.PtllaModoDesembolso === 'PREPAID_CARD') {
          customerDataObj.PtllaDomicilioTarjetaPrepaga = resolvePrepaidCardValue();
        }
        sendDataLayerEventObject({ event: 'fin_carga_datos' });
        clearShowReloadValidField();
        setUpdateInputDataLoading(true);
        await setCustomerData(loanRequest.hashKey, customerDataObj);
        await acceptCardinalContract();
        await refreshLoan();
      } catch (error) {
        if (error.response?.data?.errorCode === 'ERROR_GENERATING_PREPAID_LOANS') {
          setShowPrepaidProblems(true);
        } else {
          setErrorMessage(getResponseErrorOrDefault(error));
        }
      } finally {
        setUpdateInputDataLoading(false);
      }
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Box className="main-container-body" mb={5}>
        <Grid container className="customer-data-main-container" display="flex" justifyContent="center">
          <Grid item xs={12} sm={12} md={10} lg={10} style={{ padding: '0px 15px' }}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <OnboardingHeader loan={loanRequest} />

                {getEnv(ENVIRONMENT) !== 'PRD' && (
                  <Grid container alignItems="center" style={{ marginTop: '16px' }}>
                    <Grid item xs={12}>
                      <Link
                        href={`${getEnv(PREVIOUS_ORIGINATION_ONBOARDING_URL)}customerData/${hash}`}
                        sx={{
                          textTransform: 'none',
                          fontSize: '13px',
                          fontFamily: 'Open sans',
                          padding: '0px',
                          display: 'contents',
                        }}
                      >
                        ORIG-CUSTOMER-DATA
                      </Link>
                    </Grid>
                  </Grid>
                )}

                {showPrepaidProblems && (
                  <>
                    <Box mt={3} />
                    <Grid container>
                      <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Grid container rowSpacing={3} justifyContent="center">
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className="customer-data-prepaid-problems">
                              {info.showPrepaidProblems}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                              variant="primary"
                              className="btn-primary"
                              type="button"
                              onClick={() =>
                                navigate({
                                  pathname: routes.preOnboardingPersonalLoan,
                                  search: `?${queryParams.toString()}`,
                                })}
                              fullWidth
                            >
                              {info.newLoanCTA}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box mt={6} />
                  </>
                )}

                {!showPrepaidProblems && loanRequest && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        {inputGroups && inputGroups.map((inputGroup) => (
                          <>
                            {(getIndexOfInputGroup(inputGroup) <= getIndexOfInputGroup(groupSelected)) && (
                              <>
                                <Box mt={6} />
                                <Typography className="customer-data-title">
                                  {loanRequest.productId === 46 && inputGroup.name === info.revolvingLineTitleOrig
                                    ? info.revolvingLineTitleTranslated
                                    : inputGroup.nameTranslated ? inputGroup.nameTranslated : inputGroup.name}
                                </Typography>
                                <Box mt={4} />

                                <Grid container justifyContent="center" spacing={3}>
                                  {inputGroup.variables && inputGroup.variables.map((variable) => (
                                    <>

                                      {variable.userInputOptions.type === 'TEXT'
                                        && resolveToShowConditional(variable)
                                        && !shouldHideInputGroupVariable(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputText
                                              variable={variable}
                                              handleChange={handleChange}
                                              userFilled={userFilled}
                                              updateUserFilled={updateUserFilled}
                                              loanRequest={loanRequest}
                                              showVerificationCbu={showVerificationCbu}
                                              setShowVerificationCbu={setShowVerificationCbu}
                                              accountPreError={accountPreError}
                                              setAccountPreError={setAccountPreError}
                                              accountPreValid={accountPreValid}
                                              setAccountPreValid={setAccountPreValid}
                                              bankAccountData={bankAccountData}
                                              setBankAccountData={setBankAccountData}
                                              bankAccountErrorMessage={bankAccountErrorMessage}
                                              setBankAccountErrorMessage={setBankAccountErrorMessage}
                                              setBankAccountUserInputVarName={setBankAccountUserInputVarName}
                                              enableCodeInputByUrlParam={enableCodeInputByUrlParam}
                                              setErrorMessage={setErrorMessage}
                                              inputHintAction={inputHintAction}
                                              turnOnValidations={turnOnValidations}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'TEXT_AREA'
                                        && resolveToShowConditional(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputTextArea
                                              variable={variable}
                                              handleChange={handleChange}
                                              userFilled={userFilled}
                                              turnOnValidations={turnOnValidations}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'CHECK'
                                        && resolveToShowConditional(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputCheck
                                              variable={variable}
                                              handleChange={handleChange}
                                              userFilled={userFilled}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'COMBO'
                                        && resolveToShowConditional(variable)
                                        && !mustHidenIfUniqueResult(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputCombo
                                              variable={variable}
                                              handleChange={handleChange}
                                              userFilled={userFilled}
                                              comboData={comboData}
                                              inputHintAction={inputHintAction}
                                              turnOnValidations={turnOnValidations}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'TYPEAHEAD'
                                        && resolveToShowConditional(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputTextAutocomplete
                                              variable={variable}
                                              handleChange={handleChange}
                                              userFilled={userFilled}
                                              comboData={comboData}
                                              turnOnValidations={turnOnValidations}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'RADIO'
                                        && resolveToShowConditional(variable)
                                        && !mustHidenIfUniqueResult(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputRadio
                                              variable={variable}
                                              handleChange={handleChange}
                                              userFilled={userFilled}
                                              isRevolvingLineLoan={loanRequest.productId === 46}
                                              inputHintAction={inputHintAction}
                                              turnOnValidations={turnOnValidations}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'CARD'
                                        && resolveToShowConditional(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputCard
                                              variable={variable}
                                              setShowContinueBtn={setShowContinueBtn}
                                              resolveCardinalAssistance={resolveCardinalAssistance}
                                              addPaymentModeVoluntary={addPaymentModeVoluntary}
                                              dc={dc}
                                              setDC={setDC}
                                              loanRequest={loanRequest}
                                            />
                                          </Grid>
                                      )}

                                      {variable.userInputOptions.type === 'PREPAID_CARD'
                                        && resolveToShowConditional(variable) && (
                                          <Grid
                                            id={variable.name}
                                            item
                                            xs={12}
                                            sm={variable.userInputOptions.subDivision ? 6 : 12}
                                          >
                                            <CustomerDataInputPrepaidCard
                                              provinceList={provinceList}
                                              prepaidCardData={prepaidCardData}
                                              setPrepaidCardData={setPrepaidCardData}
                                              prepaidCardLastFour={prepaidCardLastFour}
                                              turnOnValidations={turnOnValidations}
                                            />
                                          </Grid>
                                      )}

                                    </>
                                  ))}
                                </Grid>

                                <Box mt={4} />

                                {!isLastInputGroup(inputGroup) && inputGroup.name === groupSelected.name && (
                                  <Button
                                    variant="primary"
                                    className="btn-primary"
                                    type="button"
                                    fullWidth
                                    onClick={() => setNextGroupSelected(inputGroup)}
                                  >
                                    {info.nextCTA}
                                  </Button>
                                )}

                                {isLastInputGroup(inputGroup) && shouldShowContinueButton() && (
                                  <>
                                    {userFilled.PtllaModoDesembolso && userFilled.PtllaFormasDePago && userFilled.PtllaFormasDePago.id === '3'
                                    && cardinalAssistanceInfo && (
                                      <>
                                        <CustomerDataInputCardinalAssistance
                                          cardinalData={cardinalAssistanceInfo}
                                          cardinalAssistanceDebitCardCheck={cardinalAssistanceDebitCardCheck}
                                          setCardinalAssistanceDebitCardCheck={setCardinalAssistanceDebitCardCheck}
                                        />
                                        <Box mt={4} />
                                      </>
                                    )}
                                    <Button
                                      variant="primary"
                                      className="btn-primary"
                                      type="button"
                                      loading={updateInputDataLoading}
                                      fullWidth
                                      onClick={updateInputData}
                                    >
                                      {info.submitCTA}
                                    </Button>
                                  </>
                                )}

                                <Box mt={6} />
                              </>
                            )}

                          </>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}

              </>
            )}
          </Grid>
        </Grid>
        {errorMessage && (
          <Toast
            messageOnError={errorMessage}
            open
            onClose={() => setErrorMessage(undefined)}
          />
        )}
      </Box>
      <SimpleFooter />
    </>
  );
};

export default OnboardingCustomerData;
