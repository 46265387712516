import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

const CardImage = ({ image, description }) => (
  <>
    <Grid container display="flex" alignItems="center">
      <Grid item xs={3} sm={3} md={4}>
        <img className="card-image-icon" src={image} alt="selfie" />
      </Grid>
      <Grid item xs={9} sm={9} md={8}>
        <Typography className="card-image-description">{description}</Typography>
      </Grid>
    </Grid>
  </>
);

CardImage.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CardImage;
