import { Grid, Typography } from '@mui/material';
import '../assets/css/onboardingFinal.css';
import heardOnHandImg from '../assets/imgs/heart-on-hand.svg';
import i18n from '../common/i18n';

const OnboardingFinalDismiss = () => (
  <>
    {/* ********** notifyDismissActionMessage ********** */}
    <Grid container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Grid item>
        <Typography className="onboarding-final-title">
          {i18n.OnboardingFinal.notifyDismissMsgOne}
        </Typography>
      </Grid>
    </Grid>
    <Grid container style={{ marginTop: '40px', marginBottom: '120px' }}>
      <Grid item xs={3} sm={2}>
        <img src={heardOnHandImg} alt="heart-hand" width="55px" />
      </Grid>
      <Grid item xs={9} sm={10}>
        <Typography className="onboarding-final-subtitle-long">
          {i18n.OnboardingFinal.notifyDismissMsgTwo}
        </Typography>
      </Grid>
    </Grid>
  </>
);

export default OnboardingFinalDismiss;
