import { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Button, Checkbox, FormGroup, Link,
} from '@doit/pcnt-react-ui-library';
import { Box, Grid, Typography } from '@mui/material';
import i18n from '../common/i18n';
import { fillMessageWith, isEcommerceCartLoan, isMobileOperatingSystem } from '../utils/functionsUtil';
import SummaryDetailCard from './SummaryDetailCard';
import SummaryDisbursement from './SummaryDisbursement';
import SummaryPaymentMethod from './SummaryPaymentMethod';
import SummarySaleCompletionWelcome from './SummarySaleCompletionWelcome';
import { CREDICUOTAS_SELFIE_BACKEND_URL, getEnv } from '../api/env';
import SummaryFooter from './SummaryFooter';

const SummaryTermsAndConditionsMode = ({
  loanRequest, summaryData, prepaidCardLastDigits, signAgreement, signLoading,
}) => {
  const [checked, setChecked] = useState(false);

  const getAgreementActions = () => (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <FormGroup className="summary-terms-and-conditions">
          <Checkbox
            label={i18n.SummaryTermsAndConditionsMode.agreement}
            size="small"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </FormGroup>
        <Link
          size="small"
          underline="always"
          className="summary-terms-and-conditions-agreement"
          href={`${getEnv(CREDICUOTAS_SELFIE_BACKEND_URL)}/onboarding/download/${loanRequest.hashKey}/documentation`}
          target="_blank"
        >
          {i18n.SummaryTermsAndConditionsMode.showAgreement}
        </Link>
      </Box>
      <Box mt={3} width="100%">
        <Button
          variant="primary"
          className="btn-primary"
          fullWidth
          onClick={signAgreement}
          disabled={!checked}
          loading={signLoading}
        >
          {i18n.OnboardingSummary.signCTA}
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <Grid md={1} />
      <Grid item xs={12} md={6} pt={10} pl={4} pr={4}>
        <Typography className="summary-title" variant="h1">
          {isEcommerceCartLoan(loanRequest) ? i18n.OnboardingSummary.title[1] : i18n.OnboardingSummary.title[0]}
        </Typography>
        <Typography className="summary-description">
          {parse(fillMessageWith(i18n.OnboardingSummary.description, loanRequest.customerName))}
        </Typography>
        <Box mt={3} />
        <SummaryDetailCard
          loanRequest={loanRequest}
          summaryData={summaryData}
        />
        <Box mt={3} />
        <SummaryDisbursement
          loanRequest={loanRequest}
          summaryData={summaryData}
          prepaidCardLastDigits={prepaidCardLastDigits}
        />
        <Box mt={3} />
        <SummaryPaymentMethod
          loanRequest={loanRequest}
          summaryData={summaryData}
        />
        <SummarySaleCompletionWelcome
          loanRequest={loanRequest}
        />
        <hr style={{ border: '1px solid #F5F5F5', marginTop: '30px' }} />
        {!isMobileOperatingSystem() && (
          <>
            <Box pt={3} pb={3}>
              {getAgreementActions()}
            </Box>
            <hr style={{ border: '1px solid #F5F5F5' }} />
          </>
        )}
        <SummaryFooter loanRequest={loanRequest} summaryData={summaryData} />
      </Grid>
      <Grid md={5} />
      {isMobileOperatingSystem() && (
        <Box className="summary-terms-and-conditions-cta-container">
          <Box pl={2} pr={2} pt={3} pb={3} display="flex" flexDirection="column" alignItems="center">
            {getAgreementActions()}
          </Box>
        </Box>
      )}
    </>
  );
};

SummaryTermsAndConditionsMode.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
  prepaidCardLastDigits: PropTypes.string.isRequired,
  signAgreement: PropTypes.func.isRequired,
  signLoading: PropTypes.bool.isRequired,
};

export default SummaryTermsAndConditionsMode;
