import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import Loading from '../components/commons/Loading';
import i18n from '../common/i18n';
import { getHashLoanByMinifyId } from '../api/onboardingService';
import { CREDICUOTAS_SELFIE_BACKEND_URL, getEnv } from '../api/env';
import { routes } from '../constants/routes';

const OnboardingDownloadDisclaimer = () => {
  const { minHash } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const init = async () => {
    try {
      const res = await getHashLoanByMinifyId(minHash);
      const hash = res.data.hashKey;
      window.open(`${getEnv(CREDICUOTAS_SELFIE_BACKEND_URL)}/onboarding/download/${hash}/documentation`, '_self');
    } catch (error) {
      navigate({
        pathname: routes.preOnboardingPersonalLoan,
        search: `?${queryParams.toString()}`,
      });
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <Box mt={20}>
      <Loading height="20px" />
      <Typography marginTop={5}>{i18n.OnboardingDownloadDisclaimer.message}</Typography>
    </Box>
  );
};

export default OnboardingDownloadDisclaimer;
