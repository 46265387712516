import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import { getLoanRequest, reminder, saveMobileUserAgent } from '../api/onboardingService';
import { getResponseErrorOrDefault, isMobileOperatingSystem, sendDataLayerCustomerInfo } from '../utils/functionsUtil';
import Toast from '../components/commons/Toast';
import i18n from '../common/i18n';
import Greetings from '../components/InstallmentPlanGreetings';
import DualOffer from '../components/DualOffer';
import SimpleOffer from '../components/SimpleOffer';
import SimpleFooter from '../components/SimpleFooter';
import { loadWebchat } from '../components/webchatComponentScript';
import NotificationSubscription from '../components/NotificationSubscription';
import { hasPermissionGrantedOrDenied, requestPermission } from '../utils/firebase';

const OnboardingInstallmentPlan = () => {
  const { hash } = useParams();
  const [loanRequest, setLoanRequest] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showPushNotificationMessage, setShowPushNotificationMessage] = useState(false);

  const handleCancelSubscription = () => {
    setShowPushNotificationMessage(false);
  };

  const handleSubscribe = () => {
    setShowPushNotificationMessage(false);
    requestPermission(hash);
  };

  const isPushNotificationOn = () =>
    isMobileOperatingSystem();

  const init = async () => {
    if (!hasPermissionGrantedOrDenied()) {
      setTimeout(() => {
        setShowPushNotificationMessage(true);
      }, 8000);
    }
    try {
      saveMobileUserAgent(hash, window.navigator.userAgent);
      await reminder(hash);
    } catch (error) {
      console.log(error);
    }
    try {
      const loanRequestResponse = await getLoanRequest(hash);
      const lr = loanRequestResponse.data;
      sendDataLayerCustomerInfo(lr);
      setLoanRequest(lr);
      loadWebchat(lr);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {/* PopUp mensaje de subscripcion a notificaciones */}
      {isPushNotificationOn() && showPushNotificationMessage && (
        <NotificationSubscription
          onCancel={handleCancelSubscription}
          onSubscribe={handleSubscribe}
          message={i18n.NotificationSubscription.messageForSpecialBenefits}
        />
      )}
      <Box className="main-container-body">
        <Grid container className="pre-onboarding-main-container">
          <Grid item xs={1} sm={1} md={1} />
          <Grid item xs={10} sm={10} md={10}>
            {loanRequest && (
              <>
                {/* ********** Componente saludo ********** */}
                <Greetings loanRequest={loanRequest} />
                <Box mt={2} />
              </>
            )}
            {/* ********** Componente oferta ********** */}
            {loanRequest && (!loanRequest.isOfferDual
              || (loanRequest.isOfferDual && loanRequest.installmentsNumberSelected)) && (
                <SimpleOffer loanRequest={loanRequest} />
            )}
            {/* ********** Componente oferta dual ********** */}
            {(loanRequest && loanRequest.isOfferDual && !loanRequest.installmentsNumberSelected) && (
              <DualOffer loanRequest={loanRequest} />
            )}
          </Grid>
        </Grid>
        {errorMessage && (
          <Toast
            messageOnError={errorMessage}
            open
            onClose={() => setErrorMessage(undefined)}
          />
        )}
      </Box>
      <SimpleFooter />
    </>
  );
};

export default OnboardingInstallmentPlan;
