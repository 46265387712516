import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Button, Img, NumberInput } from '@doit/pcnt-react-ui-library';
import moment from 'moment';
import { addProductToPayBill } from '../api/onboardingService';
import { getResponseErrorOrDefault, fillMessageWith } from '../utils/functionsUtil';
import { moneyFormatterWithoutDecimals, moneyFormatter } from '../utils/formatterUtil';
import imgExclamation from '../assets/imgs/Exclamation.svg';
import i18n from '../common/i18n';
import '../assets/css/payServices.css';
import { loanRedirect } from './LoanRequestRedirectService';

/* eslint arrow-body-style:off */
const PayServicesBillList = ({
  loan,
  offer,
  selectedServiceCompany,
  paymentBillDetailList,
  serviceSelected,
  serviceFormFilled,
  setErrorMessage,
}) => {
  const maxAmount = offer?.maxAmount;
  const minAmount = offer?.installments[0]?.minimumAmount;
  const info = i18n.PRODUCT_LOAD.PAY_SERVICE;

  const [loading, setLoading] = useState();
  const [paymentBillDetailABISelected, setPaymentBillDetailABISelected] = useState();
  const [amountToPay, setAmountToPay] = useState();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const showInputAmountForFetchedBill = (paymentBillDetail) => {
    setPaymentBillDetailABISelected(paymentBillDetail);
  };

  const refreshLoanRequest = () => {
    loanRedirect(loan, navigate, queryParams.toString());
  };

  const getFieldDescription = (field) => {
    const inputData = serviceSelected.form.find((element) => element.field === field);
    return inputData ? inputData.label : field;
  };

  const billListServiceFormFilledLoop = () => (
    Object.keys(serviceFormFilled).map((key) => (
      <Typography className="capitalize" key={key}>
        {`${getFieldDescription(key).toLowerCase()}: ${serviceFormFilled[key]}`}
      </Typography>
    ))
  );

  const addProductToPayFetchedBill = async (paymentBillDetail) => {
    /* Por default se toma el maxAmount, pero si se ingreso un monto a pagar
    (por ejemplo para el caso de ABI, se toma el monto ingresado) */
    let amountToPayTemp = paymentBillDetail.maxAmount1;
    if (amountToPay) {
      amountToPayTemp = amountToPay;
    }
    // Se verifica que el monto a pagar este dentro del rango ofrecido por el credito
    if (amountToPayTemp < minAmount || amountToPayTemp > maxAmount) {
      setErrorMessage(fillMessageWith(info.billListAddProductToPayAmountLimits, minAmount, maxAmount));
    } else {
      const data = {
        companyCode: paymentBillDetail.companyCode,
        companyName: paymentBillDetail.companyName,
        paymentModeId: paymentBillDetail.paymentModeId,
        paymentType: serviceSelected.paymentType,
        form: {
          barcode: paymentBillDetail.barcode,
          amount: amountToPayTemp,
          amountType: paymentBillDetail.amountType,
          clientId: paymentBillDetail.clientId,
          reference: paymentBillDetail.reference,
          additionalData: paymentBillDetail.additionalData,
          searchForm: serviceFormFilled,
          expirationDate: paymentBillDetail.expirationDate,
          hash: paymentBillDetail.hash,
          type: paymentBillDetail.type,
          additionalParamList: null,
        },
      };

      try {
        setLoading(true);
        await addProductToPayBill(loan.hashKey, data);
        await refreshLoanRequest();
      } catch (error) {
        setErrorMessage(getResponseErrorOrDefault(error));
      } finally {
        setLoading(false);
      }
    }
  };

  const addImportAndAddProductToPayFetchedBill = () => {
    // se valida que el monto ingresado este dentro de los maximos y minimos establecidos por el servicio
    if (!amountToPay || amountToPay > paymentBillDetailABISelected.maxAmount1
      || amountToPay < paymentBillDetailABISelected.minAmount1) {
      setErrorMessage(info.billAmountInputError);
    } else {
      addProductToPayFetchedBill(paymentBillDetailABISelected);
    }
  };

  return (
    <>
      { /* LISTADO DE FACTURAS */}
      { /* Muestra el listado de facturas para el servicio ingresado,
                   por ejemplo las facturas de impuesto automotor */}
      {
        selectedServiceCompany && paymentBillDetailList && !paymentBillDetailABISelected && (
          <Grid item>
            <Typography className="services-bill-title">{info.billListTitle}</Typography>
            <Typography className="services-input-label">{info.billListLabel}</Typography>
            <Box mt={3} />
            {paymentBillDetailList.length === 0 && (
              <Grid item className="services-warning">
                <Img
                  src={imgExclamation}
                  alt={info.notServiceFoundImgAlt}
                />
                <Typography>{info.billListNotFoundText}</Typography>
              </Grid>
            )}
            {paymentBillDetailList.length > 0 && paymentBillDetailList.map((paymentBillDetail) => (
              <Grid item key={paymentBillDetail.reference}>
                {paymentBillDetail.amountType !== 'ABI' && (
                  <Grid
                    className="services-card"
                    onClick={() => addProductToPayFetchedBill(paymentBillDetail)}
                  >
                    <Typography className="capitalize">
                      {paymentBillDetail.companyName.toLowerCase()}
                    </Typography>
                    {paymentBillDetail.amount2 && paymentBillDetail.amount2 !== 0 && (
                      <Typography className="capitalize">
                        {fillMessageWith(info.billListAmountImport2,
                          moneyFormatter(paymentBillDetail.amount2))}
                      </Typography>
                    )}
                    {billListServiceFormFilledLoop()}
                    {paymentBillDetail.expirationDate && (
                      <Typography className="capitalize">
                        {fillMessageWith(info.billListExpirationDate,
                          moment(paymentBillDetail.expirationDate).format('DD/MM/YYYY'))}
                      </Typography>
                    )}
                    <Typography className="capitalize">
                      <b>
                        {fillMessageWith(info.billListAmountImport1,
                          moneyFormatter(paymentBillDetail.amount1))}
                      </b>
                    </Typography>
                  </Grid>
                )}
                {paymentBillDetail.amountType === 'ABI' && (
                  <Grid
                    className="services-card"
                    onClick={() => showInputAmountForFetchedBill(paymentBillDetail)}
                  >
                    <Typography className="capitalize">
                      {paymentBillDetail.companyName.toLowerCase()}
                    </Typography>
                    {billListServiceFormFilledLoop()}
                    <Typography className="capitalize">
                      {fillMessageWith(info.billListAmountSuggest,
                        moneyFormatter(paymentBillDetail.amount1))}
                    </Typography>
                    {paymentBillDetail.expirationDate && (
                      <Typography className="capitalize">
                        {fillMessageWith(info.billListExpirationDate,
                          moment(paymentBillDetail.expirationDate).format('DD/MM/YYYY'))}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )
      }
      { /* INGRESO MONTO A PAGAR PARA FACTURAS DE TIPO ABI */}
      {paymentBillDetailABISelected && (
        <>
          <Grid item className="services-details">
            <Typography className="capitalize">
              <b>
                {paymentBillDetailABISelected.companyName.toLowerCase()}
              </b>
            </Typography>
            {billListServiceFormFilledLoop()}
            {paymentBillDetailABISelected.expirationDate && (
              <Typography className="capitalize">
                {fillMessageWith(info.billListExpirationDate,
                  moment(paymentBillDetailABISelected.expirationDate).format('DD/MM/YYYY'))}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography className="services-input-label">{info.billAmountInput}</Typography>
            <Typography className="capitalize">{info.billAmountInputLabel}</Typography>
            <NumberInput
              className="amount-to-pay"
              onChange={(event) => setAmountToPay(event.target.value)}
              fullWidth
            />
            <Typography className="amount-to-pay-hint">
              {fillMessageWith(info.billAmountInputHint,
                moneyFormatterWithoutDecimals(paymentBillDetailABISelected.minAmount1),
                moneyFormatterWithoutDecimals(paymentBillDetailABISelected.maxAmount1))}
            </Typography>
          </Grid>
          <Box mt={4} />
          <Button
            disabled={!amountToPay}
            variant="primary"
            className="btn-primary"
            onClick={addImportAndAddProductToPayFetchedBill}
            loading={loading}
            fullWidth
          >
            {info.btnPay}
          </Button>
        </>
      )}
    </>
  );
};

PayServicesBillList.propTypes = {
  loan: PropTypes.shape(),
  offer: PropTypes.shape(),
  selectedServiceCompany: PropTypes.shape(),
  paymentBillDetailList: PropTypes.shape(),
  serviceSelected: PropTypes.shape(),
  serviceFormFilled: PropTypes.shape(),
  setErrorMessage: PropTypes.func,
};

PayServicesBillList.defaultProps = {
  loan: undefined,
  offer: undefined,
  selectedServiceCompany: undefined,
  paymentBillDetailList: undefined,
  serviceSelected: undefined,
  serviceFormFilled: undefined,
  setErrorMessage: undefined,
};

export default PayServicesBillList;
