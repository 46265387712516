import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { fillMessageWith } from '../utils/functionsUtil';
import i18n from '../common/i18n';

const PreOnboardinLoadData = ({ customer, phone, email }) => (
  <>
    {(customer || phone || email) && (
      <Grid container justifyContent="flex-start" mb={{ md: 4, xs: 2 }}>
        <Grid item xs={12}>
          {customer && (
            <Typography align="left" color="#000" mb={1} fontSize={{ md: 16, xs: 14 }}>
              {parse(fillMessageWith(
                i18n.PreOnboardingLoadData.customer,
                customer.nombrecompleto,
                customer.cuit,
              ))}
            </Typography>
          )}
          {phone && (
            <Typography align="left" color="#000" mb={1} fontSize={{ md: 16, xs: 14 }}>
              {parse(fillMessageWith(i18n.PreOnboardingLoadData.phone, phone))}
            </Typography>
          )}
          {email && (
            <Typography align="left" color="#000" mb={1} fontSize={{ md: 16, xs: 14 }}>
              {parse(fillMessageWith(i18n.PreOnboardingLoadData.email, email))}
            </Typography>
          )}
        </Grid>
      </Grid>
    )}
  </>
);

PreOnboardinLoadData.propTypes = {
  customer: PropTypes.shape(),
  phone: PropTypes.string,
  email: PropTypes.string,
};

PreOnboardinLoadData.defaultProps = {
  customer: undefined,
  phone: undefined,
  email: undefined,
};

export default PreOnboardinLoadData;
