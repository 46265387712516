import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import OnboardingQRCode from './OnboardingQRCode';
import { isAllowedContinueOnDesktop, isMobileOperatingSystem } from '../utils/functionsUtil';
import validationSelfie from '../assets/imgs/validation-selfie.svg';
import DocumentLoadCountDown from './DocumentLoadCountDown';

const PreDocumentLoadRenewer = ({
  loan, onSendMessage, showCountDown, onCountDown, onContinue,
}) => {
  const [QR, setQR] = useState();

  const init = () => {
    setQR(`${window.location.href}?qr=${loan.sappCreditId}`);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Typography className="document-load-renewer-title">
        {parse(i18n.PreDocumentLoadRenewer.title)}
      </Typography>
      <Box mt={3} />
      {/* Se muestra el QR solo en DESKTOP */}
      {!isMobileOperatingSystem() && (
        <>
          {QR && !showCountDown && (
            <OnboardingQRCode value={QR} onClick={onSendMessage} />
          )}
          {showCountDown && (
            <DocumentLoadCountDown onFinish={onCountDown} />
          )}
        </>
      )}

      <Typography className="document-load-subtitle">{i18n.PreDocumentLoadRenewer.subtitle}</Typography>
      <Typography className="document-load-subtitle">{i18n.PreDocumentLoadRenewer.description}</Typography>
      <Box mt={5} />
      <img src={validationSelfie} alt="IMG" width="100px" />
      <Box mt={5} />

      {/* Se muestra el boton de continuar solo si es mobile o si esta encendido en el .env */}
      {(isMobileOperatingSystem() || isAllowedContinueOnDesktop()) && (
        <Button
          variant="primary"
          className="btn-primary"
          type="button"
          fullWidth={isMobileOperatingSystem()}
          onClick={onContinue}
        >
          {i18n.PreDocumentLoadRenewer.continueOnDesktopCTA}
        </Button>
      )}
    </>
  );
};

PreDocumentLoadRenewer.propTypes = {
  loan: PropTypes.shape().isRequired,
  onSendMessage: PropTypes.func.isRequired,
  showCountDown: PropTypes.bool.isRequired,
  onCountDown: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
};

PreDocumentLoadRenewer.defaultProps = {
  onContinue: undefined,
};

export default PreDocumentLoadRenewer;
