import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import TextInputWrapper from './commons/TextInputWrapper';
import { cellphoneSchemaValidation } from '../forms/schemaValidations';
import { sendDataLayerEventObject } from '../utils/functionsUtil';
import NavigationActions from './NavigationActions';

const CellphoneStep = ({
  onSubmit, goBack, loading, currentCellphone,
}) => {
  const methods = useForm({
    resolver: yupResolver(cellphoneSchemaValidation),
    mode: 'onChange',
  });

  const handleSubmit = (data) => {
    sendDataLayerEventObject({ event: 'enviar_codigo' });
    onSubmit(data);
  };

  return (
    <>
      <Typography variant="h1" align="left">{i18n.CellphoneStep.title}</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container justifySelf="left">
            <TextInputWrapper
              name="cellphone"
              defaultValue={currentCellphone}
              label={i18n.CellphoneStep.inputLabel}
              helperText={i18n.CellphoneStep.inputHint}
              inputProps={{ maxLength: 12 }}
              mandatory
              fullWidth
            />
          </Grid>
          <Box mt={4} />
          <NavigationActions
            onContinue={methods.handleSubmit(handleSubmit)}
            onBack={goBack}
            loading={loading}
            disabled={!methods.formState.isValid}
          />
        </form>
      </FormProvider>
    </>
  );
};

CellphoneStep.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  currentCellphone: PropTypes.number,
};

CellphoneStep.defaultProps = {
  currentCellphone: undefined,
};

export default CellphoneStep;
