import * as yup from 'yup';
import i18n from '../common/i18n';

export const dniSchemaValidation = yup.object().shape({
  dni: yup.string().matches(/^[0-9]{6,9}$/,
    { excludeEmptyString: true, message: i18n.schemaValidation.dni.length })
    .required(i18n.schemaValidation.dni.required),
});

export const partnershipCodeSchemaValidation = yup.object().shape({
  code: yup.string().required(i18n.schemaValidation.partnershipCode.required),
});

export const emailSchemaValidation = yup.object().shape({
  email: yup.string().email(i18n.schemaValidation.email.wrongFormat).required(i18n.schemaValidation.email.required),
});

export const cellphoneSchemaValidation = yup.object().shape({
  cellphone: yup.string().matches(/^[0-9]+$/, i18n.schemaValidation.cellphone.wrongFormat)
    .required(i18n.schemaValidation.cellphone.required).min(10, i18n.schemaValidation.cellphone.minLength),
});

export const codeSchemaValidation = yup.object().shape({
  code: yup.string().required(i18n.schemaValidation.code.required),
});

export const onePageSchemaValidation = dniSchemaValidation
  .concat(emailSchemaValidation)
  .concat(cellphoneSchemaValidation);

export const adwardsSchemaValidation = emailSchemaValidation.concat(cellphoneSchemaValidation);
