/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Button, TextInput, Alert } from '@doit/pcnt-react-ui-library';
import '../assets/css/onboardingCustomer.css';
import { Grid, Typography } from '@mui/material';
import i18n from '../common/i18n';
import { fillMessageWith, sendDataLayerEventObject, getResponseErrorOrDefault } from '../utils/functionsUtil';
import { resolveBankAccount, sendVerificationCodeForHash, verifyCodeForHash } from '../api/onboardingService';

const CustomerDataInputText = ({
  variable, handleChange, userFilled, updateUserFilled, loanRequest, showVerificationCbu, setShowVerificationCbu,
  accountPreError, setAccountPreError, accountPreValid, setAccountPreValid, bankAccountData, setBankAccountData,
  bankAccountErrorMessage, setBankAccountErrorMessage, setBankAccountUserInputVarName, enableCodeInputByUrlParam,
  setErrorMessage, inputHintAction, turnOnValidations,
}) => {
  const info = i18n.OnboardingCustomerData.inputText;
  const [counterInSeconds, setCounterInSeconds] = useState(undefined);
  const [accountType, setAccountType] = useState('CBU/CVU');
  const [accountLength, setAccountLength] = useState(22);
  const [showCbuInfo, setShowCbuInfo] = useState(false);
  const [showCbuResult, setShowCbuResult] = useState(false);
  const [showPreConfirmation, setShowPreConfirmation] = useState(true);
  const [validationCodeSentAtLeastOnce, setValidationCodeSentAtLeastOnce] = useState(false);
  const [validationMade, setValidationMade] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [sendQty, setSendQty] = useState(0);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [errorPattern, setErrorPattern] = useState(false);

  const countDown = () => {
    // Set to "true" when component is mounted
    let mounted = true;
    setTimeout(() => {
      // Check if the component is still mounted
      if (mounted && counterInSeconds > 0) {
        setCounterInSeconds(counterInSeconds - 1);
      }
    }, 1000);
    // Set to "false" when the component is unmounted
    return () => {
      mounted = false;
    };
  };

  const sendValidationCode = async () => {
    try {
      await setCounterInSeconds(60);
      if (isResend) {
        await sendVerificationCodeForHash(loanRequest.hashKey, loanRequest.customerName, userFilled[variable.name],
          userFilled.PtllaMail, true);
      } else {
        await sendVerificationCodeForHash(loanRequest.hashKey, loanRequest.customerName, userFilled[variable.name]);
      }
      setIsResend(true);
      setValidationCodeSentAtLeastOnce(true);
      const sendQtyNewValue = sendQty + 1;
      if (sendQtyNewValue > 1) {
        setShowEmailMessage(true);
      }
      setSendQty(sendQtyNewValue);
      countDown();
    } catch (error) {
      console.log('error', 'No se pudo enviar el código', error.response.data.cause);
      setErrorMessage('No se pudo enviar el código');
      setCounterInSeconds(undefined);
    }
  };

  const verifyCodeInput = async (code) => {
    try {
      if (code && code.length === 4) {
        const response = await verifyCodeForHash(loanRequest.hashKey, variable.name, userFilled[variable.name], code);
        setValidationMade(true);
        updateUserFilled(`${variable.name}_verified`, response.data.result);
      } else {
        setValidationMade(false);
        updateUserFilled(`${variable.name}_verified`, false);
      }
    } catch (error) {
      console.log('error', 'Error al validar el código', error.response.data.cause);
      setErrorMessage('Error al validar el código');
      setValidationMade(false);
    }
  };

  const resolveBankAccountKey = async () => {
    setShowCbuInfo(false);
    setShowCbuResult(true);
    setBankAccountErrorMessage(undefined);
    const bankAccountKey = userFilled[variable.name];
    setBankAccountUserInputVarName(variable.name);

    sendDataLayerEventObject({ event: 'verificar_cbu' });

    try {
      const response = await resolveBankAccount(loanRequest.hashKey, bankAccountKey);
      setBankAccountUserInputVarName(variable.name);
      setBankAccountData(response.data);
      setShowVerificationCbu(false);
    } catch (error) {
      console.log(error);
      setBankAccountData(undefined);
      setBankAccountErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const onChangeInputThatHasVerification = () => {
    setCounterInSeconds(undefined);
    setValidationMade(undefined);
    updateUserFilled(`${variable.name}_verified`, undefined);
  };

  const inputCbuChangeEvent = (cbuToValid) => {
    setShowPreConfirmation(true);
    setShowCbuInfo(true);
    setShowCbuResult(false);
    setShowVerificationCbu(true);
    setAccountPreValid(false);
    setAccountPreError(false);

    if (!cbuToValid) {
      setAccountType('CBU/CVU');
      setAccountLength(22);
    } else if (Number.isNaN(Number(cbuToValid))) {
      setAccountType('ALIAS');
      setAccountLength(6);
      if (cbuToValid.length < 6) {
        setAccountPreValid(false);
        setAccountLength(6 - cbuToValid.length);
      } else {
        if (cbuToValid.length <= 20) {
          setAccountPreValid(true);
        }
        if (cbuToValid.length > 20) {
          setAccountPreError(true);
        }
      }
    } else {
      setAccountType('CBU/CVU');
      setAccountLength(22);
      if (cbuToValid.length < 22) {
        setAccountPreValid(false);
        setAccountLength(22 - cbuToValid.length);
      } else if (cbuToValid.length === 22) {
        setAccountPreValid(true);
      } else {
        setAccountPreError(true);
      }
    }
  };

  const onChange = (value) => {
    const { pattern } = variable.userInputOptions;
    if (pattern != null) {
      // pattern: '[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*',
      // le saco / porque esta mal la reg exp, lo probe en regex101.com
      const regex = pattern.replace('/', '');
      setErrorPattern(value?.trim() !== '' && !new RegExp(regex).test(value));
    }
    if (variable.userInputOptions.verification !== 'NONE') {
      onChangeInputThatHasVerification();
    } else if (variable.name === 'PtllaCBUDesembolsoYCobro' || variable.name === 'PtllaCBUCobro') {
      inputCbuChangeEvent(value);
    }
    handleChange(variable, value);
  };

  useEffect(() => {}, [userFilled[variable.name]]);
  useEffect(() => countDown(), [counterInSeconds]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid
          item
          xs={12}
          md={variable.userInputOptions.verification !== 'NONE'
          && variable.name === 'PtllaTelCelular' && !loanRequest.customerPhoneValidated ? 5 : 12}
        >
          <TextInput
            name={variable.name}
            label={variable.userInputOptions.label}
            value={userFilled[variable.name]}
            error={variable.userInputOptions.required
              && (errorPattern
                || (turnOnValidations && (userFilled[variable.name] === undefined
                  || userFilled[variable.name] === null
                  || userFilled[variable.name].toString().trim() === '')
                )
              )}
            onChange={(event) => onChange(event.target.value)}
            required={variable.userInputOptions.required}
            fullWidth
          />
          {(variable.userInputOptions.hint
            || (variable.userInputOptions.hintAction && userFilled[variable.name]?.showHint)) && (
            <>
              <Typography className="customer-data-custom-hint" style={{ fontSize: '10px' }}>
                <span style={{
                  color: ((variable.userInputOptions.required
                  && (errorPattern
                    || (turnOnValidations && (userFilled[variable.name] === undefined
                      || userFilled[variable.name] === null
                      || userFilled[variable.name].toString().trim() === '')
                    )
                  )) ? '#E20000' : '#00000099'),
                }}
                >
                  {variable.userInputOptions.hint}
                </span>
                {variable.userInputOptions.hintAction && (
                  <Button
                    variant="tertiary"
                    onClick={() => inputHintAction(variable)}
                    size="small"
                    className="customer-data-button-tertiary-ligth"
                  >
                    {variable.userInputOptions.hintAction}
                  </Button>
                )}
              </Typography>
            </>
          )}

          {variable.userInputOptions.showReloadValidField && (
            <>
              <Alert severity="error">
                <Typography className="customer-data-alert">
                  {parse(info.showReloadValidField)}
                </Typography>
              </Alert>
            </>
          )}
        </Grid>

        {/* PtllaCBU */}
        {(variable.name === 'PtllaCBUDesembolsoYCobro' || variable.name === 'PtllaCBUCobro') && (
        <>
          <Grid item xs={12}>
            {showPreConfirmation && userFilled[variable.name] && !accountPreError && showCbuInfo && accountPreValid && (
            <>
              <Alert severity="info">
                <Typography className="customer-data-alert">
                  {parse(fillMessageWith(info.disburseAccountTypeOk, accountType))}
                </Typography>
              </Alert>
            </>
            )}
            {showPreConfirmation && userFilled[variable.name] && !accountPreError && showCbuInfo && !accountPreValid && (
            <>
              <Alert severity="info">
                <Typography className="customer-data-alert">
                  {parse(fillMessageWith(info.disburseAccountTypeNotOk, accountType, accountLength))}
                </Typography>
              </Alert>
            </>
            )}
            {showPreConfirmation && !userFilled[variable.name] && (
            <>
              <Alert severity="info">
                <Typography className="customer-data-alert">
                  {info.shouldInputCBUorAlias}
                </Typography>
              </Alert>
            </>
            )}
            {showPreConfirmation && accountPreError && showCbuInfo && (
            <>
              <Alert severity="error">
                <Typography className="customer-data-alert">
                  {fillMessageWith(info.disburseAccountLenghtError, accountType)}
                </Typography>
              </Alert>
            </>
            )}
            {showCbuResult && bankAccountErrorMessage && (
            <>
              <Alert severity="error">
                <Typography className="customer-data-alert">
                  {bankAccountErrorMessage}
                </Typography>
              </Alert>
            </>
            )}
            {showCbuResult && !bankAccountErrorMessage && bankAccountData && (
            <>
              <Alert severity="success">
                <Typography className="customer-data-alert">
                  {bankAccountData.type === 'CC' && (
                  <>
                    {fillMessageWith(info.bankAccountDataTypeCC,
                      bankAccountData.bank_routing?.address, bankAccountData.account_routing.address.slice(-4))}
                  </>
                  )}
                  {bankAccountData.type === 'CA' && (
                  <>
                    {fillMessageWith(info.bankAccountDataTypeCA,
                      bankAccountData.bank_routing?.address, bankAccountData.account_routing.address.slice(-4))}
                  </>
                  )}
                  {bankAccountData.type === 'VIRTUAL' && (
                  <>
                    {fillMessageWith(info.bankAccountDataTypeVIRTUAL,
                      bankAccountData.account_routing.address.slice(-4))}
                  </>
                  )}
                  {bankAccountData.type !== 'CC' && bankAccountData.type !== 'CA' && bankAccountData.type !== 'VIRTUAL' && (
                    <>
                      {fillMessageWith(info.bankAccountDataTypeOther,
                        bankAccountData.bank_routing?.address, bankAccountData.account_routing.address.slice(-4))}
                    </>
                  )}
                </Typography>
              </Alert>
            </>
            )}
          </Grid>
          {showVerificationCbu && (
          <>
            <Grid item xs={12}>
              <Button
                variant="primary"
                className="btn-primary"
                type="button"
                disabled={!accountPreValid || !userFilled[variable.name]}
                fullWidth
                onClick={resolveBankAccountKey}
              >
                {info.bankAccountDataVerifyCTA}
              </Button>
            </Grid>
          </>
          )}
        </>
        )}

        {/* with verification */}
        {variable.userInputOptions.verification !== 'NONE'
          && variable.name === 'PtllaTelCelular' && !loanRequest.customerPhoneValidated && (
          <>
            <Grid item xs={6} md={4}>
              <Button
                variant="primary"
                className="btn-primary"
                type="button"
                onClick={sendValidationCode}
                disabled={counterInSeconds >= 1}
                fullWidth
              >
                {isResend ? info.withVerificationReSendCode : info.withVerificationSendCode}
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextInput
                label={info.withVerificationCodeLabel}
                name={`${variable.name}_verifyCode`}
                onChange={(event) => verifyCodeInput(event.target.value)}
                disabled={!validationCodeSentAtLeastOnce && !enableCodeInputByUrlParam}
                inputProps={{ maxLength: 4 }}
                required={variable.userInputOptions.verification === 'REQUIRED'}
              />
              {userFilled[`${variable.name}_verified`] && (
                <>
                  <Alert severity="success" style={{ padding: '0px', backgroundColor: 'white' }}>
                    <Typography className="customer-data-alert">
                      {info.withVerificationCodeSentVerifiedOk}
                    </Typography>
                  </Alert>
                </>
              )}
              {!userFilled[`${variable.name}_verified`] && validationMade && (
                <>
                  <Alert severity="error" style={{ padding: '0px', backgroundColor: 'white' }}>
                    <Typography className="customer-data-alert">
                      {info.withVerificationCodeSentVerifiedError}
                    </Typography>
                  </Alert>
                </>
              )}
              {!userFilled[`${variable.name}_verified`] && !validationMade && (
                <>
                  <Alert severity="warning" style={{ padding: '0px', backgroundColor: 'white' }}>
                    <Typography className="customer-data-alert">
                      {info.withVerificationCodeSentNotVerified}
                    </Typography>
                  </Alert>
                </>
              )}
            </Grid>
            {counterInSeconds >= 1 && (
            <>
              <Grid item xs={12}>
                <Typography className="customer-data-send-code">
                  {info.withVerificationCodeSent}
                </Typography>
                <Typography className="customer-data-send-code">
                  {fillMessageWith(info.withVerificationSentCodeCountDown, counterInSeconds)}
                </Typography>
              </Grid>
            </>
            )}
            {counterInSeconds >= 1 && showEmailMessage && userFilled.PtllaMail && (
            <>
              <Grid item xs={12}>
                <Alert severity="info">
                  <Typography className="customer-data-alert">
                    {fillMessageWith(info.withVerificationMessageByEmail, userFilled.PtllaMail)}
                  </Typography>
                </Alert>
              </Grid>
            </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

CustomerDataInputText.propTypes = {
  variable: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  userFilled: PropTypes.shape().isRequired,
  updateUserFilled: PropTypes.func.isRequired,
  loanRequest: PropTypes.shape().isRequired,
  showVerificationCbu: PropTypes.bool.isRequired,
  setShowVerificationCbu: PropTypes.func.isRequired,
  accountPreError: PropTypes.bool.isRequired,
  setAccountPreError: PropTypes.func.isRequired,
  accountPreValid: PropTypes.bool.isRequired,
  setAccountPreValid: PropTypes.func.isRequired,
  bankAccountData: PropTypes.shape(),
  setBankAccountData: PropTypes.func.isRequired,
  bankAccountErrorMessage: PropTypes.string,
  setBankAccountErrorMessage: PropTypes.func.isRequired,
  setBankAccountUserInputVarName: PropTypes.func.isRequired,
  enableCodeInputByUrlParam: PropTypes.bool.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  inputHintAction: PropTypes.func.isRequired,
  turnOnValidations: PropTypes.bool.isRequired,
};

CustomerDataInputText.defaultProps = {
  bankAccountData: undefined,
  bankAccountErrorMessage: undefined,
};

export default CustomerDataInputText;
