import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/onboardingCustomer.css';
import { Checkbox } from '@doit/pcnt-react-ui-library';

const CustomerDataInputCheck = ({
  variable, handleChange, userFilled,
}) => {
  const [inputVar, setInputVar] = useState({ selected: false });

  const onChange = () => {
    inputVar.selected = !inputVar.selected;
    setInputVar(inputVar);
    handleChange(variable, inputVar);
  };

  const init = () => {
    setInputVar(userFilled[variable.name] || { selected: false });
  };

  useEffect(() => { init(); }, [userFilled[variable.name]]);

  return (
    <>
      <Checkbox
        name={variable.name}
        label={variable.userInputOptions.label}
        checked={inputVar.selected}
        onChange={onChange}
        required={variable.userInputOptions.required}
      />
    </>
  );
};

CustomerDataInputCheck.propTypes = {
  variable: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  userFilled: PropTypes.shape().isRequired,
};

export default CustomerDataInputCheck;
