import { Divider, List, ListItem } from '@doit/pcnt-react-ui-library';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const DocumentLoadedList = ({ requiredDocumentList }) => (
  <List>
    {requiredDocumentList.map((doc) => (
      doc.userInputOptions.defaultValue !== null && doc.userInputOptions.uploadMethod !== 'PRELOAD' && (
        <Box key={doc.userInputOptions.label}>
          <ListItem
            description={doc.userInputOptions.label}
            leadingRight={<CheckCircleOutlineOutlinedIcon style={{ color: '#02c66a' }} />}
          />
          <Divider />
        </Box>
      )
    ))}
  </List>
);

DocumentLoadedList.propTypes = {
  requiredDocumentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default DocumentLoadedList;
