import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import { Button, Slider } from '@doit/pcnt-react-ui-library';
import InstallmentCardList from './InstallmentCardList';
import i18n from '../common/i18n';
import BenefitBanner from './BenefitBanner';
import { moneyFormatter, moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';

const DualOfferTabContent = ({
  isDual, onChangeAmount, onSelectInstallment, selectedInstallment,
  loanRequest, maxAmount, installments, installmentsOriginalLine, onContinue, loading,
}) => {
  const [selectedAmount, setSelectedAmount] = useState();

  const getMarks = () => {
    const marks = [
      {
        value: Math.ceil(installments[0].minimumAmount),
        label: moneyFormatterWithoutDecimals(Math.ceil(installments[0].minimumAmount)),
      },
      {
        value: maxAmount,
        label: moneyFormatterWithoutDecimals(maxAmount),
      },
    ];
    return marks;
  };

  const handleChange = (event, amount) => {
    setSelectedAmount(amount);
    onChangeAmount(amount);
  };

  const handleContinue = () => {
    onContinue(selectedAmount);
  };

  const init = () => {
    // Se definio utilizar el 70% del monto del loanRequest como valor default
    // Posiciono el valor default en el selector
    const amount = Math.ceil((maxAmount * 0.7) / 100) * 100;
    setSelectedAmount(amount);
  };

  useEffect(() => { init(); }, []);

  return (
    <Grid container className="dual-offer-tab-content-container">
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Box mt={3} />
        <Typography className="dual-offer-tab-content-title">
          {isDual ? i18n.DualOfferTabContent.dualTitle : i18n.DualOfferTabContent.renewTitle}
        </Typography>
        <Box mt={3} />
        <Typography className="dual-offer-tab-content-section">
          {i18n.DualOfferTabContent.selectAmount}
        </Typography>
        <Box mt={5} />
        {/* ********** Componente slider ********** */}
        {selectedAmount && (
          <Grid container>
            <Grid item xs={1} md={0} />
            <Grid item xs={10} md={12}>
              <Slider
                onChange={(event, amount) => setSelectedAmount(amount)}
                onChangeCommitted={handleChange}
                step={100}
                min={Math.ceil(installments[0].minimumAmount)}
                max={maxAmount}
                value={selectedAmount}
                valueLabelFormat={moneyFormatterWithoutDecimals(selectedAmount)}
                marks={getMarks()}
                valueLabelDisplay="on"
                disabled={loading}
              />
            </Grid>
          </Grid>
        )}
        {/* ********** Componente banner de beneficios ********** */}
        <BenefitBanner
          loanRequest={loanRequest}
          installments={installments}
          selectedInstallment={selectedInstallment}
        />
        <Box mt={3} />
        <Typography className="dual-offer-tab-content-section">{i18n.DualOfferTabContent.selectInstallment}</Typography>
        <Box mt={2} />
        {/* ********** Componente plan de cuotas ********** */}
        <InstallmentCardList
          installments={installments}
          installmentsOriginalLine={installmentsOriginalLine}
          onSelect={onSelectInstallment}
          loading={loading}
        />
        <Box mt={3} />
        <Typography className="dual-offer-tab-content-disclaimer">
          {isDual ? i18n.DualOfferTabContent.dualDescription
            : parse(fillMessageWith(
              i18n.DualOfferTabContent.renewDescription,
              moneyFormatter(loanRequest.response.prePaidLoanAmount),
            ))}
        </Typography>
        <Box mt={3} />
        <Grid container>
          <Grid item md={3} />
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="primary"
              className="btn-primary"
              type="button"
              onClick={handleContinue}
              fullWidth
              disabled={loading}
              loading={loading}
            >
              {i18n.DualOfferTabContent.ctaContinue}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DualOfferTabContent.propTypes = {
  isDual: PropTypes.bool.isRequired,
  onChangeAmount: PropTypes.func.isRequired,
  onSelectInstallment: PropTypes.func.isRequired,
  selectedInstallment: PropTypes.shape().isRequired,
  loanRequest: PropTypes.shape().isRequired,
  maxAmount: PropTypes.number.isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  installmentsOriginalLine: PropTypes.arrayOf(PropTypes.shape()),
  onContinue: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

DualOfferTabContent.defaultProps = {
  installmentsOriginalLine: undefined,
};

export default DualOfferTabContent;
