import nextelImg from '../assets/imgs/recharge_nextel.png';
import claroImg from '../assets/imgs/recharge_claro.png';
import personallImg from '../assets/imgs/recharge_personal.png';
import movistarImg from '../assets/imgs/recharge_movistar.png';
import tuentiImg from '../assets/imgs/recharge_tuenti.png';

const getCompanyImageByCode = (companyCode) => {
  switch (companyCode) {
    case '9': return claroImg;
    case '16': return personallImg;
    case '577': return nextelImg;
    case '1488': return movistarImg;
    case '3144': return tuentiImg;
    default: return null;
  }
};

export default getCompanyImageByCode;
