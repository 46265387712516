import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, List, ListItem } from '@doit/pcnt-react-ui-library';
import barcodeErrorImg from '../assets/imgs/dni-error.svg';
import '../assets/css/barcodeReadMessage.css';

const BarcodeReadMessage = ({ barcodeMessage, onCTA }) => (
  <Grid container>
    <Grid item xs={12} md={6}>
      <Box>
        <img src={barcodeErrorImg} alt="error_dni" width="300px" />
        <Box mt={3}>
          <Typography className="barcode-read-message-title">{barcodeMessage.title}</Typography>
          <List>
            {barcodeMessage.items.map((item) => (
              <ListItem leadingLeft={<KeyboardArrowRightIcon color="primary" />} description={parse(item)} key={item} />
            ))}
          </List>
        </Box>
        <Box mt={5} />
        <Button
          variant="primary"
          className="btn-primary"
          fullWidth
          onClick={onCTA}
        >
          {barcodeMessage.ctaLabel}
        </Button>
      </Box>
    </Grid>
  </Grid>
);

BarcodeReadMessage.propTypes = {
  barcodeMessage: PropTypes.shape().isRequired,
  onCTA: PropTypes.func.isRequired,
};

export default BarcodeReadMessage;
