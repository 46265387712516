import { PREVIOUS_ORIGINATION_ONBOARDING_URL, getEnv } from '../api/env';
import { updateUrlSourceParameters, getSiteBanner } from '../api/onboardingService';

const listDigitsDniVerificationCode = ['0', '1', '2', '3', '4', '5', '6', '7'];

const getUrlSourceParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    utmSource: urlParams.get('utm_source'),
    utmMedium: urlParams.get('utm_medium'),
    utmContent: urlParams.get('utm_content'),
    utmCampaign: urlParams.get('utm_campaign'),
    utmTerm: urlParams.get('utm_term'),
  };
};

const hasUrlParamsToSave = () => {
  const urlSourceParameters = getUrlSourceParameters();
  return urlSourceParameters.utmCampaign !== null
    || urlSourceParameters.utmContent !== null
    || urlSourceParameters.utmMedium !== null
    || urlSourceParameters.utmSource !== null
    || urlSourceParameters.utmTerm !== null;
};

export const saveUrlSourceParameters = async (hash) => {
  try {
    if (hasUrlParamsToSave()) {
      await updateUrlSourceParameters(hash, getUrlSourceParameters());
    }
  } catch (error) {
    console.log(error);
  }
};

export const redirectToPreviousOnboardingSlashM = (hash) => {
  const urlParams = new URLSearchParams(window.location.search);
  window.location = `${getEnv(PREVIOUS_ORIGINATION_ONBOARDING_URL)}m/${hash}?${urlParams}`;
};

export const redirectToContinueURLSlashM = (hash) => {
  const urlParams = new URLSearchParams(window.location.search);
  window.location = `/m/${hash}?${urlParams}`;
};

export const dniIncludesLastDigit = (dni, listDigits) => {
  const list = listDigits || listDigitsDniVerificationCode;
  if (dni) {
    const lastDigitDni = (dni.trim()).substring((dni.trim()).length - 1);
    return list.includes(lastDigitDni);
  }
  return false;
};

export const resolveSiteBanner = async () => {
  let siteBanner = 'https://s3-sa-east-1.amazonaws.com/static.credicuotas.com.ar/mkt/banner_landing_site/img-adelanto-semana.jpg';
  try {
    const response = await getSiteBanner();
    siteBanner = response.data.siteBannerWeb;
  } catch (error) {
    console.log(error);
  }
  return siteBanner;
};
