import { Box, Grid, Typography } from '@mui/material';
import CardImage from './commons/CardImage';
import i18n from '../common/i18n';
import iconSelfie from '../assets/imgs/icon-selfie.svg';
import iconPen from '../assets/imgs/icon-pen.svg';
import iconMoney from '../assets/imgs/icon-money.svg';

const PreOnboardingNextSteps = () => (
  <>
    <Grid container className="pre-onboarding-main-container" justifyContent="flex-end">
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className="next-steps-title">{i18n.PreOnboardingNextSteps.title}</Typography>
          </Grid>
          <Box mt={10} />
          <Grid item xs={12} sm={6} md={3}>
            <CardImage image={iconSelfie} description={i18n.PreOnboardingNextSteps.step1} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardImage image={iconPen} description={i18n.PreOnboardingNextSteps.step2} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardImage image={iconMoney} description={i18n.PreOnboardingNextSteps.step3} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
);

export default PreOnboardingNextSteps;
