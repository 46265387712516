import i18n from '../common/i18n';

/* eslint "import/prefer-default-export": "off" */

export const referenceConstant = {
  ERROR_SENDING_SMS: i18n.VerificationCodeStep.errors.sendCode,
  ERROR_4XX_GET_PAYMENT_BILL_DETAIL_EP207: i18n.PRODUCT_LOAD.PAY_SERVICE.billNoDebtError,
  SAPP_NO_PEN_CREDITS: i18n.PRODUCT_LOAD.sappNoPenCredits,
  INVALID_INSTALLMENT_PLAN: i18n.PRODUCT_LOAD.invalidInstallmentPlan,
};
