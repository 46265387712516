import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Autocomplete,
} from '@mui/material';
import { TextInput } from '@doit/pcnt-react-ui-library';
import '../assets/css/onboardingCustomer.css';
import i18n from '../common/i18n';

const CustomerDataInputTextAutocomplete = ({
  variable, handleChange, userFilled, comboData, turnOnValidations,
}) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [noOptionsText, setNoOptionsText] = useState();

  const filterOptions = (ops, state) => {
    if (state.inputValue.length > 2) {
      setNoOptionsText(i18n.OnboardingCustomerData.inputAutocomplete.noOptionsTextWhenNotFound);
      return ops.filter((item) => String(item.descripcion).toLowerCase().includes(state.inputValue.toLowerCase()));
    }
    setNoOptionsText(i18n.OnboardingCustomerData.inputAutocomplete.noOptionsTextWhenIsEmpty);
    return [];
  };

  const onChange = (value) => {
    let comboSelected;
    if (value !== null) {
      comboSelected = comboData[variable.name].find((c) => c.id === value.id);
    }
    setSelected(comboSelected);
    handleChange(variable, comboSelected);
  };

  const init = () => {
    const optionsTemp = [];
    if (comboData[variable.name]?.length > 0) {
      comboData[variable.name].map((c) => optionsTemp.push(c));
    }
    if (userFilled[variable.name]) {
      const defaultSelected = optionsTemp.find((c) => c.id === userFilled[variable.name].id);
      setSelected(defaultSelected);
    }
    setOptions(optionsTemp);
  };

  useEffect(() => { init(); }, [userFilled[variable.name], comboData[variable.name]]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            value={selected}
            onChange={(event, value) => onChange(value)}
            options={options}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.descripcion}
            renderInput={(params) => (
              <TextInput
                {...params}
                name={variable.name}
                label={variable.userInputOptions.label}
                error={variable.userInputOptions.required && turnOnValidations
                  && (userFilled[variable.name] === undefined
                    || (userFilled[variable.name].toString()).trim() === '')}
                required={variable.userInputOptions.required}
                fullWidth
              />
            )}
            noOptionsText={noOptionsText}
          />
          <Typography className="customer-data-custom-hint">
            <span style={{
              color: (variable.userInputOptions.required && turnOnValidations
                  && (userFilled[variable.name] === undefined
                  || (userFilled[variable.name].toString()).trim() === '') ? '#E20000' : '#00000099'),
            }}
            >
              {variable.userInputOptions.hint}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

CustomerDataInputTextAutocomplete.propTypes = {
  variable: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  userFilled: PropTypes.shape().isRequired,
  comboData: PropTypes.shape().isRequired,
  turnOnValidations: PropTypes.bool.isRequired,
};

export default CustomerDataInputTextAutocomplete;
